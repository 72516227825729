import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { AlertService } from '../alert/alert.service';
import { Router } from '@angular/router';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private alertService: AlertService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(
    response: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    const status = response.status;
    let errorMessage = response.error.developerMessage || response.message;
    if (response.error.errors) {
      if (response.error.errors[0]) {
        errorMessage =
          response.error.errors[0].defaultUserMessage ||
          response.error.errors[0].developerMessage;
      }
    }

    if (!environment.production) {
      log.error(`Request Error: ${errorMessage}`);
    }

    if (status === 401) {
      console.log('status');
      console.log('401');
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['/login']);
      //this.alertService.alert({ type: 'Authentication Error', message: 'Invalid User Details. Please try again!' });
    } else if (
      status === 403 &&
      errorMessage === 'The provided one time token is invalid'
    ) {
      console.log('status');
      console.log('40');
    } else if (status === 400) {
      console.log('status');
      console.log('400');
    } else if (status === 403) {
      console.log('status');
      console.log('403');
    } else if (status === 404) {
      console.log('status');
      console.log('404');
    } else if (status === 500) {
      console.log('status');
      console.log('500');
    } else {
      console.log('status');
      console.log('other');
    }

    throw response;
  }
}
