import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material.module';

import { MasterRoutingModule } from './master-routing.module';
import { SelectSalesTypeComponent } from './sales-type/select-sales-type/select-sales-type.component';
import { SelectBranchComponent } from './branch/select-branch/select-branch.component';
import { SelectProjectComponent } from './project/select-project/select-project.component';
import { SelectEcommerceComponent } from './ecommerce/select-ecommerce/select-ecommerce.component';
import { SelectCustomerComponent } from './customer/select-customer/select-customer.component';
import { SelectStateComponent } from './state/select-state/select-state.component';
import { SelectTaxTypeComponent } from './tax-type/select-tax-type/select-tax-type.component';
import { SelectCessTypeComponent } from './cess-type/select-cess-type/select-cess-type.component';
import { SelectApproverComponent } from './approver/select-approver/select-approver.component';
import { SelectStaffComponent } from './staff/select-staff/select-staff.component';
import { SelectSourceComponent } from './source/select-source/select-source.component';
import { SelectPaymentViaComponent } from './payment-via/select-payment-via/select-payment-via.component';
import { SelectPaymentModeComponent } from './payment-mode/select-payment-mode/select-payment-mode.component';
import { SelectPurchaseTypeComponent } from './purchase-type/select-purchase-type/select-purchase-type.component';
import { SelectItcTypeComponent } from './itc-type/select-itc-type/select-itc-type.component';
import { SelectRevenueSegmentComponent } from './revenue-segment/select-revenue-segment/select-revenue-segment.component';
import { AddBranchComponent } from './branch/add-branch/add-branch.component';
import { ViewBranchComponent } from './branch/view-branch/view-branch.component';
import { DetailViewBranchComponent } from './branch/detail-view-branch/detail-view-branch.component';
import { SelectCountryComponent } from './country/select-country/select-country.component';
import { SelectAssetComponent } from './asset/select-asset/select-asset.component';
import { SelectAccountGroupComponent } from './account-group/select-account-group/select-account-group.component';
import { ViewProjectComponent } from './project/view-project/view-project.component';
import { AddProjectComponent } from './project/add-project/add-project.component';
import { DetailViewProjectComponent } from './project/detail-view-project/detail-view-project.component';
import { SelectMaterialComponent } from './material/select-material/select-material.component';
import { SelectAccountMasterComponent } from './account-master/select-account-master/select-account-master.component';
import { SelectServiceComponent } from './service/select-service/select-service.component';
import { SelectEntityComponent } from './entity/select-entity/select-entity.component';
import { EditProjectComponent } from './project/edit-project/edit-project.component';
import { EditBranchComponent } from './branch/edit-branch/edit-branch.component';
import { SelectVendorAccountMasterComponent } from './account-master/select-vendor-account-master/select-vendor-account-master.component';
import { SelectAllAccountMasterComponent } from './account-master/select-all-account-master/select-all-account-master.component';
import { SharedModule } from '@app/shared/shared.module';
import { SelectOutputMaterialComponent } from './material/select-output-material/select-output-material.component';
import { SelectInputMaterialComponent } from './material/select-input-material/select-input-material.component';
import { SelectCustomerTypeComponent } from './customer/select-customer-type/select-customer-type.component';
import { SelectPartyComponent } from './party/select-party/select-party.component';
import { SelectProductionsComponent } from './productions/select-productions.component';

@NgModule({
  declarations: [
    SelectSalesTypeComponent,
    SelectBranchComponent,
    SelectProjectComponent,
    SelectEcommerceComponent,
    SelectCustomerComponent,
    SelectStateComponent,
    SelectTaxTypeComponent,
    SelectCessTypeComponent,
    SelectApproverComponent,
    SelectStaffComponent,
    SelectSourceComponent,
    SelectPaymentViaComponent,
    SelectPaymentModeComponent,
    SelectPurchaseTypeComponent,
    SelectItcTypeComponent,
    SelectRevenueSegmentComponent,
    AddBranchComponent,
    ViewBranchComponent,
    DetailViewBranchComponent,
    SelectCountryComponent,
    SelectAssetComponent,
    SelectAccountGroupComponent,
    ViewProjectComponent,
    AddProjectComponent,
    DetailViewProjectComponent,
    SelectMaterialComponent,
    SelectAccountMasterComponent,
    SelectServiceComponent,
    SelectEntityComponent,
    EditProjectComponent,
    EditBranchComponent,
    SelectVendorAccountMasterComponent,
    SelectAllAccountMasterComponent,
    SelectOutputMaterialComponent,
    SelectInputMaterialComponent,
    SelectCustomerTypeComponent,
    SelectPartyComponent,
    SelectProductionsComponent
  ],
  exports: [
    SelectSalesTypeComponent,
    SelectBranchComponent,
    SelectProjectComponent,
    SelectEcommerceComponent,
    SelectCustomerComponent,
    SelectStateComponent,
    SelectTaxTypeComponent,
    SelectCessTypeComponent,
    SelectApproverComponent,
    SelectStaffComponent,
    SelectSourceComponent,
    SelectPaymentViaComponent,
    SelectPaymentModeComponent,
    SelectPurchaseTypeComponent,
    SelectItcTypeComponent,
    SelectRevenueSegmentComponent,
    DetailViewBranchComponent,
    SelectCountryComponent,
    SelectAssetComponent,
    SelectAccountGroupComponent,
    SelectMaterialComponent,
    SelectAccountMasterComponent,
    SelectServiceComponent,
    SelectEntityComponent,
    DetailViewProjectComponent,
    SelectVendorAccountMasterComponent,
    SelectAllAccountMasterComponent,
    SelectCustomerTypeComponent,
    SelectPartyComponent,
    SelectProductionsComponent
  ],
  entryComponents: [
    DetailViewBranchComponent,
    EditProjectComponent,
    DetailViewProjectComponent,
    EditBranchComponent
  ],
  imports: [CommonModule, MasterRoutingModule, MaterialModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MasterModule {}
