import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  constructor(private http: HttpClient) {}

  /*
   * ENTITY
   */

  // Get All Entity List
  getEntity(
    limit: number,
    skip: number,
    sortby: string,
    sortas: string
  ): Observable<any> {
    return this.http.get('/entity', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }

  // Get All Active Entity to display in dropdowns
  getEntityForDropdown(): Observable<any> {
    return this.http.get('/entity/active');
  }

  // Add New Entity
  addEntity(data: any): Observable<any> {
    return this.http.post('/entity', data);
  }

  // Edit an entity
  editEntity(data: any): Observable<any> {
    return this.http.put('/entity', data);
  }

  // Change entity status
  changeEntityStatus(data: any): Observable<any> {
    return this.http.put('/entity/changeStatus', data);
  }

  // Get latest few entities
  getLatestEntities(): Observable<any> {
    return this.http.get('/entity/latest');
  }

  // Get entity by ID
  getEntityById(id: Number): Observable<any> {
    return this.http.get('/entity/' + id);
  }
}
