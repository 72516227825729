import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  EventEmitter,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { MasterService } from '../../../master/master.service';
import { BranchMaster } from '../../../model/branchMaster.model';
import { LegalEntityMaster } from '@app/model/legalEntityMaster.model';
import { SharedServiceService } from '@app/shared/shared-service.service';
import { DestroySubscribers } from '@app/shared/destroy-subscribers.decorator';

@Component({
  selector: 'app-select-branch',
  templateUrl: './select-branch.component.html',
  styleUrls: ['./select-branch.component.scss']
})
@DestroySubscribers()
export class SelectBranchComponent implements OnInit {
  @Input() tabindex: number;
  @Input() branchCtrl = new FormControl();
  @Input() isEditable = true;
  @Input() isLabelRequired = true;
  @Input() isRequiredStar = true;

  @Output() selectedBranch = new EventEmitter();
  branchList: BranchMaster[];
  filteredBranchList: Observable<BranchMaster[]>;
  public selectedEntity: LegalEntityMaster;
  public selectedEntityId: any;
  public subscribers: any = {};
  constructor(
    private masterService: MasterService,
    private sharedService: SharedServiceService
  ) {}

  ngOnInit() {
    if (localStorage.hasOwnProperty('selectedEntity')) {
      let selectedEntityValue = localStorage.getItem('selectedEntity');
      if (
        selectedEntityValue !== 'undefined' &&
        selectedEntityValue !== null &&
        selectedEntityValue !== '' &&
        typeof selectedEntityValue !== 'undefined'
      ) {
        this.selectedEntity = JSON.parse(
          localStorage.getItem('selectedEntity')
        );
        this.selectedEntityId = this.selectedEntity.legal_entity_master_id;
      }
    }
    // Call the method getBranchList and get the result in a callback
    this.getBranchList(branchList => {
      this.filteredBranchList = branchList;
    });
  }

  ngAfterViewInit() {
    //call my function on entity changes
    this.subscribers.entitySelectorBranch = this.sharedService.entitySelector$.subscribe(
      entity => {
        if (
          this.selectedEntityId &&
          entity &&
          this.selectedEntityId != entity.legal_entity_master_id
        ) {
          this.branchCtrl.patchValue(null);
          this.selectedEntityId = entity ? entity.legal_entity_master_id : '';
          this.getBranchList(branchList => {
            this.filteredBranchList = branchList;
          });
        }
      }
    );
  }

  /**
   *
   *
   * @param {(branchList: Observable<Branch[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete branch list
   */
  getBranchList(fn: (branchList: Observable<BranchMaster[]>) => void) {
    this.masterService
      .getBranchForDropdown(this.selectedEntityId)
      .subscribe(res => {
        if (res.data && res.data.rows) {
          this.branchList = res.data.rows;
        } else {
          this.branchList = [];
        }
        fn(
          (this.filteredBranchList = this.branchCtrl.valueChanges.pipe(
            startWith(this.branchCtrl.value),
            map(value => (typeof value === 'string' ? value : value)),
            map(branch =>
              branch && branch.length > 1
                ? this.filterBranch(branch)
                : this.branchList.slice()
            )
          ))
        );
      });
  }

  /**
   *
   *
   * @param {*} branch
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected branch
   */
  public displaySelectedBranch(branch: any) {
    return branch ? branch.branch_master_name : branch;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the branch type based on input
   *
   */
  private filterBranch(name: string) {
    return this.branchList.filter(
      branch =>
        branch.branch_master_name.toLowerCase().indexOf(name.toLowerCase()) >= 0
    );
  }

  public selectBranchClickHandler(branch: BranchMaster) {
    this.selectedBranch.emit(branch);
  }
}
