import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { Credentials } from '../authentication/credentials.service';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

const credentialsKey = 'credentials';
/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (
      !/^(http|https):/i.test(request.url) &&
      request.url.indexOf('login') > 0
    ) {
      request = request.clone({
        url: environment.serverUrl + request.url
      });
    } else if (!/^(http|https):/i.test(request.url)) {
      let credentials =
        sessionStorage.getItem(credentialsKey) ||
        localStorage.getItem(credentialsKey);
      let savedCredentials: Credentials = JSON.parse(credentials);
      let tokenVal = savedCredentials.token;
      request = request.clone({
        headers: request.headers.set(
          'Authorization',
          tokenVal ? `Bearer ${tokenVal}` : ''
        ),
        url: environment.serverUrl + request.url
      });
    }
    return next.handle(request).pipe(
      // catchError((err: any) => {
      //   if (err instanceof HttpErrorResponse) {
      //     if (err.status === 401) {
      //       this.router.navigate(['/login']);
      //     }
      //   }
      //   return Observable.create((observer: any) => {
      //     observer.next(err);
      //   });
      // })
    );
  }
}
