// Library imports
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { environment } from '@env/environment';

// Internal imports
import { PurchaseService } from '../../../purchase/purchase.service';
import { VendorMaster } from '../../../model/vendorMaster.model';

@Component({
  selector: 'app-select-party',
  templateUrl: './select-party.component.html',
  styleUrls: ['./select-party.component.scss']
})
export class SelectPartyComponent implements OnInit {
  @Input() vendorCtrl = new FormControl();
  @Input() isEditable = true;
  @Output() public selectedVendor = new EventEmitter();
  limit: number;
  skip: number;

  vendorList: VendorMaster[];
  filteredVendorList: Observable<VendorMaster[]>;

  /**
   *Creates an instance of SelectVendorComponent.
   * @param {PurchaseService} purchaseService
   * @memberof SelectVendorComponent
   */
  constructor(private purchaseService: PurchaseService) {
    this.limit = environment.fixedLimit;
    this.skip = 0;
  }

  /**
   *
   *
   * @memberof SelectVendorComponent
   */
  ngOnInit() {
    // Call the method getVendorList and get the result in a callback
    this.getApprovedVendorList(vendorList => {
      this.filteredVendorList = vendorList;
    });
  }

  /**
   *
   *
   * @param {(vendorList: Observable<VendorMaster[]>) => void} fn
   * @memberof SelectVendorComponent
   * Method to get the complete approved vendor list
   */
  getApprovedVendorList(fn: (vendorList: Observable<VendorMaster[]>) => void) {
    this.purchaseService
      .getApprovedPurchaseVendor(this.limit, this.skip)
      .subscribe(res => {
        if (res.data && res.data.rows) {
          this.vendorList = res.data.rows;
        } else {
          this.vendorList = [];
        }
        fn(
          (this.filteredVendorList = this.vendorCtrl.valueChanges.pipe(
            startWith(this.vendorCtrl.value),
            map(value => (typeof value === 'string' ? value : value)),
            map(vendor =>
              vendor && vendor.length > 1
                ? this.filterVendors(vendor)
                : this.vendorList.slice()
            )
          ))
        );
      });
  }

  /**
   *
   *
   * @param {VendorMaster} vendor
   * @returns
   * @memberof SelectVendorComponent
   * Method to display only the name of the selected vendor
   */
  public displaySelectedVendor(vendor: VendorMaster) {
    return vendor ? vendor.vendor_name : vendor;
  }

  /**
   *
   *
   * @private
   * @param {string} vendor_name
   * @returns
   * @memberof SelectVendorComponent
   * Method to filter the vendor based on input
   */
  private filterVendors(vendor_name: string) {
    return this.vendorList.filter(vendor =>
      vendor.gst_master
        ? vendor.vendor_name.toLowerCase().indexOf(vendor_name.toLowerCase()) >=
            0 ||
          vendor.gst_master.gst_no
            .toLowerCase()
            .indexOf(vendor_name.toLowerCase()) >= 0
        : vendor.vendor_name.toLowerCase().indexOf(vendor_name.toLowerCase()) >=
          0
    );
  }

  /**
   *
   *
   * @param {VendorMaster} vendor
   * @memberof SelectVendorComponent
   */
  selectedVendorClickHandler(vendor: VendorMaster) {
    this.selectedVendor.emit(vendor);
  }
}
