import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BranchMaster } from '@app/model/branchMaster.model';
import { BillMaster } from '@app/model/billMaster.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  constructor(private http: HttpClient) {}

  /********************** VENDOR ***********************/

  // Get All Vendor
  getPurchaseVendor(
    limit: number,
    skip: number,
    sortby: string,
    sortas: string
  ): Observable<any> {
    return this.http.get('/vendor', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }

  // Get All Active Vendor
  getApprovedPurchaseVendor(limit: number, skip: number): Observable<any> {
    return this.http.get('/vendor/approved', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  // Add new Vendor
  addVendor(data: any): Observable<any> {
    console.log('vendor service');
    return this.http.post('/vendor', data);
  }

  // Update  Vendor
  updateVendor(data: any): Observable<any> {
    console.log('vendor service');
    console.log(data);

    return this.http.put('/vendor', data);
  }

  // Get All Pending Purchase Orders
  getPendingPurchaseVendor(
    limit: number,
    skip: number,
    sortby: string,
    sortas: string
  ): Observable<any> {
    return this.http.get('/vendor/pending', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }

  // approve / reject vendor
  vendorActivation(data: any): Observable<any> {
    return this.http.put('/vendor/activation', data);
  }

  // approve / reject vendor
  vendorApproval(data: any): Observable<any> {
    return this.http.put('/vendor/approval', data);
  }

  getAllVendorAccounts(data: any): Observable<any> {
    return this.http.post('/vendor/vendorAccounts', data);
  }

  getVendorById(vendorId: number): Observable<any> {
    return this.http.get('/vendor/vendorId/' + vendorId);
  }

  getAccountById(data: any): Observable<any> {
    return this.http.post('/account/accountById', data);
  }

  accountForPayment() {
    return this.http.get('/account/accountForPayment');
  }

  getVendorCurrentBalance(data: any) {
    return this.http.post('/report/getVendorCurrentBalance', data);
  }

  getVendorMaterialExpenseAccount(data: any): Observable<any> {
    return this.http.post('/account/vendorMaterialExpenseAccount', data);
  }

  searchVendor(data: any): Observable<any> {
    return this.http.post('/vendor/searchVendor', data);
  }

  searchPendingVendor(data: any): Observable<any> {
    return this.http.post('/vendor/searchPendingVendor', data);
  }

  //  vendor expense account
  vendorExpenseAccount(data: any): Observable<any> {
    return this.http.post('/vendor/vendorExpenseAccount', data);
  }

  // Get Customer Tags By customer Id
  getCustomerTagsByClientId(id: Number): Observable<any> {
    return this.http.get('/vendor/customerTag/customerId/' + id);
  }

  /********************** PURCHASE ORDER ***********************/

  // Get All Purchase Orders
  getPurchaseOrder(data: any): Observable<any> {
    return this.http.get('/purchaseOrder', {
      params: data
    });
  }

  //Get All approved Purchase Orders
  getApprovedPurchaseOrder(data: any): Observable<any> {
    return this.http.get('/purchaseOrder/approved', {
      params: data
    });
  }

  // Get All Purchase Orders for payments
  getPurchaseOrderForPayment(data: any): Observable<any> {
    return this.http.get('/purchaseOrder/poForPayment', {
      params: data
    });
  }

  searchPO(data: any): Observable<any> {
    return this.http.post('/purchaseOrder/search', data);
  }

  searchForPayment(data: any): Observable<any> {
    return this.http.post('/purchaseOrder/searchForPayment', data);
  }

  searchPendingPO(data: any): Observable<any> {
    return this.http.post('/purchaseOrder/searchPending', data);
  }

  // Add new Purchase Orders
  addPurchaseOrder(data: any): Observable<any> {
    return this.http.post('/purchaseOrder', data);
  }

  // Get All Pending Purchase Orders
  getPendingPurchaseOrder(data: any): Observable<any> {
    return this.http.get('/purchaseOrder/pending', {
      params: data
    });
  }

  // Get PO By PO Id
  getPurchaseOrderById(id: Number): Observable<any> {
    return this.http.get('/purchaseOrder/id/' + id);
  }

  // Get PO details By PO Id
  getPurchaseOrderDetailsByPOId(id: Number): Observable<any> {
    return this.http.get('/purchaseOrder/details/purchaseOrderId/' + id);
  }

  // Get PO details with GRN By PO Id
  getPurchaseOrderDetailsWithGRN(data: any): Observable<any> {
    return this.http.post(
      '/purchaseOrder/getPurchaseOrderDetailsWithGRN',
      data
    );
  }

  // Get PO details with GRN By PO Id
  getGRNForBill(data: any): Observable<any> {
    return this.http.post('/goodsReciept/GRNForBill', data);
  }

  // Get PO Tags By PO Id
  getPurchaseOrderTagsByPOId(id: Number): Observable<any> {
    return this.http.get('/purchaseOrder/purchaseOrderId/' + id);
  }

  changePOStatus(data: any) {
    return this.http.put('/purchaseOrder/changeStatus', data);
  }

  closePO(data: any) {
    return this.http.put('/purchaseOrder/closePurchaseOrder', data);
  }

  getApproverByPOId(data: any) {
    return this.http.post('/purchaseOrder/getApproverByPOId', data);
  }

  getApproverByBillId(data: any) {
    return this.http.post('/bill/getApproverByBillId', data);
  }

  getApproverByDebitNoteId(data: any) {
    return this.http.post('/debitNote/getApproverByDebitNoteId', data);
  }

  getPurchaseOrderCount(branchId: number, projectId: number): Observable<any> {
    return this.http.get('/purchaseOrder/count', {
      params: {
        branchId: branchId.toString(),
        projectId: projectId.toString()
      }
    });
  }

  getPurchaseOrderNumber(data: string): Observable<any> {
    return this.http.get('/purchaseOrder/pono', {
      params: {
        prefix: data
      }
    });
  }

  getDebitNoteCount(): Observable<any> {
    return this.http.get('/debitNote/count');
  }

  getDebitNoteNumber(data: string): Observable<any> {
    return this.http.get('/debitNote/pono', {
      params: {
        prefix: data
      }
    });
  }

  getGRNCount(): Observable<any> {
    return this.http.get('/goodsReciept/count');
  }

  checkUniquePoNo(data: any): Observable<any> {
    return this.http.post('/purchaseOrder/checkUniquePoNo', data);
  }

  checkUniqueGST(gst: any): Observable<any> {
    return this.http.get('/gst/checkGST/', {
      params: { gst: gst }
    });
  }

  checkUniqueVAT(vat: any): Observable<any> {
    return this.http.get('/vendor/checkVAT/', {
      params: { vat: vat }
    });
  }

  /********************** BILL ***********************/

  // Get All Bill
  getBill(data: any): Observable<any> {
    return this.http.get('/bill', {
      params: data
    });
  }

  // Get PO By PO Id
  getBillDataById(id: Number): Observable<any> {
    return this.http.get('/bill/id/' + id);
  }

  getLastBillRecord(): Observable<any> {
    return this.http.get('/bill/lastBillRecord');
  }

  // Add new Bill
  addBill(data: any): Observable<any> {
    return this.http.post('/bill', data);
  }

  // Add new quick Bill
  addQuickBill(data: any): Observable<any> {
    return this.http.post('/bill/quickBill', data);
  }

  searchBill(data: any): Observable<any> {
    return this.http.post('/bill/searchBill', data);
  }

  searchPendingBill(data: any): Observable<any> {
    return this.http.post('/bill/searchPendingBill', data);
  }

  getBillDetailsByBillMasterId(id: Number): Observable<any> {
    return this.http.get('/bill/details/billId/' + id);
  }

  getBillDetailsForDebitNote(id: Number): Observable<any> {
    return this.http.get('/debitNote/getBillDetailsForDebitNote/billId/' + id);
  }

  getBillTags(id: Number): Observable<any> {
    return this.http.get('/bill/billId/' + id);
  }

  /********************** TDS ***********************/

  // Get All TDS
  getTds(limit: number, skip: number): Observable<any> {
    return this.http.get('/tds', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  deleteTds(id: any): Observable<any> {
    return this.http.delete('/tds/id/' + id);
  }

  getSortingTds(data: any): Observable<any> {
    return this.http.get('/tds', {
      params: data
    });
  }

  tdsForPayment(data: any): Observable<any> {
    return this.http.get('/tds/tdsForPayment', {
      params: data
    });
  }

  getSortTds(data: any): Observable<any> {
    return this.http.get('/tds', {
      params: data
    });
  }

  getTDSPaid(data: any): Observable<any> {
    return this.http.get('/tds/paidTDS', {
      params: data
    });
  }

  getTDSPending(data: any): Observable<any> {
    return this.http.get('/tds/pendingTDS', {
      params: data
    });
  }

  // search All pending TDS
  searchPendingTDS(data: any): Observable<any> {
    return this.http.post('/tds/searchPending', data);
  }

  // search All payment pending
  searchPaymentPending(data: any): Observable<any> {
    return this.http.post('/tds/searchPendingPayment', data);
  }

  // search All paid payment
  searchPaymentPaid(data: any): Observable<any> {
    return this.http.post('/tds/searchPaidPayment', data);
  }

  // search All TDS
  searchTDS(data: any): Observable<any> {
    return this.http.post('/tds/search', data);
  }

  searchPendingPaymentForPayment(data: any): Observable<any> {
    return this.http.post('/tds/searchPendingPaymentForPayment', data);
  }

  // Get All Pending TDS
  getPendingTds(limit: number, skip: number): Observable<any> {
    return this.http.get('/tds/pending', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }
  getSortPendingTds(data: any): Observable<any> {
    return this.http.get('/tds/pending', {
      params: data
    });
  }

  searchTDSForReports(data: any): Observable<any> {
    return this.http.post('/tds/searchTDSForReports', data);
  }

  // Update Bill Id in TDS
  updateTDSForBill(data: any): Observable<any> {
    return this.http.post('/tds/updateTDSForBill', data);
  }

  // Get All Pending TDS
  addTds(data: any): Observable<any> {
    return this.http.post('/tds', data);
  }

  // addQuick TDS
  addQuickTDS(data: any): Observable<any> {
    return this.http.post('/tds/addQuickTDS', data);
  }

  // Get All Pending TDS
  paidTDS(data: any): Observable<any> {
    return this.http.post('/tds/paidTDS', data);
  }

  /********************** PAYMENT ***********************/

  // Get All Payment List
  getPayment(limit: number, skip: number): Observable<any> {
    return this.http.get('/billPayment', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  deletePayment(id: any): Observable<any> {
    return this.http.delete('/billPayment/id/' + id);
  }

  getSortPayment(data: any): Observable<any> {
    return this.http.get('/billPayment', {
      params: data
    });
  }

  getSortPaymentForExport(data: any): Observable<any> {
    return this.http.get('/billPayment/forExport', {
      params: data
    });
  }

  // Get Pending Payment List
  getPendingPayment(): Observable<any> {
    return this.http
      .disableApiPrefix()
      .get('/assets/mock/pendingPayment.mock.json');
  }

  // Get All Advance Payment List
  getAdvancePayment(): Observable<any> {
    return this.http
      .disableApiPrefix()
      .get('/assets/mock/payment-advance.mock.json');
  }

  /*######################## MOVE TO MASTER - START ###############*/

  /********************** ENTITY ***********************/

  // Get All Entities
  getEntity(data: any): Observable<any> {
    return this.http.get('/entity', data);
  }

  /********************** ITC TYPE ***********************/

  // Get All ITC Types
  getItcType(): Observable<any> {
    return this.http.get('/itcType', {});
  }

  /********************** PURCHASE TYPE ***********************/

  // Get All Purchase Types
  getPurchaseType(): Observable<any> {
    return this.http.get('/purchaseType', {});
  }

  // approve bill
  approveBill(data: any, branchId: number): Observable<any> {
    data['branch_master_id'] = branchId;
    return this.http.put('/bill/changeStatus', data);
  }

  // reject bill
  rejectBill(data: any): Observable<any> {
    return this.http.put('/bill/reject', data);
  }

  // edit bill
  editBill(data: any): Observable<any> {
    return this.http.put('/bill', data);
  }

  // Get All Pending Purchase Orders
  getConfig(): Observable<any> {
    return this.http.disableApiPrefix().get('/assets/config.json');
  }

  // Add new Purchase Orders
  getProductType(): Observable<any> {
    return this.http.get('/productType');
  }

  /********************** DEBIT NOTE ***********************/
  // Debit Notes

  addDebitNote(data: any) {
    return this.http.post('/debitNote', data);
  }

  // Get All DebitNote
  getDebitNote(limit: number, skip: number): Observable<any> {
    return this.http.get('/debitNote', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  getSortDebitNote(data: any): Observable<any> {
    return this.http.get('/debitNote', {
      params: data
    });
  }

  deleteDebitNote(id: any): Observable<any> {
    return this.http.delete('/debitNote/id/' + id);
  }

  // Get All PendingDebitNote
  getPendingDebitNote(limit: number, skip: number): Observable<any> {
    return this.http.get('/debitNote/pending', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }
  getSortPendingDebitNote(data: any): Observable<any> {
    return this.http.get('/debitNote/pending', {
      params: data
    });
  }

  getDebitNoteTags(id: Number): Observable<any> {
    return this.http.get('/debitNote/debitNoteId/' + id);
  }

  // Get debit note by id
  getDebitNoteDataById(id: Number): Observable<any> {
    return this.http.get('/debitNote/id/' + id);
  }

  searchDebitNote(data: any): Observable<any> {
    return this.http.post('/debitNote/searchDebitNote', data);
  }

  searchPendingDebitNote(data: any): Observable<any> {
    return this.http.post('/debitNote/searchPendingDebitNote', data);
  }

  getDebitNoteDetailsByDebitNoteId(id: Number): Observable<any> {
    return this.http.get('/debitNote/details/debitNoteId/' + id);
  }

  approveDebitNote(data: any, branchId: number): Observable<any> {
    data['branch_master_id'] = branchId;
    return this.http.put('/debitNote/approve', data);
  }

  rejectDebitNote(data: any): Observable<any> {
    return this.http.put('/debitNote/reject', data);
  }

  //payment
  addPayment(data: any): Observable<any> {
    return this.http.post('/billPayment', data);
  }

  //payment
  addQuickPayment(data: any): Observable<any> {
    return this.http.post('/billPayment/quickPayment', data);
  }

  //multiple quick payment
  addMultipleQuickPayment(data: any): Observable<any> {
    return this.http.post('/billPayment/multipleQuickPayment', data);
  }

  //payment
  addAdjustPayment(data: any): Observable<any> {
    return this.http.post('/billPayment/adjustPayment', data);
  }

  // search All TDS
  searchBillPayment(data: any): Observable<any> {
    return this.http.post('/billPayment/search', data);
  }

  // Get List of all bills
  getGRNs(
    limit: number,
    skip: number,
    sortby: string,
    sortas: string
  ): Observable<any> {
    return this.http.get('/goodsReciept', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }
  getSortGRNs(data: any): Observable<any> {
    return this.http.get('/goodsReciept', {
      params: data
    });
  }

  searchGN(data: any): Observable<any> {
    return this.http.post('/goodsReciept/search', data);
  }

  getGRNById(data: any): Observable<any> {
    return this.http.post('/goodsReciept/getGRNById', data);
  }

  /*######################## MOVE TO MASTER - END ###############*/
}
