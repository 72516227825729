import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ProductionDAO } from '@app/productions/production/production.interface';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {
  MatAutocomplete,
  MatChipInputEvent,
  MatAutocompleteSelectedEvent
} from '@angular/material';
import { ProductionsService } from '@app/productions/productions.service';
import * as _ from 'lodash';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-select-productions',
  templateUrl: './select-productions.component.html',
  styleUrls: ['./select-productions.component.scss']
})
export class SelectProductionsComponent implements OnInit {
  @Input() productions: ProductionDAO[] = [];
  @Input() productionCtrl = new FormControl();
  @Input() isEditable = true;
  @Input() tabindex: number;
  @Output() selectedProductionList = new EventEmitter();

  productionList: ProductionDAO[] = [];
  filteredProductionList: Observable<ProductionDAO[]>;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('productionInput') productionInput: ElementRef<HTMLInputElement>;
  @ViewChild('production') matAutocomplete: MatAutocomplete;

  constructor(private productionService: ProductionsService) {}

  ngOnInit() {
    this.removable = this.isEditable;

    // Call the method getProductionList and get the result in a callback
    this.getProductionList(productionList => {
      this.filteredProductionList = productionList;
    });
  }

  /**
   *
   * @param {(productionList: Observable<ProductionDAO[]>) => void} fn
   * @memberof ProductionFromComponent
   * Method to get the complete production list
   */
  getProductionList(fn: (productionList: Observable<ProductionDAO[]>) => void) {
    this.productionService.getProductionList().subscribe((res: any) => {
      if (res.data && res.data.rows) {
        this.productionList = res.data.rows;
        if (this.productions && this.productions.length > 0) {
          this.productionList = _.difference(
            this.productionList,
            this.productions
          );
        }
      } else {
        this.productionList = [];
      }
      fn(
        (this.filteredProductionList = this.productionCtrl.valueChanges.pipe(
          startWith(this.productionCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(production =>
            production && production.length > 1
              ? this.filterProduction(production)
              : this.productionList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   * @param {*} production
   * @returns
   * @memberof ProductionFromComponent
   * Method to display only the name of the selected production
   */
  public displaySelectedProduction(production: ProductionDAO) {
    return production ? production.production_no : production;
  }

  /**
   * @private
   * @param {string} production_no
   * @returns
   * @memberof ProductionFromComponent
   * Method to filter the production type based on input
   */
  private filterProduction(production_no: string) {
    return this.productionList.filter(
      production =>
        production.production_no
          .toLowerCase()
          .indexOf(production_no.toLowerCase()) >= 0
    );
  }

  add(event: MatChipInputEvent): void {
    // Add approver only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
  }

  remove(production: ProductionDAO): void {
    const index = this.productions.indexOf(production);
    if (index > 0) {
      this.productions.splice(index, 1);
      this.productionList.push(production);
      this.filteredProductionList = of(this.productionList);
    }
  }

  selected(event: MatAutocompleteSelectedEvent) {
    let index = this.productions.indexOf(event.option.value);
    if (index < 0) {
      this.productions.push(event.option.value);
      let listIndex = this.productionList.indexOf(event.option.value);
      this.productionList.splice(listIndex, 1);
      this.productionInput.nativeElement.value = '';
      this.productionCtrl.setValue(null);
      this.selectedProductionList.emit(this.productions);
    }
  }
}
