import { Component, OnInit, Input } from '@angular/core';
import { SalesService } from '@app/sales/sales.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { PaymentType } from '../../../model/paymentType.model';

@Component({
  selector: 'app-select-payment-via',
  templateUrl: './select-payment-via.component.html',
  styleUrls: ['./select-payment-via.component.scss']
})
export class SelectPaymentViaComponent implements OnInit {
  public paymentViaList: Array<PaymentType>;
  @Input() paymentViaCtrl = new FormControl();
  @Input() isEditable = true;
  @Input() tabindex: number;
  public filteredPaymentViaList: Observable<PaymentType[]>;
  constructor(private salesService: SalesService) {}

  ngOnInit() {
    // Call the method getPaymentViaList and get the result in a callback
    this.getPaymentViaList(paymentViaList => {
      this.filteredPaymentViaList = paymentViaList;
    });
  }

  //method for get payment-via list
  getPaymentViaList(fn: (paymentViaList: Observable<PaymentType[]>) => void) {
    this.salesService.getPaymentViaList().subscribe(res => {
      if (res.data && res.data.rows) {
        this.paymentViaList = res.data.rows;
      } else {
        this.paymentViaList = [];
      }
      fn(
        (this.filteredPaymentViaList = this.paymentViaCtrl.valueChanges.pipe(
          startWith(this.paymentViaCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(paymentVia =>
            paymentVia && paymentVia.length > 1
              ? this.filterPaymentVia(paymentVia)
              : this.paymentViaList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddReceiptComponent
   * Method to filter the paymentVia type based on input
   *
   */
  private filterPaymentVia(name: string) {
    return this.paymentViaList.filter(
      paymentVia =>
        paymentVia.payment_type_name
          .toLowerCase()
          .indexOf(name.toLowerCase()) >= 0
    );
  }

  displaySelectedPaymentVia(paymentVia: PaymentType) {
    return paymentVia ? paymentVia.payment_type_name : paymentVia;
  }
}
