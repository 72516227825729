import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SalesService } from '../../../sales/sales.service';
import { RevenueSegment } from '../../../model/revenueSegment.model';
import {
  MatAutocomplete,
  MatChipInputEvent,
  MatAutocompleteSelectedEvent
} from '@angular/material';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-select-revenue-segment',
  templateUrl: './select-revenue-segment.component.html',
  styleUrls: ['./select-revenue-segment.component.scss']
})
export class SelectRevenueSegmentComponent implements OnInit {
  @Output() selectedRevenueSegmentList = new EventEmitter();
  @ViewChild('revenueSegmentInput') revenueSegmentInput: ElementRef<
    HTMLInputElement
  >;
  @ViewChild('revenueSegment') matAutocomplete: MatAutocomplete;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() revenueSegments: RevenueSegment[] = [];
  @Input() revenueSegmentCtrl = new FormControl();
  @Input() isEditable = true;
  @Input() tabindex: number;
  revenueSegmentList: RevenueSegment[];
  filteredRevenueSegmentList: Observable<RevenueSegment[]>;

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    this.removable = this.isEditable;
    // Call the method getRevenueSegmentList and get the result in a callback
    this.getRevenueSegmentList(revenueSegmentList => {
      this.filteredRevenueSegmentList = revenueSegmentList;
    });
  }

  /**
   *
   *
   * @param {(revenueSegmentList: Observable<RevenueSegment[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete RevenueSegment list
   */
  getRevenueSegmentList(
    fn: (revenueSegmentList: Observable<RevenueSegment[]>) => void
  ) {
    this.salesService.getRevenueSegment().subscribe(res => {
      if (res.data && res.data.rows) {
        this.revenueSegmentList = res.data.rows;
      } else {
        this.revenueSegmentList = [];
      }
      fn(
        (this.filteredRevenueSegmentList = this.revenueSegmentCtrl.valueChanges.pipe(
          startWith(this.revenueSegmentCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(revenueSegment =>
            revenueSegment && revenueSegment.length > 1
              ? this.filterSource(revenueSegment)
              : this.revenueSegmentList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} source
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected source
   */
  public displaySelectedRevenueSegment(revenueSegment: RevenueSegment) {
    return revenueSegment
      ? revenueSegment.revenue_segment_name
      : revenueSegment;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the revenueSegment type based on input
   *
   */
  private filterSource(name: string) {
    return this.revenueSegmentList.filter(
      revenueSegment =>
        revenueSegment.revenue_segment_name
          .toLowerCase()
          .indexOf(name.toLowerCase()) >= 0
    );
  }

  add(event: MatChipInputEvent): void {
    // Add approver only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
  }

  remove(revenueSegment: RevenueSegment): void {
    const index = this.revenueSegments.indexOf(revenueSegment);
    if (index >= 0) {
      this.revenueSegments.splice(index, 1);
      this.revenueSegmentList.push(revenueSegment);
      this.filteredRevenueSegmentList = of(this.revenueSegmentList);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let index = this.revenueSegments.indexOf(event.option.value);
    if (index < 0) {
      this.revenueSegments.push(event.option.value);
      let listIndex = this.revenueSegmentList.indexOf(event.option.value);
      this.revenueSegmentList.splice(listIndex, 1);
      this.revenueSegmentInput.nativeElement.value = '';
      this.revenueSegmentCtrl.setValue(null);
      this.selectedRevenueSegmentList.emit(this.revenueSegments);
    }
  }
}
