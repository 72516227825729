import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
import { environment } from '@env/environment';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe extends DatePipe implements PipeTransform {
  public dateFormat: string = environment.dateFormat;
  public timeOffset: number = moment().utcOffset();
  transform(
    value: string | Date,
    format: string = environment.dateFormat,
    timezone: string = moment.tz.guess()
  ): string {
    return super.transform(value, format, timezone);
  }
}
