import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  Inject
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpEventType,
  HttpResponse
} from '@angular/common/http';
import { MatFileUploadQueueComponent } from '../mat-file-upload-queue/mat-file-upload-queue.component';
import { MatSnackBar, MatTabGroup } from '@angular/material';

@Component({
  selector: 'app-mat-file-upload',
  templateUrl: './mat-file-upload.component.html',
  styleUrls: ['./mat-file-upload.component.scss'],
  exportAs: 'matFileUpload',
  host: {
    class: 'app-mat-file-upload'
  }
})
export class MatFileUploadComponent implements OnDestroy {
  isUploading: boolean = false;
  progressPercentage: number = 0;
  loaded: number = 0;
  total: number = 0;
  private _file: any;
  private _id: number;
  private fileUploadSubscription: any;

  /**
   * Http request input bindings
   */
  @Input()
  httpUrl: string = '';

  @Input()
  uploadFormData: any;

  @Input()
  matTabGroup: MatTabGroup;

  @Input()
  httpRequestHeaders:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      } = new HttpHeaders().set('Content-Type', 'multipart/form-data');

  @Input() httpRequestParams:
    | HttpParams
    | {
        [param: string]: string | string[];
      } = new HttpParams();

  @Input()
  fileAlias: string = 'file';

  @Input()
  get file(): any {
    return this._file;
  }

  set file(file: any) {
    this._file = file;
    this.total = this._file.size;
  }

  @Input()
  get id(): any {
    return this._id;
  }

  set id(id: any) {
    this._id = id;
  }

  /**
   * Output
   */
  @Output() removeEvent = new EventEmitter<MatFileUploadComponent>();
  @Output() onUpload = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    @Inject(forwardRef(() => MatFileUploadQueueComponent))
    public matFileUploadQueue: MatFileUploadQueueComponent,
    private snackbar: MatSnackBar
  ) {
    if (matFileUploadQueue) {
      this.httpUrl = matFileUploadQueue.httpUrl || this.httpUrl;
      this.httpRequestHeaders =
        matFileUploadQueue.httpRequestHeaders || this.httpRequestHeaders;
      this.httpRequestParams =
        matFileUploadQueue.httpRequestParams || this.httpRequestParams;
      this.fileAlias = matFileUploadQueue.fileAlias || this.fileAlias;
    } else {
    }
  }

  public upload(): void {
    if (!this.uploadFormData && this.matFileUploadQueue) {
      this.uploadFormData = this.matFileUploadQueue.uploadFormData;
    }

    if (this._file && this.uploadFormData) {
      this.isUploading = true;
      let formData = new FormData();
      formData.set(this.fileAlias, this._file, this._file.name);
      formData.set(
        'branch_master_id',
        this.uploadFormData && this.uploadFormData.branch_ctrl
          ? this.uploadFormData.branch_ctrl.branch_master_id
          : ''
      );
      formData.set(
        'project_master_id',
        this.uploadFormData && this.uploadFormData.project_ctrl
          ? this.uploadFormData.project_ctrl.project_master_id
          : ''
      );
      formData.set('date', this.uploadFormData ? this.uploadFormData.date : '');
      formData.set(
        'customer_master_id',
        this.uploadFormData && this.uploadFormData.customer_ctrl
          ? this.uploadFormData.customer_ctrl.customer_master_id
          : ''
      );
      formData.set(
        'account_master_id',
        this.uploadFormData && this.uploadFormData.rev_account
          ? this.uploadFormData.rev_account.account_master_id
          : ''
      );
      this.fileUploadSubscription = this.httpClient
        .post(this.httpUrl, formData, {
          observe: 'events',
          params: this.httpRequestParams,
          reportProgress: true,
          responseType: 'json'
        })
        .subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progressPercentage = Math.floor(
                (event.loaded * 100) / event.total
              );
              this.loaded = event.loaded;
              this.total = event.total;
            } else if (event instanceof HttpResponse) {
              //this.matTabGroup.selectedIndex = 0;
              // setTimeout(() => {
              //  this.remove();
              //}, 500);
            }
            this.onUpload.emit({ file: this._file, event: event });
          },
          (error: any) => {
            if (this.fileUploadSubscription) {
              this.fileUploadSubscription.unsubscribe();
            }
            this.isUploading = false;
            this.onUpload.emit({ file: this._file, event: event });
          }
        );
    } else {
      this.snackbar.open('Please select file to upload.', 'OK', {
        duration: 2000
      });
    }
  }

  public remove(): void {
    if (this.fileUploadSubscription) {
      this.fileUploadSubscription.unsubscribe();
    }
    this.removeEvent.emit(this.file);
  }

  ngOnDestroy(): void {
    console.log('File' + this._file.name + 'destroyed.');
  }
}
