import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  constructor(private http: HttpClient) {}

  /********************** Account Group ***********************/

  // Get All Account Group List
  getAccountGroup(): Observable<any> {
    //return this.http.disableApiPrefix().get('/assets/mock/customer.mock.json');
    return this.http.get('/accountGroup');
  }

  /********************** Material Master Group ***********************/

  // Get All Material Master List
  getMaterialMaster(data?: any): Observable<any> {
    //return this.http.disableApiPrefix().get('/assets/mock/customer.mock.json');
    return this.http.get('/material', {
      params: data
    });
  }

  getOutputMaterialMaster(): Observable<any> {
    return this.http.get('/material/output');
  }

  getInputMaterialMaster(): Observable<any> {
    return this.http.get('/material/input');
  }

  // Get All Material Master List
  getMaterialsByAccountMaster(accountMaster: number): Observable<any> {
    //return this.http.disableApiPrefix().get('/assets/mock/customer.mock.json');
    return this.http.get('/material/accountMasterId/' + accountMaster);
  }

  // Get total matrial qty
  getTotalMaterialQty(materialId: number): Observable<any> {
    return this.http.get('/materialTransaction/materialId/' + materialId);
  }

  /********************** CUSTOMER ***********************/

  // Get All Customers List
  getCustomer(): Observable<any> {
    // return this.http.disableApiPrefix().get('/assets/mock/customer.mock.json');
    return this.http.get('/customer');
  }

  // Get customer by vendor id
  getCustomerByVendorId(vendorId: number): Observable<any> {
    return this.http.get('/customer/vendorId/' + vendorId);
  }

  //Add New Customer
  addCustomer(data: any): Observable<any> {
    return this.http.post('/customer', data);
  }

  /********************** BOOKING ***********************/

  // Get All Booking List
  getBooking(data: any): Observable<any> {
    return this.http.get('/booking', {
      params: data
    });
  }

  // Get All Pending Booking List
  getPendingBooking(data: any): Observable<any> {
    return this.http.get('/booking/pending', {
      params: data
    });
  }

  // Get Booking By Id
  getBookingById(id: number): Observable<any> {
    return this.http.get('/booking/' + id);
  }

  // Get Booking By Id
  getTagsByBookingId(id: number): Observable<any> {
    return this.http.get('/booking/tags/' + id);
  }

  // Get Booking By Id
  getBookingDetailsByBookingId(id: number): Observable<any> {
    return this.http.get('/booking/details/' + id);
  }

  // Save Booking
  saveBooking(data: any): Observable<any> {
    return this.http.post('/booking', data);
  }

  // Search Pending Booking
  searchPendingBooking(data: any): Observable<any> {
    return this.http.post('/booking/pendingSearch', data);
  }

  // Search  Booking
  searchBooking(data: any): Observable<any> {
    return this.http.post('/booking/search', data);
  }

  // change Booking status
  changeBookingStatus(data: any): Observable<any> {
    return this.http.put('/booking/changeStatus', data);
  }

  // Update Credit Note
  updateBooking(data: any): Observable<any> {
    return this.http.put('/booking', data);
  }

  getBookingNumber(data: string): Observable<any> {
    return this.http.get('/booking/bookingNo', {
      params: {
        prefix: data
      }
    });
  }

  //close booking
  closeBooking(data: any) {
    return this.http.put('/booking/close', data);
  }

  /************************* INVOICE *************************/

  // Save Invoice
  saveInvoice(data: any): Observable<any> {
    return this.http.post('/invoice', data);
  }

  // Update Invoice
  updateInvoice(data: any): Observable<any> {
    return this.http.put('/invoice', data);
  }

  // change Invoice status
  changeInvoiceStattus(data: any): Observable<any> {
    return this.http.put('/invoice/changeStatus', data);
  }

  // Get All Invoice List
  getInvoice(data: any): Observable<any> {
    return this.http.get('/invoice', {
      params: data
    });
  }

  // Get All Pending Invoice List
  getPendingInvoice(data: any): Observable<any> {
    return this.http.get('/invoice/pending', {
      params: data
    });
  }

  // Get Invoice By Id
  getInvoiceById(id: number): Observable<any> {
    return this.http.get('/invoice/' + id);
  }

  // Get Invoice By Id
  getTagsByInvoiceId(id: number): Observable<any> {
    return this.http.get('/invoice/tags/' + id);
  }

  // Get Invoice By Id
  getInvoiceDetailsByInvoiceId(id: number): Observable<any> {
    return this.http.get('/invoice/details/' + id);
  }

  // Search Invoice List
  searchInvoice(data: any): Observable<any> {
    return this.http.post('/invoice/search', data);
  }

  // Search Pending Invoice List
  searchPendingInvoice(data: any): Observable<any> {
    return this.http.post('/invoice/pendingSearch', data);
  }

  getInvoiceNumber(data: string): Observable<any> {
    return this.http.get('/invoice/invoiceNo', {
      params: {
        prefix: data
      }
    });
  }

  // get total invoice details by booking id
  getInvoiceDetailsByBookingDetailId(id: number): Observable<any> {
    return this.http.get('/invoice/details/bookingDetailId/' + id);
  }

  /********************** INVOICE RECIEPT ***********************/

  // Get All Invoice Reciept List
  getInvoiceReceipt(data: any): Observable<any> {
    return this.http.get('/invoicePayment', {
      params: data
    });
  }

  // Get All Invoice List for payment
  getInvoiceForPayment(data: any): Observable<any> {
    return this.http.get('/invoice/forPayment', {
      params: data
    });
  }

  // search All Invoice List for payment
  searchInvoiceForPayment(data: any): Observable<any> {
    return this.http.post('/invoice/forPayment/search', {
      params: data
    });
  }

  // Search Invoice Reciept List
  searchInvoiceReceipt(data: any): Observable<any> {
    return this.http.post('/invoicePayment/search', data);
  }

  //save adjustment payment
  addAdjustInvoicePayment(data: any): Observable<any> {
    return this.http.post('/invoicePayment/adjustInvoicePayment', data);
  }

  // Get List of all pending Invoice Reciept List
  getPendingInvoiceReceipt(): Observable<any> {
    return this.http
      .disableApiPrefix()
      .get('/assets/mock/pending-invoice-receipt.mock.json');
  }

  // Search pending Invoice Reciept List
  searchPendingInvoiceReceipt(): Observable<any> {
    return this.http
      .disableApiPrefix()
      .get('/assets/mock/pending-invoice-receipt.mock.json');
  }

  addInvoiceReceipt(data: any): Observable<any> {
    return this.http.post('/invoicePayment', data);
  }

  addMultipleInvoiceReceipt(data: any): Observable<any> {
    return this.http.post('/invoicePayment/multiple', data);
  }

  getInvoicePaymentById(id: any): Observable<any> {
    return this.http.get('/invoicePayment/id/' + id);
  }

  deleteInvoicePayment(id: any): Observable<any> {
    return this.http.delete('/invoicePayment/id/' + id);
  }

  getInvoiceReceiptForExport(
    limit: number,
    skip: number,
    sortby: string,
    sortas: string
  ): Observable<any> {
    return this.http.get('/invoicePayment/forExport', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }

  /********************** CREDIT NOTE ***********************/

  // Get List of all credit notes
  getCreditNote(data: any): Observable<any> {
    return this.http.get('/creditNote', {
      params: data
    });
  }

  // Get List of all Pending credit notes
  getPendingCreditNote(data: any): Observable<any> {
    return this.http.get('/creditNote/pending', {
      params: data
    });
  }

  getCNNumber(data: string): Observable<any> {
    return this.http.get('/creditNote/creditNoteNo', {
      params: {
        prefix: data
      }
    });
  }

  // Get CreditNote By Id
  getCreditNoteById(id: number): Observable<any> {
    return this.http.get('/creditNote/' + id);
  }

  // Get CreditNote By Id
  getTagsByCreditNoteId(id: number): Observable<any> {
    return this.http.get('/creditNote/tags/' + id);
  }

  // Get CreditNote By Id
  getCreditNoteDetailsByCNId(id: number): Observable<any> {
    return this.http.get('/creditNote/details/' + id);
  }

  // Get CreditNote By Id
  getCreditNoteDetailsByInvoiceDetailId(id: number): Observable<any> {
    return this.http.get('/creditNote/details/invoiceDetailId/' + id);
  }

  // Search creditNote List
  searchCreditNote(data: any): Observable<any> {
    return this.http.post('/creditNote/search', data);
  }

  // delete creditNote
  deleteCreditNote(data: any): Observable<any> {
    return this.http.put('/creditNote/delete', data);
  }

  // Search Pending creditNote List
  searchPendingCreditNote(data: any): Observable<any> {
    return this.http.post('/creditNote/pendingSearch', data);
  }

  // Save Credit Note
  saveCreditNote(data: any): Observable<any> {
    return this.http.post('/creditNote', data);
  }

  // Update Credit Note
  updateCreditNote(data: any): Observable<any> {
    return this.http.put('/creditNote', data);
  }

  // Change Credit Note status
  changeCreditNoteStatus(data: any): Observable<any> {
    return this.http.put('/creditNote/changeStatus', data);
  }

  /*######################## MOVE TO MASTER - START ###############*/

  /********************** TAX TYPE ***********************/

  // Get All Tax Types List
  getTaxType(): Observable<any> {
    return this.http.get('/taxType', {});
  }

  /********************** ITC TYPE ***********************/

  // Get All ITC Types List
  getITCType(): Observable<any> {
    return this.http.get('/itcType', {});
  }

  /********************** SALES TYPE ***********************/

  // Get All Sales Types List
  getSalesType(): Observable<any> {
    return this.http.get('/salesType');
  }

  /********************** BRANCHES ***********************/

  // Get All Branches List
  getBranch(): Observable<any> {
    return this.http.get('/branch', {});
  }

  /********************** PROJECT ***********************/

  // Get All Projects List
  getProject(): Observable<any> {
    return this.http.get('/project', {});
  }

  getProjectByBranchId(id: number): Observable<any> {
    return this.http.get('/project/branchId/' + id);
  }

  getProjectByEntityId(id: number): Observable<any> {
    return this.http.get('/project/entityId/' + id);
  }

  getProjectByBranchIdList(ids: number[]): Observable<any> {
    return this.http.post('/project/branchIdList', { branchIdList: ids });
  }

  getProjectByEntityIdList(ids: number[]): Observable<any> {
    return this.http.post('/project/entityIdList', { entityIdList: ids });
  }

  /********************** STATE ***********************/

  // Get All states List
  getState(): Observable<any> {
    return this.http.get('/state', {});
  }

  // Get All states List
  getStateByCountryId(countryId: number): Observable<any> {
    return this.http.get('/state/' + countryId);
  }

  /********************** COUNTRY ***********************/

  // Get All countries List
  getCountry(): Observable<any> {
    return this.http.get('/country', {});
  }

  /********************** E COMMERCE ***********************/

  // Get All E Commerce List
  getECommerce(): Observable<any> {
    return this.http
      .disableApiPrefix()
      .get('/assets/mock/e-commerce.mock.json');
  }

  /********************** CESS TYPE ***********************/

  // Get All Projects List
  getCessType(): Observable<any> {
    return this.http.get('/cessType', {});
  }

  /********************** STAFF ***********************/

  // Get All Staff List
  getStaff(): Observable<any> {
    //return this.http.disableApiPrefix().get('/assets/mock/staff.mock.json');
    return this.http.get('/staff', {});
  }

  /********************** SOURCE ***********************/

  // Get All Source List
  getSource(): Observable<any> {
    return this.http.get('/source', {});
  }

  /********************** Revenue Segment ***********************/

  // Get All RevenueSegment List
  getRevenueSegment(): Observable<any> {
    return this.http.get('/revenueSegment', {});
  }

  /********************** Assset ***********************/

  // Get All Asset List
  getAsset(): Observable<any> {
    return this.http.get('/asset', {});
  }

  /********************** PAYMENT TYPE ***********************/

  // Get Payment Via list
  getPaymentViaList(): Observable<any> {
    return this.http.get('/paymentType');
  }

  /********************** PAYMENT MODE ***********************/

  // Get List of all payment modes
  getPaymentModeList(): Observable<any> {
    return this.http.get('/paymentMode');
  }

  /*######################## MOVE TO MASTER - END ###############*/

  /*######################## MOVE TO PURCHASE - START ###############*/

  /********************** BILLS ***********************/

  // Get List of all bills
  getBills(
    limit: number,
    skip: number,
    sortby: string,
    sortas: string
  ): Observable<any> {
    return this.http.get('/bill', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }

  // Get List of all pending bills
  getPendingBills(
    pendingLimit: number,
    pendingSkip: number,
    pendingSortby: string,
    pendingSortas: string
  ): Observable<any> {
    return this.http.get('/bill/pending', {
      params: {
        limit: pendingLimit.toString(),
        skip: pendingSkip.toString(),
        sortby: pendingSortby.toString(),
        sortas: pendingSortas.toString()
      }
    });
  }

  // Get List of all bills for export
  getBillsForExport(
    limit: number,
    skip: number,
    sortby: string,
    sortas: string
  ): Observable<any> {
    return this.http.get('/bill/forExport', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }

  /*######################## MOVE TO PURCHASE - END ###############*/

  /*######################## MOVE TO USER - START ###############*/

  /********************** APPROVERS ***********************/

  // Get All Approvers List
  getApprover(): Observable<any> {
    return this.http.disableApiPrefix().get('/assets/mock/cessType.mock.json');
  }
  /********************** USERS ***********************/

  // Get List of users
  getUser(): Observable<any> {
    //return this.http.disableApiPrefix().get('/assets/mock/user.mock.json');
    return this.http.get('/user', {});
  }

  // Get List of approvvers
  getApprovers(): Observable<any> {
    //return this.http.disableApiPrefix().get('/assets/mock/user.mock.json');
    return this.http.get('/user/approvers', {});
  }

  /*######################## MOVE TO USER - END ###############*/
}
