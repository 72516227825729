import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { Chart, ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

// Internal component imports
import { HomeService } from './home.service';
import { MasterService } from '@app/master/master.service';
import { EntityService } from '@app/entity/entity.service';
import { MatOption, MatSelect } from '@angular/material';
import { BranchMaster } from '@app/model/branchMaster.model';
import { CredentialsService } from '@app/core';
import { SharedServiceService } from '@app/shared/shared-service.service';
import { LegalEntityMaster } from '@app/model/legalEntityMaster.model';
import { DestroySubscribers } from '@app/shared/destroy-subscribers.decorator';

// Define component
@DestroySubscribers()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})

// Declare class
export class HomeComponent implements OnInit {
  // Total Project Expense - Pie Chart
  public TotalProjectExpensesPieChartOptions: ChartOptions;
  public TotalProjectExpensesPieChartLabels: Label[];
  public TotalProjectExpensesPieChartData: number[];
  public TotalProjectExpensesChartType: ChartType;
  public TotalProjectExpensesPieChartLegend = true;
  public TotalProjectExpensesPieChartPlugins = [pluginDataLabels];
  public TotalProjectExpensesPieChartColors: Array<any>;
  public vendorStatusCount: any;
  public purchaseOrderStatusCount: any;
  public billStatusCount: any;
  public subscribers: any = {};
  // Total Vendor Balances - Pie Chart
  public TotalVendorBalancesChartOptions: any; //(ChartOptions & { annotation: any });
  public TotalVendorBalancesChartLabels: Label[];
  public TotalVendorBalancesChartData: number[][];
  public TotalVendorBalancesChartType: ChartType;
  public TotalVendorBalancesChartLegend = true;
  public TotalVendorBalancesChartPlugins = [pluginDataLabels];
  public TotalVendorBalancesChartColors: Array<any>;

  // Initialise
  public Math = Math;
  public ExpenseHeadwiseFilterForm: FormGroup;
  public ExpenseBranchwiseFilterForm: FormGroup;
  public VendorBalancesFilterForm: FormGroup;
  public TotalProjectExpenseFilterForm: FormGroup;
  public totalProjectExpenseVsBudgetFilterForm: FormGroup;
  // Entity, Branch, Project list (Complete and filtered)
  public projectList: Array<any>;
  public branchList: Array<any>;
  public entityList: Array<any>;
  public completeProjectList: Array<any>;
  public completeBranchList: Array<any>;
  public completeEntityList: Array<any>;
  public latestEntities: Array<any>;
  public latestBranches: Array<any>;
  public latestProjects: Array<any>;

  public totalProjectExpenseVsBudgetFlag: boolean = false;
  public TotalProjectExpensesVsBudgetPieChartLabels: Array<any> = [];
  public TotalProjectExpensesVsBudgetPieChartData: Array<any> = [];
  public TotalProjectExpensesVsBudgetPieChartOptions: ChartOptions;
  public TotalProjectExpensesVsBudgetChartType: string = '';
  public TotalProjectExpensesVsBudgetPieChartLegend: boolean = false;
  public TotalProjectExpensesVsBudgetPieChartPlugins: Array<any> = [];

  // Initialise (Report data variable)
  public topExpenseList: Array<any>;
  public topVendorsPayableList: Array<any>;
  public topAdvancesPendingList: Array<any>;
  public expenseHeadwiseFlag: boolean = false;
  public expenseBranchwiseFlag: boolean = false;
  public vendorBalancesList: Array<any>;
  public totalProjectExpenseList: Array<any>;
  public selectedProjectExpBtnGroupVal: string;
  public selectedProjectExpVsBudgetBtnGroupVal: string;
  public selectedBranchExpBtnGroupVal: string;
  public selectedVendorBalBtnGroupVal: string;
  public selectedHeadWiseExpBtnGroupVal: string;

  public barchartOptions: any;
  public barChartData: any;
  public barchartLabels: any;
  public barchartDataset: any;
  public expenseHeadwiseLabel: Array<String> = [];
  public expenseHeadwiseTableLabel: Array<String> = [];
  public expenseHeadwiseDataset: any;

  public expenseBranchwiseLabel: Array<String> = [];
  public expenseBranchwiseDataset: any;
  @ViewChild('allSelectedBranch') private allSelectedBranch: MatOption;
  @ViewChild('matSelectBranch') private matSelectBranch: MatSelect;
  @ViewChild('allSelectedProject') private allSelectedProject: MatOption;
  @ViewChild('matSelectProject') private matSelectProject: MatSelect;

  public selectedEntity: LegalEntityMaster;
  public selectedEntityId: any;
  public chartColors: any[] = [
    {
      backgroundColor: [
        '#496063',
        '#6C6F39',
        '#F0B24F',
        '#BC5B07',
        '#892319',
        '#4D2501',
        '#5D7578',
        '#7F824C'
      ]
    }
  ];

  public barChartColors: any[] = [
    {
      backgroundColor: '#496063'
    },
    {
      backgroundColor: '#7F824C'
    },
    {
      backgroundColor: '#F0B24F'
    },
    {
      backgroundColor: '#BC5B07'
    },
    {
      backgroundColor: '#892319'
    },
    {
      backgroundColor: '#4D2501'
    },
    {
      backgroundColor: '#5D7578'
    },
    {
      backgroundColor: '#6C6F39'
    }
  ];

  // Constructor
  constructor(
    private homeService: HomeService,
    private entityService: EntityService,
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    public credentialsService: CredentialsService,
    private sharedService: SharedServiceService
  ) {
    // Set initial value
    this.projectList = [];
    this.branchList = [];
    this.entityList = [];
    this.completeProjectList = [];
    this.completeBranchList = [];
    this.completeEntityList = [];
    this.latestEntities = [];
    this.latestBranches = [];
    this.latestProjects = [];
    this.selectedProjectExpBtnGroupVal = 'projectExpenseBarChart';
    this.selectedVendorBalBtnGroupVal = 'vendorBalanceChartData';
    this.selectedBranchExpBtnGroupVal = 'expenseBranchBarChart';
    this.selectedHeadWiseExpBtnGroupVal = 'expenseHeadwiseBarChart';
    this.selectedProjectExpVsBudgetBtnGroupVal =
      'projectExpenseVsBudgetBarChart';

    // Set initial value (Report data variable)
    this.topExpenseList = [];
    this.topVendorsPayableList = [];
    this.topAdvancesPendingList = [];
    this.expenseHeadwiseFlag = false;
    this.expenseBranchwiseFlag = false;
    this.vendorBalancesList = [];
    this.totalProjectExpenseList = [];
  }

  /**
   * Init method - Methods has to be called on load to display widgets after
   * it initialises the filter bar. Since its onload, all the values will
   * be set as null at this point of time.
   */
  ngOnInit() {
    if (localStorage.hasOwnProperty('selectedEntity')) {
      let selectedEntityValue = localStorage.getItem('selectedEntity');
      if (
        selectedEntityValue !== 'undefined' &&
        selectedEntityValue !== null &&
        selectedEntityValue !== '' &&
        typeof selectedEntityValue !== 'undefined'
      ) {
        this.selectedEntity = JSON.parse(
          localStorage.getItem('selectedEntity')
        );
        this.selectedEntityId = this.selectedEntity.legal_entity_master_id;
      }
    }

    this.barchartLabels = [
      'Absence of OB',
      'Closeness',
      'Credibility',
      'Heritage',
      'M Disclosure',
      'Provenance',
      'Reliability',
      'Transparency'
    ];
    this.barchartDataset = [
      {
        label: 'American Express',
        // backgroundColor: "pink",
        // borderColor: "red",
        // borderWidth: 1,
        // fill: true,
        data: [3, 5, 6, 7, 3, 5, 6, 7]
      },
      {
        label: 'Mastercard',
        // backgroundColor: "lightblue",
        // borderColor: "blue",
        // borderWidth: 1,
        // fill: true,
        data: [4, 7, 3, 6, 10, 7, 4, 6]
      },
      {
        label: 'Paypal',
        // backgroundColor: "lightgreen",
        // borderColor: "green",
        // borderWidth: 1,
        // fill: true,
        data: [10, 7, 4, 6, 9, 7, 3, 10]
      },
      {
        label: 'Visa',
        // backgroundColor: "yellow",
        // borderColor: "orange",
        // borderWidth: 1,
        // fill: true,
        data: [6, 9, 7, 3, 10, 7, 4, 6]
      }
    ];

    this.barchartOptions = {
      responsive: true, // Instruct chart js to respond nicely.
      maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
      legend: {
        position: 'right'
      },
      title: {
        display: false,
        text: 'Chart'
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: false
            }
          }
        ]
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    };
    // this.getLatestEntities();
    // this.getLatestBranches();
    // this.getLatestProjects();
    this.defaultDashboardFilterValues((isSuccess: boolean) => {
      if (isSuccess) {
        this.createExpenseHeadwiseFilterForm();
        this.createExpenseBranchwiseFilterForm();
        this.createVendorBalancesFilterForm();
        this.createTotalProjectExpenseFilterForm();
        this.createTotalProjectExpenseVsBudgetFilterForm();
      }
    });
    this.getAllVendorStatusCount();
    this.getAllPurchaseOrderStatusCount();
    this.getAllBillStatusCount();
  }

  ngAfterViewInit() {
    //call my function on entity changes
    this.subscribers.entitySelectorHome = this.sharedService.entitySelector$.subscribe(
      entity => {
        if (
          this.selectedEntityId &&
          entity &&
          this.selectedEntityId != entity.legal_entity_master_id
        ) {
          this.ExpenseHeadwiseFilterForm.patchValue({
            branch: null,
            project: null
          });
          this.selectedEntityId = entity ? entity.legal_entity_master_id : '';
          this.getActiveBranches();
          this.ExpenseHeadwiseFilter();
        }
      }
    );
  }

  getActiveBranches() {
    // Get the complete list of active branches
    this.masterService
      .getBranchForDropdown(this.selectedEntityId)
      .subscribe(res => {
        if (res.data && res.data.rows) {
          this.branchList = res.data.rows;
          this.completeBranchList = res.data.rows;
        }
      });
  }

  toggleBranchPerOne() {
    if (this.allSelectedBranch.selected) {
      this.allSelectedBranch.deselect();
      return false;
    }
    if (
      this.ExpenseHeadwiseFilterForm.controls.branch.value.length ==
      this.completeBranchList.length
    )
      this.allSelectedBranch.select();
  }

  toggleAllBranchSelection() {
    if (this.allSelectedBranch.selected) {
      this.matSelectBranch.options.forEach((item: MatOption) => item.select());
      // this.ExpenseHeadwiseFilterForm.controls.branch
      //   .patchValue([0, ...this.completeBranchList.map(item => item)]);
    } else {
      //this.ExpenseHeadwiseFilterForm.controls.branch.patchValue([]);
      this.matSelectBranch.options.forEach((item: MatOption) =>
        item.deselect()
      );
    }
  }

  toggleProjectPerOne() {
    if (this.allSelectedProject.selected) {
      this.allSelectedProject.deselect();
      return false;
    }
    if (
      this.ExpenseHeadwiseFilterForm.controls.project.value.length ==
      this.projectList.length
    )
      this.allSelectedProject.select();
  }

  toggleAllProjectSelection() {
    if (this.allSelectedProject.selected) {
      this.matSelectProject.options.forEach((item: MatOption) => item.select());
      // this.ExpenseHeadwiseFilterForm.controls.branch
      //   .patchValue([0, ...this.completeBranchList.map(item => item)]);
    } else {
      //this.ExpenseHeadwiseFilterForm.controls.branch.patchValue([]);
      this.matSelectProject.options.forEach((item: MatOption) =>
        item.deselect()
      );
    }
  }

  getLatestEntities(fn: any) {
    // Get small list of latest etities
    this.entityService.getLatestEntities().subscribe(res => {
      if (res.data) {
        this.latestEntities = res.data;
        fn(true);
      } else {
        fn(false);
      }
    });
  }

  getLatestBranches(fn: any) {
    // Get small list of latest branches
    this.masterService.getLatestBranches().subscribe(res => {
      if (res.data) {
        this.latestBranches = res.data;
        fn(true);
      } else {
        fn(false);
      }
    });
  }

  getLatestProjects(fn: any) {
    // Get small list of latest projects
    this.masterService.getLatestProjects().subscribe(res => {
      if (res.data) {
        this.latestProjects = res.data;
        fn(true);
      } else {
        fn(false);
      }
    });
  }

  /************************ EXPENSE HEADWISE WIDGET ************************/

  /**
   * Filter Form Design
   */
  createExpenseHeadwiseFilterForm() {
    this.ExpenseHeadwiseFilterForm = this.formBuilder.group({
      branch: [''],
      project: ['']
      //duration: ['6']
    });

    var expenseHeadwiseData = JSON.parse(
      localStorage.getItem('ExpenseHeadwiseFilter')
    );
    if (expenseHeadwiseData) {
      this.ExpenseHeadwiseFilterForm.patchValue({
        branch: expenseHeadwiseData.branch,
        project: expenseHeadwiseData.project
        //duration: expenseHeadwiseData.duration
      });
      var setWidgetData = {
        branches: _.map(expenseHeadwiseData.branch, 'branch_master_id'),
        projects: _.map(expenseHeadwiseData.project, 'project_master_id')
        //duration: expenseHeadwiseData.duration
      };
      this.getWidgetExpenseHeadwise(setWidgetData);
    } else if (
      this.latestBranches.length > 0 ||
      this.latestProjects.length > 0
    ) {
      this.ExpenseHeadwiseFilterForm.patchValue({
        branch: this.latestBranches,
        project: this.latestProjects
        //duration: expenseHeadwiseData.duration
      });
      var setWidgetData = {
        branches: _.map(this.latestBranches, 'branch_master_id'),
        projects: _.map(this.latestProjects, 'project_master_id')
        // duration: expenseHeadwiseData.duration
      };
      this.getWidgetExpenseHeadwise(setWidgetData);
    } else {
      this.getLatestBranches((success: boolean) => {
        if (success && this.latestBranches.length > 0) {
          this.ExpenseHeadwiseFilterForm.patchValue({
            branch: this.latestBranches,
            project: this.latestProjects
          });
          var setWidgetData = {
            branches: _.map(this.latestBranches, 'branch_master_id'),
            projects: _.map(this.latestProjects, 'project_master_id')
          };
          this.getWidgetExpenseHeadwise(setWidgetData);
        }
      });
    }
  }

  /**
   * Filter
   */
  ExpenseHeadwiseFilter() {
    let data = {};
    var branch = this.ExpenseHeadwiseFilterForm.value.branch;
    var project = this.ExpenseHeadwiseFilterForm.value.project;
    if (branch && branch.length > 0 && branch[0] === 0) {
      branch.splice(0, 1);
    }
    if (project && project.length > 0 && project[0] === 0) {
      project.splice(0, 1);
    }
    var widgetFilterParameters = this.setParametesForWidgetFilter(
      '',
      this.ExpenseHeadwiseFilterForm.value.branch,
      this.ExpenseHeadwiseFilterForm.value.project
    );
    branch =
      widgetFilterParameters.branch && widgetFilterParameters.branch.length > 0
        ? widgetFilterParameters.branch
        : branch;
    project =
      widgetFilterParameters.project &&
      widgetFilterParameters.project.length > 0
        ? widgetFilterParameters.project
        : project;

    // Report Generation based on the selection
    data = {
      //duration: this.ExpenseHeadwiseFilterForm.value.duration,
      branches: _.map(branch, 'branch_master_id'),
      projects: _.map(project, 'project_master_id')
    };

    // Set local storage
    let localStorageData = {
      branch: this.ExpenseHeadwiseFilterForm.value.branch,
      project: this.ExpenseHeadwiseFilterForm.value.project
      //duration: this.ExpenseHeadwiseFilterForm.value.duration
    };
    localStorage.setItem(
      'ExpenseHeadwiseFilter',
      JSON.stringify(localStorageData)
    );

    // Generate the widget
    this.getWidgetExpenseHeadwise(data);
  }

  /**
   * Widget Data
   */
  getWidgetExpenseHeadwise(data: any) {
    if (
      data &&
      (data.branches || data.projects) &&
      (data.branches.length > 0 || data.projects.length > 0)
    ) {
      this.homeService.getWidgetExpenseHeadwise(data).subscribe(res => {
        //this.expenseHeadwiseList = res.data;
        if (
          res.data &&
          res.data.months &&
          res.data.report &&
          res.data.report.length > 0
        ) {
          this.expenseHeadwiseLabel = res.data.months;
          this.expenseHeadwiseTableLabel = this.expenseHeadwiseLabel;
          // this.expenseHeadwiseTableLabel.unshift("");
          this.expenseHeadwiseDataset = res.data.report;
          this.expenseHeadwiseFlag = true;
        } else {
          this.expenseHeadwiseFlag = false;
        }
      });
    } else {
      this.expenseHeadwiseFlag = false;
      // this.expenseHeadwiseList = [];
    }
  }

  /************************ EXPENSE BRANCHWISE WIDGET ************************/

  /**
   * Filter Form Design
   */
  createExpenseBranchwiseFilterForm() {
    this.ExpenseBranchwiseFilterForm = this.formBuilder.group({
      entity: ['']
      //duration: ['6']
    });

    var expenseBranchwiseData = JSON.parse(
      localStorage.getItem('ExpenseBranchwiseFilter')
    );
    if (expenseBranchwiseData) {
      this.ExpenseBranchwiseFilterForm.patchValue({
        entity: expenseBranchwiseData.entity
        //duration: expenseBranchwiseData.duration
      });
      var setWidgetData = {
        entity: expenseBranchwiseData.entity.legal_entity_master_id
        //duration: expenseBranchwiseData.duration
      };
      this.getWidgetExpenseBranchwise(setWidgetData);
    } else if (this.latestEntities.length > 0) {
      this.ExpenseBranchwiseFilterForm.patchValue({
        entity: this.latestEntities[0] ? this.latestEntities[0] : null
      });
      var setWidgetData = {
        entity:
          this.latestEntities[0] && this.latestEntities[0]
            ? this.latestEntities[0].legal_entity_master_id
            : null
      };
      this.getWidgetExpenseBranchwise(setWidgetData);
    } else {
      this.getLatestEntities((isSuccess: boolean) => {
        if (isSuccess && this.latestEntities.length > 0) {
          this.ExpenseBranchwiseFilterForm.patchValue({
            entity: this.latestEntities[0] ? this.latestEntities[0] : null
          });
          var setWidgetData = {
            entity:
              this.latestEntities[0] && this.latestEntities[0]
                ? this.latestEntities[0].legal_entity_master_id
                : null
          };
          this.getWidgetExpenseBranchwise(setWidgetData);
        }
      });
    }
  }

  /**
   * Filter
   */
  ExpenseBranchwiseFilter() {
    let data = {};

    // Report Generation based on the selection
    data = {
      // duration: this.ExpenseBranchwiseFilterForm.value.duration,
      entity: this.ExpenseBranchwiseFilterForm.value.entity
        .legal_entity_master_id
    };

    // Set local storage
    let localStorageData = {
      entity: this.ExpenseBranchwiseFilterForm.value.entity
      //duration: this.ExpenseBranchwiseFilterForm.value.duration
    };
    localStorage.setItem(
      'ExpenseBranchwiseFilter',
      JSON.stringify(localStorageData)
    );

    // Generate the widget
    this.getWidgetExpenseBranchwise(data);
  }

  /**
   * Expense Branchwise
   */
  getWidgetExpenseBranchwise(data: any) {
    if (data && data.entity) {
      this.homeService.getWidgetExpenseBranchwise(data).subscribe(res => {
        //this.expenseBranchwiseList = res.data;
        if (
          res.data &&
          res.data.months &&
          res.data.report &&
          res.data.report.length > 0
        ) {
          this.expenseBranchwiseLabel = res.data.months;
          this.expenseBranchwiseDataset = res.data.report;
          this.expenseBranchwiseFlag = true;
        } else {
          this.expenseBranchwiseFlag = false;
        }
      });
    } else {
      this.expenseBranchwiseFlag = false;
    }
  }

  /************************ VENDOR BALANCES WIDGET ************************/

  public onVendorBalValChange(val: string) {
    this.selectedVendorBalBtnGroupVal = val;
  }

  /**
   * Filter Form Design
   */
  createVendorBalancesFilterForm() {
    this.VendorBalancesFilterForm = this.formBuilder.group({
      entity: ['']
      //fromdate: [''],
      //todate: ['']
    });

    var venorBalancesData = JSON.parse(
      localStorage.getItem('VenorBalancesFilter')
    );
    if (venorBalancesData) {
      this.VendorBalancesFilterForm.patchValue({
        entity: venorBalancesData.entity
        //fromdate: venorBalancesData.fromdate,
        //todate: venorBalancesData.todate
      });
      var setWidgetData = {
        entity: venorBalancesData.entity.legal_entity_master_id
        //fromdate: venorBalancesData.fromdate,
        //todate: venorBalancesData.todate
      };
      this.getWidgetVendorBalances(setWidgetData);
    } else if (this.latestEntities.length > 0) {
      this.VendorBalancesFilterForm.patchValue({
        entity: this.latestEntities[0]
        // fromdate: venorBalancesData.fromdate,
        //todate: venorBalancesData.todate
      });
      var setWidgetData = {
        entity: this.latestEntities[0].legal_entity_master_id
        //fromdate: venorBalancesData.fromdate,
        // todate: venorBalancesData.todate
      };
      this.getWidgetVendorBalances(setWidgetData);
    } else {
      this.getLatestEntities((isSuccess: boolean) => {
        if (isSuccess && this.latestEntities.length > 0) {
          this.VendorBalancesFilterForm.patchValue({
            entity: this.latestEntities[0]
          });
          var setWidgetData = {
            entity: this.latestEntities[0].legal_entity_master_id
          };
          this.getWidgetVendorBalances(setWidgetData);
        }
      });
    }
  }

  /**
   * Filter
   */
  VendorBalancesFilter() {
    let data = {};

    // Report Generation based on the selection
    data = {
      entity: this.VendorBalancesFilterForm.value.entity.legal_entity_master_id
      //fromdate: this.VendorBalancesFilterForm.value.fromdate,
      //todate: this.VendorBalancesFilterForm.value.todate
    };

    // Set local storage
    let localStorageData = {
      entity: this.VendorBalancesFilterForm.value.entity
      //fromdate: this.VendorBalancesFilterForm.value.fromdate,
      //todate: this.VendorBalancesFilterForm.value.todate
    };
    localStorage.setItem(
      'VenorBalancesFilter',
      JSON.stringify(localStorageData)
    );

    // Generate the widget
    this.getWidgetVendorBalances(data);
  }

  /**
   * Vendor Balances
   */
  getWidgetVendorBalances(data: any) {
    if (data && data.entity) {
      this.homeService.getWidgetVendorBalances(data).subscribe(res => {
        this.vendorBalancesList = res.data;
        let chartlabel = [];
        let chartData = [];
        let chartAvgData = [];
        //let chartRGB = [];
        for (let i = 0; i < this.vendorBalancesList.length; i++) {
          //if (this.vendorBalancesList[i].amount != 0) {
          if (
            this.vendorBalancesList[i].vendor_name !== 'Total' &&
            this.vendorBalancesList[i].vendor_name !== 'Average'
          ) {
            chartlabel.push(this.vendorBalancesList[i].vendor_name);
            chartData.push(this.vendorBalancesList[i].amount);
            //chartRGB.push(this.random_rgba());
          } else if (this.vendorBalancesList[i].vendor_name === 'Average') {
            chartAvgData.push(
              -321659.9706071429,
              -321659.9706071429,
              -321659.9706071429,
              -321659.9706071429,
              -321659.9706071429
            );
          }
          //}
        }
        // chartlabel.splice(-1, 1);
        // chartData.splice(-1, 1);
        //chartRGB.splice(-1, 1);

        let dd = [chartData];
        this.TotalVendorBalancesChartLabels = chartlabel;
        this.TotalVendorBalancesChartData = dd;
        this.TotalVendorBalancesChartColors = [
          {
            backgroundColor: 'rgba(77,83,96,0.2)',
            borderColor: 'rgba(77,83,96,1)',
            pointBackgroundColor: 'rgba(77,83,96,1)',
            pointBorderColor: 'rgba(77,83,96,1)',
            pointHoverBackgroundColor: 'rgba(77,83,96,1)',
            pointHoverBorderColor: 'rgba(77,83,96,1)'
          }
        ];

        // this.TotalVendorBalancesChartColors = [
        //   {
        //     backgroundColor: chartRGB,
        //   },
        // ];
        this.TotalVendorBalancesChartOptions = {
          responsive: true, // Instruct chart js to respond nicely.
          maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
          legend: {
            display: false
          },
          elements: {
            point: {
              radius: 2,
              hitRadius: 2,
              hoverRadius: 4,
              hoverBorderWidth: 2
            },
            line: {
              tension: 0
            }
          },
          plugins: {
            datalabels: {
              display: false
            }
          },
          annotation: {
            annotations: [
              {
                type: 'box',
                yScaleID: 'y-axis-0',
                yMin: 15,
                yMax: 30,
                borderColor: 'rgba(255, 51, 51, 0.25)',
                borderWidth: 2,
                backgroundColor: 'rgba(255, 51, 51, 0.25)'
              },
              {
                type: 'box',
                yScaleID: 'y-axis-0',
                yMin: 5,
                yMax: 15,
                borderColor: 'rgba(255, 255, 0, 0.25)',
                borderWidth: 1,
                backgroundColor: 'rgba(255, 255, 0, 0.25)'
              },
              {
                type: 'box',
                yScaleID: 'y-axis-0',
                yMin: 0,
                yMax: 5,
                borderColor: 'rgba(0, 204, 0, 0.25)',
                borderWidth: 1,
                backgroundColor: 'rgba(0, 204, 0, 0.25)'
              }
            ]
          }
        };
        // annotation: {
        //   annotations: [
        //     {
        //       type: 'line',
        //       mode: 'horizontal',
        //       //scaleID: 'x-axis-0',
        //       value: 1000,
        //       borderColor: 'orange',
        //       borderWidth: 2,
        //       label: {
        //         enabled: true,
        //         fontColor: 'orange',
        //         content: 'LineAnno'
        //       }
        //     },
        //   ],
        // }
        //};
        this.TotalVendorBalancesChartType = 'line';
        this.TotalVendorBalancesChartLegend = false;
        this.TotalVendorBalancesChartPlugins = [pluginDataLabels];
      });
    } else {
      this.vendorBalancesList = [];
    }
  }

  /************************ PROJECT LIFETIME EXPENSE WIDGET ************************/

  public onProjectExpenseValChange(val: string) {
    this.selectedProjectExpBtnGroupVal = val;
  }

  onBranchExpenseValChange(val: string) {
    this.selectedBranchExpBtnGroupVal = val;
  }

  onHeadWiseExpenseValChange(val: string) {
    this.selectedHeadWiseExpBtnGroupVal = val;
  }

  compareBranchs(b1: any, b2: any): boolean {
    return b1 && b2 ? b1.branch_master_id === b2.branch_master_id : null;
  }

  compareProjects(p1: any, p2: any): boolean {
    return p1 && p2 ? p1.project_master_id === p2.project_master_id : null;
  }

  compareEntities(e1: any, e2: any): boolean {
    return e1 && e2
      ? e1.legal_entity_master_id === e2.legal_entity_master_id
      : null;
  }

  /**
   * Filter Form Design
   */
  createTotalProjectExpenseFilterForm() {
    this.TotalProjectExpenseFilterForm = this.formBuilder.group({
      project: ['']
    });

    var totalProjectExpensesData = JSON.parse(
      localStorage.getItem('TotalProjectExpenseFilter')
    );
    if (totalProjectExpensesData) {
      this.TotalProjectExpenseFilterForm.patchValue({
        project: totalProjectExpensesData.project
      });
      var setWidgetData = {
        project: totalProjectExpensesData.project.project_master_id
      };
      this.getTotalProjectExpenses(setWidgetData);
    } else if (this.latestProjects.length > 0) {
      this.TotalProjectExpenseFilterForm.patchValue({
        project: this.latestProjects[0]
      });
      var setWidgetData = {
        project: this.latestProjects[0].project_master_id
      };
      this.getTotalProjectExpenses(setWidgetData);
    } else {
      this.getLatestProjects((isSuccess: boolean) => {
        if (isSuccess && this.latestProjects.length > 0) {
          this.TotalProjectExpenseFilterForm.patchValue({
            project: this.latestProjects[0]
          });
          var setWidgetData = {
            project: this.latestProjects[0].project_master_id
          };
          this.getTotalProjectExpenses(setWidgetData);
        }
      });
    }
  }

  /**
   * Filter
   */
  TotalProjectExpenseFilter() {
    let data = {};

    // Report Generation based on the selection
    data = {
      project: this.TotalProjectExpenseFilterForm.value.project
        .project_master_id
    };

    // Set local storage
    let localStorageData = {
      project: this.TotalProjectExpenseFilterForm.value.project
    };
    localStorage.setItem(
      'TotalProjectExpenseFilter',
      JSON.stringify(localStorageData)
    );

    // Generate the widget
    this.getTotalProjectExpenses(data);
  }

  /**
   * Total Project Expense
   */
  getTotalProjectExpenses(data: any) {
    if (data && data.project) {
      this.homeService.getTotalProjectExpenses(data).subscribe(res => {
        this.totalProjectExpenseList = res.data;
        let chartlabel = [];
        let chartData = [];
        //let chartRGB = [];
        for (let i = 0; i < this.totalProjectExpenseList.length; i++) {
          if (this.totalProjectExpenseList[i].amount != 0) {
            chartlabel.push(
              this.totalProjectExpenseList[i].account_master_name
            );
            chartData.push(this.totalProjectExpenseList[i].amount);
            //chartRGB.push(this.random_rgba());
          }
        }
        chartlabel.splice(-1, 1);
        chartData.splice(-1, 1);
        //chartRGB.splice(-1, 1);
        this.TotalProjectExpensesPieChartLabels = chartlabel;
        this.TotalProjectExpensesPieChartData = chartData;
        // this.TotalProjectExpensesPieChartColors = [
        //   {
        //     backgroundColor: chartRGB
        //   }
        // ];
        this.TotalProjectExpensesPieChartOptions = {
          responsive: true, // Instruct chart js to respond nicely.
          maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
          legend: {
            position: 'right'
          },
          plugins: {
            datalabels: {
              // formatter: (value, ctx) => {
              //   const label = ctx.chart.data.labels[ctx.dataIndex];
              //   return label;
              // }
              display: false
            }
          }
        };
        this.TotalProjectExpensesChartType = 'pie';
        this.TotalProjectExpensesPieChartLegend = true;
        this.TotalProjectExpensesPieChartPlugins = [pluginDataLabels];
      });
    } else {
      this.totalProjectExpenseList = [];
    }
  }

  /*********************project expense vs budget Filter*************************************/

  /**
   * Filter Form Design
   */
  createTotalProjectExpenseVsBudgetFilterForm() {
    this.totalProjectExpenseVsBudgetFilterForm = this.formBuilder.group({
      project: ['']
    });

    var totalProjectExpensesVsBudgetData = JSON.parse(
      localStorage.getItem('TotalProjectExpenseVsBudgetFilter')
    );
    if (totalProjectExpensesVsBudgetData) {
      this.totalProjectExpenseVsBudgetFilterForm.patchValue({
        project: totalProjectExpensesVsBudgetData.project
      });
      var setWidgetData = {
        project: totalProjectExpensesVsBudgetData.project.project_master_id
      };
      this.getTotalProjectExpensesVsBudget(setWidgetData);
    } else if (this.latestProjects.length > 0) {
      this.totalProjectExpenseVsBudgetFilterForm.patchValue({
        project: this.latestProjects[0]
      });
      var setWidgetData = {
        project: this.latestProjects[0].project_master_id
      };
      this.getTotalProjectExpensesVsBudget(setWidgetData);
    } else {
      this.getLatestProjects((isSuccess: boolean) => {
        if (isSuccess && this.latestProjects.length > 0) {
          this.totalProjectExpenseVsBudgetFilterForm.patchValue({
            project: this.latestProjects[0]
          });
          var setWidgetData = {
            project: this.latestProjects[0].project_master_id
          };
          this.getTotalProjectExpensesVsBudget(setWidgetData);
        }
      });
    }
  }

  /**
   * project expense vs budget Filter
   */
  projectExpenseVsBudgetFilter() {
    let data = {};

    // Report Generation based on the selection
    data = {
      project: this.totalProjectExpenseVsBudgetFilterForm.value.project
        .project_master_id
    };

    // Set local storage
    let localStorageData = {
      project: this.totalProjectExpenseVsBudgetFilterForm.value.project
    };
    localStorage.setItem(
      'TotalProjectExpenseVsBudgetFilter',
      JSON.stringify(localStorageData)
    );

    // Generate the widget
    this.getTotalProjectExpensesVsBudget(data);
  }

  /**
   * Total Project Expense
   */
  getTotalProjectExpensesVsBudget(data: any) {
    if (data && data.project) {
      this.homeService.getTotalProjectExpensesVsBudget(data).subscribe(res => {
        if (
          res.data &&
          res.data.data &&
          res.data.label &&
          res.data.data.length > 0
        ) {
          this.totalProjectExpenseVsBudgetFlag = true;
          this.TotalProjectExpensesVsBudgetPieChartLabels = res.data.label;
          this.TotalProjectExpensesVsBudgetPieChartData = res.data.data;
          this.TotalProjectExpensesVsBudgetPieChartOptions = {
            responsive: true, // Instruct chart js to respond nicely.
            maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
            legend: {
              position: 'right'
            },
            plugins: {
              datalabels: {
                display: false
              }
            }
          };
          this.TotalProjectExpensesVsBudgetChartType = 'doughnut';
          this.TotalProjectExpensesVsBudgetPieChartLegend = true;
          this.TotalProjectExpensesVsBudgetPieChartPlugins = [pluginDataLabels];
        } else {
          this.totalProjectExpenseVsBudgetFlag = false;
        }
      });
    } else {
      this.totalProjectExpenseVsBudgetFlag = false;
    }
  }

  onProjectExpenseVsBudgetValChange(val: string) {
    this.selectedProjectExpVsBudgetBtnGroupVal = val;
  }

  getBudgetVsActualPercentage() {
    if (
      this.TotalProjectExpensesVsBudgetPieChartLabels.length > 0 &&
      this.TotalProjectExpensesVsBudgetPieChartData.length > 0 &&
      this.TotalProjectExpensesVsBudgetPieChartData.length ===
        this.TotalProjectExpensesVsBudgetPieChartLabels.length
    ) {
      let actualIndex = _.indexOf(
        this.TotalProjectExpensesVsBudgetPieChartLabels,
        'actual'
      );
      let budgetIndex = _.indexOf(
        this.TotalProjectExpensesVsBudgetPieChartLabels,
        'budget'
      );
      if (actualIndex >= 0 && budgetIndex >= 0) {
        let budgetPer =
          (this.TotalProjectExpensesVsBudgetPieChartData[actualIndex] /
            this.TotalProjectExpensesVsBudgetPieChartData[budgetIndex]) *
          100;
        return budgetPer;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  /*********************project expense vs budget Filter*************************************/

  /************************** FILTER FIELDS ON SEARCH BAR **************************/

  /**
   * defaultDashboardFilterValues
   * This is the default search parameters and from here the dashboard
   * widgets will have the complete list of legal entity, branches and
   * projects.
   */
  defaultDashboardFilterValues(fn: any) {
    // Get small list of latest etities
    this.entityService.getLatestEntities().subscribe(res => {
      if (res.data) {
        this.latestEntities = res.data;
      }
    });

    // Get small list of latest branches
    this.masterService.getLatestBranches().subscribe(res => {
      if (res.data) {
        this.latestBranches = res.data;
      }
    });

    this.masterService.getLatestProjects().subscribe(res => {
      if (res.data) {
        this.latestProjects = res.data;
      }
    });

    // Get the complete list of active entities
    this.entityService.getEntityForDropdown().subscribe(res => {
      if (res.data && res.data.rows) {
        this.entityList = res.data.rows;
        this.completeEntityList = res.data.rows;
      }
    });

    // Get the complete list of active branches
    this.masterService
      .getBranchForDropdown(this.selectedEntityId)
      .subscribe(res => {
        if (res.data && res.data.rows) {
          this.branchList = res.data.rows;
          this.completeBranchList = res.data.rows;
        }
      });

    // Get the complete list of active projects
    this.masterService.getProjectForDropdown().subscribe(res => {
      if (res.data && res.data.rows) {
        this.projectList = res.data.rows;
        this.completeProjectList = res.data.rows;
      }
    });

    fn(true);
  }

  /**
   * Branch List based on entity
   * This is to filter the branches based on the selected legal entities
   */
  getBranchByEntityId(entity: any) {
    this.branchList = [];

    // Loop thru the entity
    for (var a of entity) {
      // Fetch the complete list so the in every iteration you have the complete list to filter
      var list = this.completeBranchList;

      // If the array lenth is 0 assign the filtered list to the array. else concat to existing array
      if (this.branchList.length == 0) {
        this.branchList = list.filter(
          branch => branch.legal_entity_master_id === a.legal_entity_master_id
        );
      } else {
        this.branchList = this.branchList.concat(
          list.filter(
            branch => branch.legal_entity_master_id === a.legal_entity_master_id
          )
        );
      }
    }
    return this.branchList;
  }

  /**
   * Project List based on branch
   * This is to filter the projects based on the selected branches
   */
  getProjectByBranchId(branch: any) {
    this.projectList = [];

    // Loop thru the branch
    for (var a of branch) {
      // Fetch the complete list so the in every iteration you have the complete list to filter
      var list = this.completeProjectList;

      // If the array lenth is 0 assign the filtered list to the array. else concat to existing array
      if (this.projectList.length == 0) {
        this.projectList = list.filter(
          project => project.branch_master_id === a.branch_master_id
        );
      } else {
        this.projectList = this.projectList.concat(
          list.filter(
            project => project.branch_master_id === a.branch_master_id
          )
        );
      }
    }
    return this.projectList;
  }

  /**
   * Filter Change
   * On change of filter selection, update the realtive list
   */
  setParametesForWidgetFilter(
    thisEntity: any,
    thisBranch: any,
    thisProject: any
  ) {
    // Initialise the paramenter to be sent for reports DB query
    var entity = thisEntity;
    var branch = thisBranch;
    var project = thisProject;

    // Updated the entity, branch and project based on selection
    if (
      thisEntity &&
      thisEntity.length > 0 &&
      thisBranch &&
      thisBranch.length > 0
    ) {
      // If both entity & branch are selected then only the branches based on the selected entity
      // should be listed in the dropdown. And only the projects based on the selected branch should be listed
      branch = this.getBranchByEntityId(thisEntity);
      project = this.getProjectByBranchId(thisBranch);
    } else if (thisEntity && thisEntity.length > 0) {
      // If only entity is selected both the branch and project list should be based on the
      // selected entity
      branch = this.getBranchByEntityId(thisEntity);
      project = this.getProjectByBranchId(branch);
    } else if (thisBranch && thisBranch.length > 0) {
      // If only branch is selected the project list should be based on the selected branch
      project = this.getProjectByBranchId(thisBranch);
    } else {
      // If nothing is selected, the default will be the complete list of all
      this.entityList = [];
      this.branchList = [];
      this.projectList = [];
    }

    return { entity: entity, branch: branch, project: project };
  }

  random_rgba() {
    var o = Math.round,
      r = Math.random,
      s = 255;
    return (
      'rgba(' +
      o(r() * s) +
      ',' +
      o(r() * s) +
      ',' +
      o(r() * s) +
      ',' +
      r().toFixed(1) +
      ')'
    );
  }

  getAllVendorStatusCount() {
    if (
      !(
        this.credentialsService.user_level_5 ||
        this.credentialsService.user_level_6 ||
        this.credentialsService.user_level_7
      )
    ) {
      this.homeService.getAllVendorStatusCount({}).subscribe(res => {
        if (res.status == 200) {
          this.vendorStatusCount = res.data;
        } else {
          this.vendorStatusCount = {};
        }
      });
    }
  }

  getAllPurchaseOrderStatusCount() {
    if (
      !(
        this.credentialsService.user_level_5 ||
        this.credentialsService.user_level_6 ||
        this.credentialsService.user_level_7
      )
    ) {
      this.homeService.getAllPurchaseOrderStatusCount({}).subscribe(res => {
        if (res.status == 200) {
          this.purchaseOrderStatusCount = res.data;
        } else {
          this.purchaseOrderStatusCount = {};
        }
      });
    }
  }

  getAllBillStatusCount() {
    if (
      !(
        this.credentialsService.user_level_5 ||
        this.credentialsService.user_level_6 ||
        this.credentialsService.user_level_7
      )
    ) {
      this.homeService.getAllBillStatusCount({}).subscribe(res => {
        if (res.status == 200) {
          this.billStatusCount = res.data;
        } else {
          this.billStatusCount = {};
        }
      });
    }
  }
  /************************************ OLD UNUSED WIDGETS ************************************/

  // /**
  //  * Expenses widget
  //  * This is to show the list of top five expenses
  //  */
  // getWidgetExpenses(data: any) {
  //   this.homeService.getWidgetExpenses(data).subscribe(res => {
  //     this.topExpenseList = res.data;
  //   });
  // }

  // /**
  //  * Top Payable Vendors
  //  * This is to show the list of top five payable vendors
  //  */
  // getWidgetTopPayableVendors(data: any) {
  //   this.homeService.getWidgetTopPayableVendors(data).subscribe(res => {
  //     this.topVendorsPayableList = res.data;
  //   });
  // }

  // /**
  //  * Top Advances Pending
  //  * This is to show the list of top five pending advances
  //  */
  // getWidgetTopAdvancesPending(data: any) {
  //   this.homeService.getWidgetTopAdvancesPending(data).subscribe(res => {
  //     this.topAdvancesPendingList = res.data;
  //   });
  // }
}
