import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

// Internal component imports
import { ServiceMaster } from '../../../model/serviceMaster.model';
import { configService } from '@app/config/config.service';

// Define component
@Component({
  selector: 'app-select-service',
  templateUrl: './select-service.component.html',
  styleUrls: ['./select-service.component.scss']
})

// Declare class
export class SelectServiceComponent implements OnInit {
  @Input() serviceCtrl = new FormControl();
  @Input() isEditable = true;
  @Output() selectedService = new EventEmitter();
  @Input() tabindex: number;
  serviceList: ServiceMaster[];
  filteredServiceList: Observable<ServiceMaster[]>;
  limit: number = 1000;
  skip: number = 0;

  /**
   * Constructor
   */
  constructor(private configService: configService) {}

  /**
   * Init method
   */
  ngOnInit() {
    // Call the method getServiceList and get the result in a callback
    this.getServiceList(serviceList => {
      this.filteredServiceList = serviceList;
    });
  }

  /**
   * Method to get the complete purchase type list
   */
  getServiceList(fn: (serviceList: Observable<ServiceMaster[]>) => void) {
    this.configService.getService(this.limit, this.skip).subscribe(res => {
      if (res.data && res.data.rows) {
        this.serviceList = res.data.rows;
      } else {
        this.serviceList = [];
      }
      fn(
        (this.filteredServiceList = this.serviceCtrl.valueChanges.pipe(
          startWith(this.serviceCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(service =>
            service && service.length > 1
              ? this.filterServices(service)
              : this.serviceList.slice()
          )
        ))
      );
    });
  }

  /*
   * Method to display only the name of the selected purchase type
   */
  public displaySelectedService(service: ServiceMaster) {
    return service ? service.service_master_name : service;
  }

  /**
   * Method to filter the purchase type based on input
   */
  private filterServices(service_master_name: string) {
    return this.serviceList.filter(
      service =>
        service.service_master_name
          .toLowerCase()
          .indexOf(service_master_name.toLowerCase()) >= 0
    );
  }

  selectServiceClickHandler(service: ServiceMaster) {
    this.selectedService.emit(service);
  }
}
