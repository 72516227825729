import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

// Internal component imports
import { PurchaseService } from '../../../purchase/purchase.service';
import { ItcType } from '../../../model/itcType.model';

// Define component
@Component({
  selector: 'app-select-itc-type',
  templateUrl: './select-itc-type.component.html',
  styleUrls: ['./select-itc-type.component.scss']
})

// Declare class
export class SelectItcTypeComponent implements OnInit {
  @Input() itcTypeCtrl = new FormControl();
  @Input() isEditable = true;
  @Output() itcTypeSelected = new EventEmitter();
  @Input() isLabelRequired: boolean = true;
  itcTypeList: ItcType[];
  filteredItcTypeList: Observable<ItcType[]>;

  /**
   * Constructor
   */
  constructor(private purchaseService: PurchaseService) {}

  /**
   * Init method
   */
  ngOnInit() {
    // Call the method getItcTypeList and get the result in a callback
    this.getItcTypeList(itcTypeList => {
      this.filteredItcTypeList = itcTypeList;
    });
  }

  /**
   * Method to get the complete ITC type list
   */
  getItcTypeList(fn: (itcTypeList: Observable<ItcType[]>) => void) {
    this.purchaseService.getItcType().subscribe(res => {
      if (res.data && res.data.rows) {
        this.itcTypeList = res.data.rows;
      } else {
        this.itcTypeList = [];
      }
      if (this.itcTypeCtrl) {
        fn(
          (this.filteredItcTypeList = this.itcTypeCtrl.valueChanges.pipe(
            startWith(this.itcTypeCtrl.value),
            map(value => (typeof value === 'string' ? value : value)),
            map(itcType =>
              itcType && itcType.length > 1
                ? this.filterItcTypes(itcType)
                : this.itcTypeList.slice()
            )
          ))
        );
      }
    });
  }

  /*
   * Method to display only the name of the selected ITC type
   */
  public displaySelectedItcType(itcType: any) {
    return itcType ? itcType.itc_type_name : itcType;
  }

  /**
   * Method to filter the ITC type based on input
   */
  private filterItcTypes(itc_type_name: string) {
    return this.itcTypeList.filter(
      itcType =>
        itcType.itc_type_name
          .toLowerCase()
          .indexOf(itc_type_name.toLowerCase()) >= 0
    );
  }

  public selectItcTypeClickHandler(itcType: ItcType) {
    this.itcTypeSelected.emit(itcType);
  }
}
