import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { SalesService } from '../../../sales/sales.service';
import { ECommerce } from '../../../model/eCommerce.model';

@Component({
  selector: 'app-select-ecommerce',
  templateUrl: './select-ecommerce.component.html',
  styleUrls: ['./select-ecommerce.component.scss']
})
export class SelectEcommerceComponent implements OnInit {
  @Input() ecommerceCtrl = new FormControl();
  ecommerceList: ECommerce[];
  filteredECommerceList: Observable<ECommerce[]>;

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    // Call the method getStateList and get the result in a callback
    this.getEcommerceList(ecommerceList => {
      this.filteredECommerceList = ecommerceList;
    });
  }

  /**
   *
   *
   * @param {(ecommerceList: Observable<ECommerce[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete ecommerce list
   */
  getEcommerceList(fn: (ecommerceList: Observable<ECommerce[]>) => void) {
    this.salesService.getECommerce().subscribe(res => {
      this.ecommerceList = res;
      fn(
        (this.filteredECommerceList = this.ecommerceCtrl.valueChanges.pipe(
          startWith(this.ecommerceCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(ecommerce =>
            ecommerce && ecommerce.length > 0
              ? this.filterEcommerce(ecommerce)
              : this.ecommerceList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} ecommerce
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected ECommerce
   */
  public displaySelectedEcommerce(ecommerce: ECommerce) {
    return ecommerce ? ecommerce.e_commerce_name : ecommerce;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the ecommerce based on input
   *
   */
  private filterEcommerce(name: string) {
    return this.ecommerceList.filter(
      ecommerce =>
        ecommerce.e_commerce_name.toLowerCase().indexOf(name.toLowerCase()) >= 0
    );
  }
}
