import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'about',
      loadChildren: 'app/about/about.module#AboutModule'
    },
    {
      path: 'reports',
      loadChildren: 'app/reports/reports.module#ReportsModule'
    },
    {
      path: 'sales',
      loadChildren: 'app/sales/sales.module#SalesModule'
    },
    {
      path: 'purchase',
      loadChildren: 'app/purchase/purchase.module#PurchaseModule'
    },
    {
      path: 'productions',
      loadChildren: 'app/productions/productions.module#ProductionsModule'
    },
    {
      path: 'accounting',
      loadChildren: 'app/accounting/accounting.module#AccountingModule'
    },
    {
      path: 'inventory',
      loadChildren: 'app/inventory/inventory.module#InventoryModule'
    },
    {
      path: 'config',
      loadChildren: 'app/config/config.module#ConfigModule'
    },
    {
      path: 'entity',
      loadChildren: 'app/entity/entity.module#EntityModule'
    },
    {
      path: 'settings',
      loadChildren: 'app/setting/setting.module#SettingModule'
    },
    {
      path: 'setup',
      loadChildren: 'app/setup/setup.module#SetupModule'
    },
    {
      path: 'user',
      loadChildren: 'app/user/user.module#UserModule'
    }
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules // <-This is our preloading
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
