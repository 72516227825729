import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import {
  MatTableDataSource,
  MatDialog,
  PageEvent,
  MatTabGroup
} from '@angular/material';
import { Sort } from '@angular/material/sort';
import { environment } from '@env/environment';

// Internal component imports
import { MasterService } from '../../master.service';
import { DetailViewBranchComponent } from '../detail-view-branch/detail-view-branch.component';
import { BranchMaster } from '../../../model/branchMaster.model';
import { EditBranchComponent } from '../edit-branch/edit-branch.component';

// Define component
@Component({
  selector: 'app-view-branch',
  templateUrl: './view-branch.component.html',
  styleUrls: ['./view-branch.component.scss'],
  encapsulation: ViewEncapsulation.None
})

// Declare class
export class ViewBranchComponent implements OnInit {
  // Initialisation
  dataSource: MatTableDataSource<BranchMaster>;
  limit: number;
  skip: number;
  sortby: string;
  sortas: string;
  totalCount: number;
  branchList: Array<BranchMaster>;
  emptyList: boolean = false;
  pageSizeOptions: Array<any>;
  displayedColumnsForApproved: string[];
  @ViewChild('matTabGroup') branchTab: MatTabGroup;

  // Constructor
  constructor(private dialog: MatDialog, private masterService: MasterService) {
    this.limit = environment.pageSizeOptions[0];
    this.skip = 0;
    this.sortby = 'id';
    this.sortas = environment.listDefaultSort;
    this.emptyList = false;
    this.totalCount = 0;
    this.pageSizeOptions = environment.pageSizeOptions;
    this.displayedColumnsForApproved = [
      'position',
      'branchName',
      'legalEntity',
      'projects',
      'primaryContactName',
      'primaryContactNo',
      'gstNo',
      'country',
      'state',
      'status',
      'action'
    ];
  }

  /**
   * Init method
   */
  ngOnInit() {
    this.getBranch();
  }

  /**
   * Open pop up dialog box to view the details of the branch
   */
  openViewDialog(element: any) {
    const dialogRef = this.dialog.open(DetailViewBranchComponent, {
      width: '80%'
    });
    dialogRef.componentInstance.branchData = element;
    dialogRef.afterClosed().subscribe(result => {
      this.getBranch();
    });
  }

  /**
   * Open pop up dialog box to edit branch
   */
  openEditDialog(element: any) {
    const editDialogRef = this.dialog.open(EditBranchComponent, {
      // height: '80%',
      width: '80%'
    });
    (editDialogRef.componentInstance.editDialogRef = editDialogRef),
      (editDialogRef.componentInstance.editFormData = element),
      (editDialogRef.componentInstance.viewBranchComponent = this);
  }

  /**
   * Get branch list
   */
  getBranch() {
    this.masterService
      .getBranch(this.limit, this.skip, this.sortby, this.sortas)
      .subscribe((res: any) => {
        if (res.data && res.data.rows) {
          this.branchList = res.data.rows;
          this.totalCount = res.data.count;
        }
        this.dataSource = new MatTableDataSource(this.branchList);
      });
  }

  /**
   * Pagination handler
   */

  getNextPaginationHandler(event: PageEvent) {
    this.skip = event.pageSize * event.pageIndex;
    this.limit = event.pageSize;
    this.getBranch();
  }

  /**
   * Sort handler
   */
  sortData(sort: Sort) {
    this.sortby = sort.active;
    this.sortas = sort.direction;
    this.limit = this.limit;
    this.skip = this.skip;
    this.getBranch();
  }

  goToAddBranchPage() {
    this.branchTab.selectedIndex = 1;
  }
}
