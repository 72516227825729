import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringTruncate'
})
export class StringTruncatePipe implements PipeTransform {
  transform(value: string, limit = 10, trail = '...'): string {
    return value && value.length > limit
      ? value.substring(0, limit) + trail
      : value;
  }
}
