import { Component, OnInit } from '@angular/core';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError
} from '@angular/router';

@Component({
  selector: 'app-vendor-sub-header',
  templateUrl: './vendor-sub-header.component.html',
  styleUrls: ['./vendor-sub-header.component.scss']
})
export class VendorSubHeaderComponent implements OnInit {
  selectedTab: String;
  currentUrl: String;
  constructor(private router: Router) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    console.log(this.currentUrl);
    if (this.currentUrl.includes('purchase')) {
      this.selectedTab = '/purchase/bill';
    } else {
      this.selectedTab = '/setup';
    }
  }
}
