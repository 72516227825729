// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import env from './.env';

export const environment = {
  production: false,
  version: env.npm_package_version + '-dev',
  //baseApiUrl: 'http://', // For connecting to server running elsewhere update the base API URL
  //apiProvider: '/api',
  companyName: 'AADNAH ARTS',
  apiVersion: '/1.0.0',
  serverUrl: '/api',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  pageSizeOptions: [10, 15, 20, 50, 100],
  reportsPageSizeOptions: [50, 100, 200],
  listDefaultSort: 'asc',
  listExpenseSort: 'desc',
  fixedLimit: 10000,
  maxInputLimit: 100000,
  dateFormat: 'dd-MMM-yyyy',
  title: 'AADNAH ARTS - ',
  level_7: 64, //sys-owner
  level_6: 32, //sys-cxo
  level_5: 16, //sys-super
  level_4: 8, //sys-admin
  level_3: 4, //sys-approver
  level_2: 2, //sys-acc
  level_1: 1 //sys-user
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
