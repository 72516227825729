import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { SalesService } from '../../../sales/sales.service';
import { Country } from '../../../model/country.model';

@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss']
})
export class SelectCountryComponent implements OnInit {
  @Input() countryCtrl = new FormControl();
  @Output() selectedCountry = new EventEmitter();
  @Input() isEditable = true;
  @Input() tabindex: number;
  countryList: Country[];
  filteredCountryList: Observable<Country[]>;

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    // Call the method getCountryList and get the result in a callback
    this.getCountryList(countryList => {
      this.filteredCountryList = countryList;
    });
  }

  /**
   *
   *
   * @param {(countryList: Observable<Country[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete country list
   */
  getCountryList(fn: (countryList: Observable<Country[]>) => void) {
    this.salesService.getCountry().subscribe(res => {
      if (res.data && res.data.rows) {
        this.countryList = res.data.rows;
      } else {
        this.countryList = [];
      }
      fn(
        (this.filteredCountryList = this.countryCtrl.valueChanges.pipe(
          startWith(this.countryCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(country =>
            country && country.length > 0
              ? this.filterCountry(country)
              : this.countryList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} country
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the country_name of the selected country
   */
  public displaySelectedCountry(country: Country) {
    return country ? country.country_name : country;
  }

  /**
   *
   *
   * @private
   * @param {string} country_name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the country based on input
   *
   */
  private filterCountry(country_name: string) {
    return this.countryList.filter(
      country =>
        country.country_name
          .toLowerCase()
          .indexOf(country_name.toLowerCase()) >= 0
    );
  }

  public selectCountryClickHandler(country: Country) {
    this.selectedCountry.emit(country);
  }
}
