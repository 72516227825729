import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Logger } from '../logger.service';
import { CredentialsService } from './credentials.service';
import * as _ from 'lodash';
const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private credentialsService: CredentialsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.credentialsService.isAuthenticated()) {
      if (state.url === '/user' || state.url === '/user/user') {
        if (
          this.credentialsService.user_level_4 ||
          this.credentialsService.user_level_5 ||
          this.credentialsService.user_level_6 ||
          this.credentialsService.user_level_7
        ) {
          //console.log('IFFFFFFFFFFFFFFF     ifff       user');
          return true;
        } else {
          //console.log('IFFFFFFFFFFFFFFF     esle       user');
          return false;
        }
      } else if (state.url === '/reports' || state.url.includes('reports')) {
        // if (
        //   this.credentialsService.user_level_5 ||
        //   this.credentialsService.user_level_6 ||
        //   this.credentialsService.user_level_7
        // ) {
        // console.log('IFFFFFFFFFFFFFFF     ifff       user');
        return true;
        // } else {
        //   //console.log('IFFFFFFFFFFFFFFF     esle       user');
        //   return false;
        // }
      } else if (
        this.credentialsService.user_level_3 ||
        this.credentialsService.user_level_5 ||
        this.credentialsService.user_level_4 ||
        this.credentialsService.user_level_2 ||
        this.credentialsService.user_level_1 ||
        this.credentialsService.user_level_6 ||
        this.credentialsService.user_level_7
      ) {
        //console.log('IFFFFFFFFFFFFFFF           acccountant');
        return true;
      } else {
        //console.log('elseeeeeeeeeeeeee           user');
        log.debug('Not authenticated, redirecting and adding redirect url...');
        this.router.navigate(['/login'], {
          queryParams: { redirect: state.url },
          replaceUrl: true
        });
        return false;
      }
      // console.log(route);
      // console.log(state);
      // console.log(state.url);
      //console.log(route.component['name'])
      // console.log(state.root);
      // console.log(state.url.substring(1));
      // console.log(route.children);
      //   console.log('^^^^^^^^^^^^^^^^^^^^^^^^444^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
      //   console.log(_.map(modulePermissionList, 'module_name'));
      //   let title = route.children.length > 0 ? route.children[0].data.title : route.data.title;
      //   console.log(title);
      //   let modulePermission: ModulePermission =  _.find(modulePermissionList, {module_name: title}) as ModulePermission;
      //   console.log(modulePermission);
      //   //let index = modulePermission.permission.indexOf("Tab");
      //  // console.log(index);
      //   if(modulePermission && modulePermission.permission.indexOf("Tab") >= 0 ) {
      //     console.log('^^^^^^^^^^^^t22222222rtretre^^^^^^');
      // } else {
      //   console.log('^^^^^^^^^^^^trtretre^^^^^^');
      //   return false;
      // }
    } else {
      log.debug('Not authenticated, redirecting and adding redirect url...');
      this.router.navigate(['/login'], {
        queryParams: { redirect: state.url },
        replaceUrl: true
      });
      return false;
    }
  }
}

interface ModulePermission {
  id: number;
  module_name: string;
  permission: string[];
  isActive: boolean;
}

const modulePermissionList = [
  {
    id: 1,
    module_name: 'Entity',
    permission: ['Tab', 'Add', 'Edit', 'FetchAll'],
    isActive: true
  },
  {
    id: 2,
    module_name: 'Branch',
    permission: ['Tab', 'Approve', 'FetchAll'],
    isActive: true
  },
  {
    id: 3,
    module_name: 'Project',
    permission: ['Add', 'Edit', 'FetchAll'],
    isActive: true
  },
  {
    id: 4,
    module_name: 'Home',
    permission: ['Tab', 'Add', 'Edit', 'FetchAll'],
    isActive: true
  }
];
