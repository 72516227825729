import {
  Component,
  OnDestroy,
  ContentChildren,
  forwardRef,
  QueryList,
  Input
} from '@angular/core';
import { MatFileUpload } from 'angular-material-fileupload';
import { Subscription, merge } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { startWith } from 'rxjs/operators';
import { MatFileUploadComponent } from '../mat-file-upload/mat-file-upload.component';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-mat-file-upload-queue',
  templateUrl: './mat-file-upload-queue.component.html',
  styleUrls: ['./mat-file-upload-queue.component.scss'],
  exportAs: 'matFileUploadQueue'
})
export class MatFileUploadQueueComponent implements OnDestroy {
  @ContentChildren(forwardRef(() => MatFileUploadComponent))
  fileUploads: QueryList<MatFileUpload>;

  /** Subscription to remove changes in files. */
  private _fileRemoveSubscription: Subscription | null;

  /** Subscription to changes in the files. */
  private _changeSubscription: Subscription;

  /** Combined stream of all of the file upload remove change events. */
  get fileUploadRemoveEvents() {
    return merge(...this.fileUploads.map(fileUpload => fileUpload.removeEvent));
  }

  public files: Array<any> = [];

  /**
   * Http request input bindings
   */
  @Input()
  httpUrl: string;

  @Input()
  uploadFormData: any;

  @Input()
  httpRequestHeaders:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      } = new HttpHeaders().set('Content-Type', 'multipart/form-data');

  @Input()
  httpRequestParams:
    | HttpParams
    | {
        [param: string]: string | string[];
      } = new HttpParams();

  @Input()
  fileAlias: string = 'file';

  constructor(private snackbar: MatSnackBar) {}

  ngAfterViewInit() {
    this._changeSubscription = this.fileUploads.changes
      .pipe(startWith(null))
      .subscribe(() => {
        if (this._fileRemoveSubscription) {
          this._fileRemoveSubscription.unsubscribe();
        }
        this._listenTofileRemoved();
      });
  }

  private _listenTofileRemoved(): void {
    this._fileRemoveSubscription = this.fileUploadRemoveEvents.subscribe(
      (event: MatFileUpload) => {
        this.files.splice(event.id, 1);
      }
    );
  }

  add(file: any): void {
    this.files.push(file);
  }

  uploadAll(): void {
    if (this.fileUploads && this.fileUploads.length > 0) {
      this.fileUploads.forEach(fileUpload => {
        fileUpload.upload();
      });
    } else {
      this.snackbar.open('Please select file to upload.', 'OK', {
        duration: 2000
      });
    }
  }

  removeAll(): void {
    this.files.splice(0, this.files.length);
  }

  ngOnDestroy(): void {
    if (this.files) {
      this.removeAll();
    }
  }
}
