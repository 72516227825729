import {
  Component,
  OnInit,
  Input,
  Output,
  ViewEncapsulation,
  EventEmitter
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { SalesService } from '../../../sales/sales.service';
import { CessType } from '../../../model/cessType.model';

@Component({
  selector: 'app-select-cess-type',
  templateUrl: './select-cess-type.component.html',
  styleUrls: ['./select-cess-type.component.scss']
})
export class SelectCessTypeComponent implements OnInit {
  @Input() cessTypeCtrl = new FormControl();
  @Input() isEditable = true;
  @Output() cessTypeSelected = new EventEmitter();
  @Input() isLabelRequired: boolean = true;
  cessTypeList: CessType[];
  filteredCessTypeList: Observable<CessType[]>;

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    // Call the method getCessTypeList and get the result in a callback
    this.getCessTypeList(cessTypeList => {
      this.filteredCessTypeList = cessTypeList;
    });
  }

  /**
   *
   *
   * @param {(cessTypeList: Observable<CessType[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete cessType list
   */
  getCessTypeList(fn: (cessTypeList: Observable<CessType[]>) => void) {
    this.salesService.getCessType().subscribe(res => {
      if (res.data && res.data.rows) {
        this.cessTypeList = res.data.rows;
      } else {
        this.cessTypeList = [];
      }
      if (this.cessTypeCtrl) {
        fn(
          (this.filteredCessTypeList = this.cessTypeCtrl.valueChanges.pipe(
            startWith(this.cessTypeCtrl.value),
            map(value => (typeof value === 'string' ? value : value)),
            map(cessType =>
              cessType && cessType.length > 0
                ? this.filterCessType(cessType)
                : this.cessTypeList.slice()
            )
          ))
        );
      }
    });
  }

  /**
   *
   *
   * @param {*} taxType
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected cessType
   */
  public displaySelectedCessType(cessType: CessType) {
    return cessType ? cessType.cess_type_name : cessType;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the cessType based on input
   *
   */
  private filterCessType(name: string) {
    return this.cessTypeList.filter(
      cessType =>
        cessType.cess_type_name.toLowerCase().indexOf(name.toLowerCase()) >= 0
    );
  }

  public selectCessTypeClickHandler(cessType: CessType) {
    this.cessTypeSelected.emit(cessType);
  }
}
