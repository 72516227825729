import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
/** rxjs Imports */
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Credentials, CredentialsService } from './credentials.service';
/** Environment Configuration */
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

export interface LoginContext {
  username: string;
  password: string;
  id: number;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {
  constructor(
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<any> {
    // Replace by proper authentication call
    const data = {
      username: context.username,
      token: '',
      id: '',
      remember: context.remember
    };
    let loginData = {
      email: context.username,
      password: context.password
    };
    let httpParams = new HttpParams();
    return this.http
      .post('/auth/login', loginData, { params: httpParams })
      .pipe(
        map((userData: any) => {
          if (
            userData.hasOwnProperty('data') &&
            userData.data.hasOwnProperty('auth_token')
          ) {
            data.token = userData.data.auth_token;
            data.id = userData.data.user_master_id;
          }
          this.credentialsService.setCredentials(data, context.remember);
          this.credentialsService.setUserDetails(userData.data);
          return of(data);
        })
      );
    // this.credentialsService.setCredentials(data, context.remember);
    // return of(data);
  }

  /**
   * Authenticates the vendor user.
   */
  vendorLogin(context: LoginContext): Observable<any> {
    // Replace by proper authentication call
    const data = {
      username: context.username,
      token: '',
      id: '',
      remember: context.remember
    };
    let loginData = {
      email: context.username,
      password: context.password
    };
    let httpParams = new HttpParams();
    return this.http
      .post('/auth/login', loginData, { params: httpParams })
      .pipe(
        map((userData: any) => {
          if (
            userData.hasOwnProperty('data') &&
            userData.data.hasOwnProperty('auth_token')
          ) {
            data.token = userData.data.auth_token;
            data.id = userData.data.user_master_id;
          }
          this.credentialsService.setCredentials(data, context.remember);
          return of(data);
        })
      );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    return of(true);
  }

  /**
   *
   * TAB AUTHENTICATION
   *
   */

  // isAuthenticatePurchaseTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateAccountTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateInventoryTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateReportsTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  /**
   *
   * SUB TAB AUTHENTICATION
   *
   */

  // isAuthenticateVendorTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticatePOTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateBillTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateGRNTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateTDSTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticatePaymentTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateDebitNoteTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateAccountMasterTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateGroupMasterTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateDisplayItemMasterTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateGeneralLedgerTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateBudgetTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateMaterialTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateMaterialTransactionTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5 || this.credentialsService.user_level_2)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateAccountRelatedReportsTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateExpanseRelatedReportsTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  /**
   *
   * SIDE BAR TAB AUTHENTICATION
   *
   */

  // isAuthenticateHomeTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateSettingTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateEntityTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateBranchTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateProjectTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateUserTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateProfileTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateConfigTab() {
  //   if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAuthenticateTab() {

  //   console.log("############################################################################")
  //   console.log(this.router.url);
  //   console.log(this.router);
  //   console.log("############################################################################")

  //   //  let dd =  _.map(modulePermissionList, )
  //   //  console.log(dd);
  //   //  let title = this.route.component;
  //   //  let title1 = this.route.firstChild;
  //   //  let title2 = this.route.pathFromRoot;

  //   //  console.log(this.route.snapshot.component.toString());
  //   //  console.log(title.toString());
  //   //  console.log(title1);
  //   //  console.log(title2);

  //   //  console.log(this.route.component);

  //   //   console.log('^^^^^^^^^^^^^^^^^^^^^4444^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');

  //   //let title = this.router..length > 0 ? this.router.children[0].data.title : this.router.data.title
  //   // console.log(route);
  //   // console.log(state);
  //   // console.log(state.url);
  //   // console.log(route.component['name'])
  //   // console.log(state.root);
  //   // console.log(state.url.substring(1));
  //   //   console.log(route.children);
  //   //   console.log('^^^^^^^^^^^^^^^^^^^^^^^^444^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
  //   //   console.log(_.map(modulePermissionList, 'module_name'));
  //   //   let title = route.children.length > 0 ? route.children[0].data.title : route.data.title;
  //   //   console.log(title);
  //   //   let modulePermission: ModulePermission =  _.find(modulePermissionList, {module_name: title}) as ModulePermission;
  //   //   console.log(modulePermission);
  //   //   //let index = modulePermission.permission.indexOf("Tab");
  //   //  // console.log(index);
  //   //   if(modulePermission && modulePermission.permission.indexOf("Tab") >= 0 ) {
  //   //     console.log('^^^^^^^^^^^^t22222222rtretre^^^^^^');
  //   return true;
  //   // } else {
  //   //   console.log('^^^^^^^^^^^^trtretre^^^^^^');
  //   //   return false;
  //   // }
  // }

  // isAuthenticateHeader() {
  //   // let dd =  _.map(modulePermissionList, (d) => {
  //   //   if(d.permission.indexOf('Tab') >= 0) {
  //   //     return d;
  //   //   }
  //   // })
  //   // console.log(dd);
  //   console.log(
  //     '^^^^^^^^^^^^^^^^^^^^^4444^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^'
  //   );
  //   console.log("############################################################################")
  //   console.log(this.router.url);
  //   console.log(this.router);
  //   console.log("############################################################################")
  //   //return true;
  //   if (this.credentialsService.isAuthenticated()) {
  //     console.log('^^^^^^^^^^^^^^^^^^^^^4444^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
  //     console.log(this.router.url);
  //     console.log(this.router.url === '/user')
  //     console.log(this.router.url === '/user/user');

  //     console.log(this.credentialsService.user_level_5)
  //     console.log(this.credentialsService.user_level_4)
  //     console.log(this.credentialsService.user_level_2)
  //     //if((this.router.url === '/user' || this.router.url === '/user/user')) {
  //     if((this.credentialsService.user_level_4 || this.credentialsService.user_level_5)){
  //         console.log("IFFFFFFFFFFFFFFF     ifff       user")
  //         return true;
  //       // } else {
  //       //   console.log("IFFFFFFFFFFFFFFF     esle       user")
  //       //   return false;
  //       // }
  //     } else if(this.credentialsService.user_level_2){
  //      console.log("IFFFFFFFFFFFFFFF           acccountant")
  //       return true;
  //     } else {
  //      console.log("elseeeeeeeeeeeeee           user")
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }

  //   // console.log(route);
  //   // console.log(state);
  //   // console.log(state.url);
  //   // console.log(route.component['name'])
  //   // console.log(state.root);
  //   // console.log(state.url.substring(1));
  //   //   console.log(route.children);
  //   //   console.log('^^^^^^^^^^^^^^^^^^^^^^^^444^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
  //   //   console.log(_.map(modulePermissionList, 'module_name'));
  //   //   let title = route.children.length > 0 ? route.children[0].data.title : route.data.title;
  //   //   console.log(title);
  //   //   let modulePermission: ModulePermission =  _.find(modulePermissionList, {module_name: title}) as ModulePermission;
  //   //   console.log(modulePermission);
  //   //   //let index = modulePermission.permission.indexOf("Tab");
  //   //  // console.log(index);
  //   //   if(modulePermission && modulePermission.permission.indexOf("Tab") >= 0 ) {
  //   //     console.log('^^^^^^^^^^^^t22222222rtretre^^^^^^');
  //   // return true;
  //   // } else {
  //   //   console.log('^^^^^^^^^^^^trtretre^^^^^^');
  //   //   return false;
  //   // }
  // }
}

interface ModulePermission {
  id: number;
  module_name: string;
  permission: string[];
  isActive: boolean;
}

const modulePermissionList = [
  {
    id: 1,
    module_name: 'Entity',
    permission: ['Tab', 'Add', 'Edit', 'FetchAll'],
    isActive: true
  },
  {
    id: 2,
    module_name: 'Branch',
    permission: ['Tab', 'Approve', 'FetchAll'],
    isActive: true
  },
  {
    id: 3,
    module_name: 'Project',
    permission: ['Add', 'Edit', 'FetchAll'],
    isActive: true
  },
  {
    id: 4,
    module_name: 'Home',
    permission: ['Tab', 'Add', 'Edit', 'FetchAll'],
    isActive: true
  }
];
