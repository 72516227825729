import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MasterService } from '@app/master/master.service';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { ViewProjectComponent } from '../view-project/view-project.component';
import { environment } from '@env/environment';
import { StaffMaster } from '@app/model/staffMaster.model';
import { ConfirmationDialogComponent } from '@app/shared/confirmation-dialog/confirmation-dialog.component';
import { SharedServiceService } from '@app/shared/shared-service.service';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit {
  public editProjectForm: FormGroup;
  public staffList: StaffMaster[] = [];
  public editFormData: any;
  public viewProjectComponent: ViewProjectComponent;

  constructor(
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public editDialogRef: MatDialogRef<EditProjectComponent>,
    public sharedService: SharedServiceService
  ) {}

  ngOnInit() {
    this.createProjectForm();
    this.getStaffList();
    this.showProjectForm();
  }

  /***Create project form fields ****/
  createProjectForm() {
    this.editProjectForm = this.formBuilder.group({
      name: ['', Validators.required],
      //branch: ['', [Validators.required, this.sharedService.objectValidator]],
      legalEntity: [
        '',
        [Validators.required, this.sharedService.objectValidator]
      ],
      party: ['', [this.sharedService.objectValidator]],
      staff: [null, this.sharedService.objectValidator],
      remarks: ['', Validators.required]
    });
  }

  /**
   * Method to get the complete staff list
   */
  getStaffList() {
    let data = {
      limit: environment.pageSizeOptions[0],
      skip: 0
    };
    this.masterService.getStaff(data).subscribe(res => {
      if (res.data && res.data.rows) {
        this.staffList = res.data.rows;
      } else {
        this.staffList = [];
      }
    });
  }

  /***Show project details ****/
  showProjectForm() {
    this.editProjectForm.patchValue({
      name: this.editFormData.project_master_name,
      branch: this.editFormData.branch_master,
      staff: this.editFormData.staff_master,
      party: this.editFormData.vendor_master,
      legalEntity: this.editFormData.legal_entity_master,
      remarks: this.editFormData.remarks
    });
  }

  compareStaffWithList(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.staff_master_id === c2.staff_master_id : c1 === c2;
  }

  /***Update project details ****/
  updateProject() {
    let credentials = JSON.parse(localStorage.getItem('credentials'));
    if (this.editProjectForm.valid) {
      const confirmationDialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        {
          width: '400px'
          // data : {name: "TEST", message: "Are you sure to submit add vendor ?"}
        }
      );
      confirmationDialogRef.disableClose = true;
      confirmationDialogRef.componentInstance.dialogRef = confirmationDialogRef;
      confirmationDialogRef.afterClosed().subscribe(result => {
        if (confirmationDialogRef.componentInstance.confirmation) {
          let data = {
            project_master_id: this.editFormData.project_master_id,
            project_master_name: this.editProjectForm.value.name,
            // branch_master_id: this.editProjectForm.value.branch
            //   .branch_master_id,
            vendor_master_id: this.editProjectForm.value.party
              ? this.editProjectForm.value.party.vendor_master_id
              : null,
            legal_entity_master_id: this.editProjectForm.value.legalEntity
              .legal_entity_master_id,
            customer_master_id: this.editFormData.customer_master_id,
            staff_master_id: this.editProjectForm.value.staff
              ? this.editProjectForm.value.staff.staff_master_id
              : null,
            remarks: this.editProjectForm.value.remarks,
            user: credentials.id
          };
          this.masterService.updateProject(data).subscribe(
            (res: any) => {
              this.editProjectForm.reset();
              this.snackBar.open('Project Details Updated Successfully', 'OK', {
                duration: 2000
              });
              this.viewProjectComponent.getProject();
              this.editDialogRef.close();
            },
            (error: any) => {
              this.snackBar.open(error.error.message, 'OK', { duration: 2000 });
            }
          );
        }
      });
    } else {
      this.snackBar.open('Please Enter Valid Data', 'OK', { duration: 2000 });
    }
  }

  cancelModal() {
    this.editDialogRef.close();
  }
}
