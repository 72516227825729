import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ViewChild
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

// Service
import { MasterService } from '../../master.service';
import { MatTabGroup, MatDialog } from '@angular/material';
import { ViewProjectComponent } from '../view-project/view-project.component';
import { StaffMaster } from '@app/model/staffMaster.model';
import { environment } from '@env/environment';
import { ConfirmationDialogComponent } from '@app/shared/confirmation-dialog/confirmation-dialog.component';
import { SharedServiceService } from '@app/shared/shared-service.service';

// Component
@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
  encapsulation: ViewEncapsulation.None
})

// Class
export class AddProjectComponent implements OnInit {
  public addProjectForm: FormGroup;
  public staffList: StaffMaster[] = [];
  @Input() matTabGroup: MatTabGroup;
  @Input() viewProjectComponent: ViewProjectComponent;
  @ViewChild('addProjectFormObj') addProjectFormObj: NgForm;
  constructor(
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public sharedService: SharedServiceService
  ) {}

  ngOnInit() {
    this.createAddProjectForm();
    this.getStaffList();
  }

  createAddProjectForm() {
    this.addProjectForm = this.formBuilder.group({
      name: ['', Validators.required],
      // branch: ['', [Validators.required, this.sharedService.objectValidator]],
      legalEntity: [
        '',
        [Validators.required, this.sharedService.objectValidator]
      ],
      party: ['', [this.sharedService.objectValidator]],
      staff: [null, this.sharedService.objectValidator],
      // customer: [''],
      remarks: ['', Validators.required]
    });
  }

  /**
   * Method to get the complete staff list
   */
  getStaffList() {
    let data = {
      limit: environment.pageSizeOptions[0],
      skip: 0
    };
    this.masterService.getStaff(data).subscribe(res => {
      if (res.data && res.data.rows) {
        this.staffList = res.data.rows;
      } else {
        this.staffList = [];
      }
    });
  }

  addProject() {
    let credentials = JSON.parse(localStorage.getItem('credentials'));
    if (this.addProjectForm.valid) {
      const confirmationDialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        {
          width: '400px'
          // data : {name: "TEST", message: "Are you sure to submit add vendor ?"}
        }
      );
      confirmationDialogRef.disableClose = true;
      confirmationDialogRef.componentInstance.dialogRef = confirmationDialogRef;
      confirmationDialogRef.afterClosed().subscribe(result => {
        if (confirmationDialogRef.componentInstance.confirmation) {
          let data = {
            project_master_name: this.addProjectForm.value.name,
            // branch_master_id: this.addProjectForm.value.branch.branch_master_id,
            staff_master_id: this.addProjectForm.value.staff
              ? this.addProjectForm.value.staff.staff_master_id
              : null,
            vendor_master_id: this.addProjectForm.value.party
              ? this.addProjectForm.value.party.vendor_master_id
              : null,
            legal_entity_master_id: this.addProjectForm.value.legalEntity
              ? this.addProjectForm.value.legalEntity.legal_entity_master_id
              : null,
            //customer_master_id: this.addProjectForm.value.customer.customer_master_id,
            remarks: this.addProjectForm.value.remarks,
            user: credentials.id
          };
          this.masterService.addProject(data).subscribe(
            (res: any) => {
              this.viewProjectComponent.getProject();
              this.addProjectFormObj.resetForm();
              this.addProjectForm.patchValue({ staff: '' });
              setTimeout(() => {
                this.matTabGroup.selectedIndex = 0;
              }, 1000);
              this.snackBar.open('New Project Added Successfully', 'OK', {
                duration: 2000
              });
            },
            (error: any) => {
              this.snackBar.open(error.error.message, 'OK', { duration: 2000 });
            }
          );
        }
      });
    } else {
      this.snackBar.open('Please Enter Valid Data', 'OK', { duration: 2000 });
    }
  }

  cancelHandler() {
    this.addProjectFormObj.resetForm();
    this.matTabGroup.selectedIndex = 0;
  }
}
