/** Angular Imports */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

/** rxjs Imports */
import { filter } from 'rxjs/operators';

/** Custom Model */
import { VendorBreadcrumb } from './vendor-breadcrumb.model';

/**
 * Route data property to generate breadcrumb using a static string.
 *
 * Example- breadcrumb: 'Home'
 */
const routeDataVendorBreadcrumb = 'vedorBreadcrumb';
/**
 * Route data property to generate breadcrumb using given route parameter name.
 *
 * Example- routeParamVendorBreadcrumb: 'id'
 */
const routeParamVendorBreadcrumb = 'routeParamVendorBreadcrumb';
/**
 * Route data property to generate breadcrumb using resolved data property name.
 *
 * Use array to specify name for a nested object property.
 *
 * Example- routeResolveVendorBreadcrumb: ['user', 'username']
 */
const routeResolveVendorBreadcrumb = 'routeResolveVendorBreadcrumb';
/**
 * Route data property to specify whether generated breadcrumb should have a link.
 *
 * True by default. Specify false if a link is not required.
 *
 * Example- addVendorBreadcrumbLink: false
 */
const routeAddVendorBreadcrumbLink = 'addVendorBreadcrumbLink';

/**
 * Generate breadcrumbs dynamically via route configuration.
 */
@Component({
  selector: 'app-vendor-breadcrumb',
  templateUrl: './vendor-breadcrumb.component.html',
  styleUrls: ['./vendor-breadcrumb.component.scss']
})
export class VendorBreadcrumbComponent implements OnInit {
  /** Array of breadcrumbs. */
  vendorBreadcrumbs: VendorBreadcrumb[];

  /**
   * Generates the breadcrumbs.
   * @param {ActivatedRoute} activatedRoute Activated Route.
   * @param {Router} router Router for navigation.
   */
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    console.log('breadcrum1');
    this.generateVendorBreadcrumbs();
  }

  ngOnInit() {
    console.log('breadcrum');
  }

  /**
   * Generates the array of breadcrumbs for the visited route.
   */
  generateVendorBreadcrumbs() {
    console.log('In');
    //console.log(currentRoute);
    const onNavigationEnd = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    onNavigationEnd.subscribe(() => {
      this.vendorBreadcrumbs = [];
      let currentRoute = this.activatedRoute.root;
      let currentUrl = '';
      console.log('currentRoute');
      console.log(currentRoute);
      while (currentRoute.children.length > 0) {
        const childrenRoutes = currentRoute.children;
        let vendorBreadcrumbLabel: any;
        let url: any;
        console.log('childrenRoutes');
        console.log(childrenRoutes);
        childrenRoutes.forEach(route => {
          currentRoute = route;
          vendorBreadcrumbLabel = false;

          if (route.outlet !== 'primary') {
            return;
          }

          const routeURL = route.snapshot.url
            .map(segment => segment.path)
            .join('/');
          currentUrl += `/${routeURL}`;

          if (currentUrl === '/') {
            vendorBreadcrumbLabel = 'Home';
          }

          const hasData = route.routeConfig && route.routeConfig.data;
          console.log('hasData');
          console.log(hasData);
          if (hasData) {
            if (
              route.snapshot.data.hasOwnProperty(
                routeResolveVendorBreadcrumb
              ) &&
              route.snapshot.data[routeResolveVendorBreadcrumb]
            ) {
              vendorBreadcrumbLabel = route.snapshot.data;
              route.snapshot.data[routeResolveVendorBreadcrumb].forEach(
                (property: any) => {
                  vendorBreadcrumbLabel = vendorBreadcrumbLabel[property];
                }
              );
            } else if (
              route.snapshot.data.hasOwnProperty(routeParamVendorBreadcrumb) &&
              route.snapshot.paramMap.get(
                route.snapshot.data[routeParamVendorBreadcrumb]
              )
            ) {
              vendorBreadcrumbLabel = route.snapshot.paramMap.get(
                route.snapshot.data[routeParamVendorBreadcrumb]
              );
            } else if (
              route.snapshot.data.hasOwnProperty(routeDataVendorBreadcrumb)
            ) {
              vendorBreadcrumbLabel =
                route.snapshot.data[routeDataVendorBreadcrumb];
            }

            if (
              route.snapshot.data.hasOwnProperty(routeAddVendorBreadcrumbLink)
            ) {
              url = route.snapshot.data[routeAddVendorBreadcrumbLink];
            } else {
              url = currentUrl;
            }
          }

          const vedorBreadcrumb: VendorBreadcrumb = {
            label: vendorBreadcrumbLabel,
            url: url
          };

          if (vendorBreadcrumbLabel) {
            this.vendorBreadcrumbs.push(vedorBreadcrumb);
          }
        });
      }
    });
  }
}
