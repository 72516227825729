import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';
import { VendorSubHeaderComponent } from './vendor-sub-header/vendor-sub-header.component';

import {
  AuthenticationService,
  CredentialsService,
  I18nService
} from '@app/core';

@Component({
  selector: 'app-vendor-shell',
  templateUrl: './vendor-shell.component.html',
  styleUrls: ['./vendor-shell.component.scss']
})
export class VendorShellComponent implements OnInit {
  flag = true;

  /** Sets the initial state of sidenav as collapsed. Not collapsed if false. */
  sidenavCollapsed = true;
  @ViewChild('vendorSubHeader') vendorSubHeader: VendorSubHeaderComponent;

  constructor(
    private router: Router,
    private titleService: Title,
    private media: MediaObserver,
    private authenticationService: AuthenticationService,
    public credentialsService: CredentialsService,
    private i18nService: I18nService
  ) {}

  ngOnInit() {}

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  logout() {
    this.authenticationService
      .logout()
      .subscribe(() =>
        this.router.navigate(['/vendor/login'], { replaceUrl: true })
      );
  }

  test() {
    this.flag = !this.flag;
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  /**
   * Toggles the current collapsed state of sidenav according to the emitted event.
   */
  toggleCollapse($event: boolean) {
    this.sidenavCollapsed = $event;
  }

  selectedTab($event: any) {
    console.log($event);
    let eventUrl = $event.url;
    if (eventUrl.includes('purchase')) {
      this.vendorSubHeader.selectedTab = '/purchase/bill';
    } else {
      this.vendorSubHeader.selectedTab = '/setup';
    }
  }
}
