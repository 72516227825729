import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/material.module';
import { LoaderComponent } from './loader/loader.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { TimeOffsetPipe } from './time-offset.pipe';
import { StringTruncatePipe } from './string-truncate.pipe';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { MatFileUploadComponent } from './mat-file-uploads/mat-file-upload/mat-file-upload.component';
import { MatFileUploadQueueComponent } from './mat-file-uploads/mat-file-upload-queue/mat-file-upload-queue.component';
import { BytesPipePipe } from './mat-file-uploads/bytes-pipe.pipe';
import { FileUploadInputDirective } from './mat-file-uploads/file-upload-input.directive';
import { HttpClientModule } from '@angular/common/http';
import { ControlErrorComponent } from './control-error/control-error.component';
import { FormSubmitDirective } from './form-submit.directive';
import { DateTimePipe } from './date-time.pipe';
@NgModule({
  imports: [
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    MatFileUploadModule,
    HttpClientModule
  ],
  declarations: [
    LoaderComponent,
    ConfirmationDialogComponent,
    TimeOffsetPipe,
    DateTimePipe,
    StringTruncatePipe,
    MatFileUploadComponent,
    MatFileUploadQueueComponent,
    BytesPipePipe,
    FileUploadInputDirective,
    ControlErrorComponent,
    FormSubmitDirective
  ],
  exports: [
    LoaderComponent,
    ConfirmationDialogComponent,
    TimeOffsetPipe,
    DateTimePipe,
    StringTruncatePipe,
    MatFileUploadComponent,
    MatFileUploadQueueComponent,
    BytesPipePipe,
    FileUploadInputDirective,
    ControlErrorComponent,
    FormSubmitDirective
  ],
  providers: [],
  entryComponents: [ConfirmationDialogComponent]
})
export class SharedModule {}
