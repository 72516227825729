import {
  Component,
  OnInit,
  Input,
  Output,
  ViewEncapsulation,
  EventEmitter
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { SalesService } from '../../../sales/sales.service';
import { CustomerMaster } from '../../../model/customerMaster.model';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss']
})
export class SelectCustomerComponent implements OnInit {
  @Input() customerCtrl = new FormControl();
  @Input() isEditable = true;
  customerList: CustomerMaster[] = [];
  filteredCustomerList: Observable<CustomerMaster[]>;
  @Output() public selectedCustomer = new EventEmitter();
  @Input() tabindex: number;

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    // Call the method getCustomerList and get the result in a callback
    this.getCustomerList(customerList => {
      this.filteredCustomerList = customerList;
    });
  }

  /**
   *
   *
   * @param {(customerList: Observable<CustomerMaster[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete customer list
   */
  getCustomerList(fn: (customerList: Observable<CustomerMaster[]>) => void) {
    this.salesService.getCustomer().subscribe(res => {
      if (res.data && res.data.rows) {
        this.customerList = res.data.rows;
      } else {
        this.customerList = [];
      }
      fn(
        (this.filteredCustomerList = this.customerCtrl.valueChanges.pipe(
          startWith(this.customerCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(customer =>
            customer && customer.length > 0
              ? this.filterCustomer(customer)
              : this.customerList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} customer
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected customer
   */
  public displaySelectedCustomer(customer: CustomerMaster) {
    return customer ? customer.customer_master_name : customer;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the customer based on input
   *
   */
  private filterCustomer(name: string) {
    return this.customerList.filter(
      customer =>
        customer.customer_master_name
          .toLowerCase()
          .indexOf(name.toLowerCase()) >= 0
    );
  }

  selecteCustomerClickHandler(customer: CustomerMaster) {
    this.selectedCustomer.emit(customer);
  }
}
