import { Adapter } from '@app/sales/response.model';
import {
  ProductionDAO,
  ProductionDTO,
  ProductionStatusEnum,
  IsActiveEnum
} from './production.interface';

export class ResponceProduction {
  constructor(
    public production_id: number,
    public date: Date,
    public branch_master: number,
    public project_master: number,
    public production_no: string,
    public remarks: string,
    public asset_tag: Array<Object>,
    public source_tag: Array<Object>,
    public staff_tag: Array<Object>,
    public rev_segment_tag: Array<Object>,
    public custom_tag: Array<Object>,
    public booking_tag: Array<Object>
  ) {}
}

export class ProductionResponceAdapter implements Adapter<ResponceProduction> {
  adapt(item: ProductionDTO): ResponceProduction {
    return new ResponceProduction(
      item.production_master_id,
      item.date,
      item.branch_master_id,
      item.project_master_id,
      item.production_no,
      item.remarks,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }
}

export class RequestProduction {
  constructor(
    public production_master_id: number,
    public date: Date,
    public branch_master_id: number,
    public project_master_id: number,
    public production_no: string,
    public production_status: ProductionStatusEnum,
    public remarks: string,
    public created_by: number,
    public last_updated_by: number,
    public created_at: Date,
    public last_updated_at: Date,
    public is_active: IsActiveEnum,
    public ip_address: string
  ) {}
}

export class ProductionRequestAdapter implements Adapter<RequestProduction> {
  adapt(item: ProductionDAO): RequestProduction {
    return new RequestProduction(
      item.production_id,
      item.date,
      item.branch_master,
      item.project_master,
      item.production_no,
      null,
      item.remarks,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }
}
