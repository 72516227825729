import { Injectable } from "@angular/core";
import { SexEnum, CustomerDTO, CustomerDAO } from "./customer.customer.interface";
import { Adapter } from "../response.model";

export class ResponseCustomer {
    constructor(
        public customerId: number,
        public customerName: string,
        public mobile: number,
        public pinCode: number,
        public country: number,
        public state: number,
        public address:string,
        public gstNumber: string,
        public vatNumber: string,
        public customerType: number,
        public nextCallDate: Date,
        public customerCode: string,
        public dob: Date,
        public sex: SexEnum,
        public adhaar: string,
        public panNumber: number,
        public email: string,
        public remarks: string,
        public branchTag?: Array<object>,
        public sourceTag?: Array<object>,
        public staffTag?: Array<object>,
        public revSegmentTag?: Array<object>,
        public customTag?: Array<object>,
    ){}
}

export class CustomerResponseAdapter implements Adapter<ResponseCustomer> {
    adapt(item: CustomerDTO): ResponseCustomer {
      return new ResponseCustomer(
        item.customer_master_id, 
        item.customer_master_name, 
        item.primary_contact_no, 
        item.pincode,
        item.country_id,
        item.state_id,
        item.address,
        item.gst_no,
        item.vat_no,
        item.customer_type_id,
        item.next_call_date,
        item.customer_code,
        item.dob,
        item.sex,
        item.aadhar_no,
        item.pan_card_id,
        item.email,
        item.remarks,
        item.branchTag,
        item.sourceTag,
        item.staffTag,
        item.revSegmentTag,
        item.customTag,
        );
    }
}

export class RequestCustomer {
    constructor(
        public customer_master_id : number, 
        public customer_master_name : string, 
        public primary_contact_no: number,
        public pincode: number,
        public country_id: number,
        public state_id: number,
        public address:string,
        public gst_no: string,
        public pan_no: string,
        public vat_no: String,
        public customer_type_id: number,
        public next_call_date: Date,
        public customer_code: string,
        public dob: Date,
        public sex: SexEnum,
        public aadhar_no: string,
        public pan_card_id: number,
        public email: string,
        public remarks: string,
        public branchTag?: Array<object>,
        public sourceTag?: Array<object>,
        public staffTag?: Array<object>,
        public revSegmentTag?: Array<object>,
        public customTag?: Array<object>,
    ){}
}

export class CustomerRequestAdapter implements Adapter<RequestCustomer> {
    adapt(item: CustomerDAO): RequestCustomer {
      return new RequestCustomer(
        item.customerId, 
        item.customerName, 
        item.mobile, 
        item.pinCode,
        item.country.country_id,
        item.state.state_id,
        item.address,
        item.gstNumber,
        item.panNumber,
        item.vatNumber,
        item.customerType,
        item.nextCallDate,
        item.customerCode,
        item.dob,
        item.sex,
        item.adhaar,
        null,
        item.email,
        item.remarks,
        item.branchTag,
        item.sourceTag,
        item.staffTag,
        item.revSegmentTag,
        item.customTag,
        );
    }
}