import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaterialMasterComponent } from './material-master.component';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: '',
      data: { title: 'MaterialMaster', breadcrumb: 'MaterialMaster' },
      children: [
        {
          path: '',
          component: MaterialMasterComponent
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MaterialMasterRoutingModule {}
