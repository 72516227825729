import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import {
  MatTableDataSource,
  MatDialog,
  MatDialogConfig,
  PageEvent,
  Sort,
  MatPaginator,
  MatTabGroup
} from '@angular/material';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';

// Internal component imports
import { MasterService } from '../../master.service';
import { DetailViewProjectComponent } from '../detail-view-project/detail-view-project.component';
import { ProjectMaster } from '../../../model/projectMaster.model';
import { EditProjectComponent } from '../edit-project/edit-project.component';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { SharedServiceService } from '@app/shared/shared-service.service';

// Define component
@Component({
  selector: 'app-view-project',
  templateUrl: './view-project.component.html',
  styleUrls: ['./view-project.component.scss'],
  encapsulation: ViewEncapsulation.None
})

// Declare class
export class ViewProjectComponent implements OnInit {
  sortby: string;
  sortas: string;
  emptyList: boolean = false;
  pageSizeOptions: Array<any>;

  dataSource: MatTableDataSource<ProjectMaster>;
  limit: number;
  skip: number;
  totalCount: number;
  projectList: Array<ProjectMaster>;
  public projectName: FormControl;
  displayedColumnsForApproved: string[];

  public projectSearchForm: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('matTabGroup') projectTab: MatTabGroup;
  public searchON: boolean = false;

  constructor(
    private dialog: MatDialog,
    private masterService: MasterService,
    private fb: FormBuilder,
    public sharedService:SharedServiceService

  ) {
    this.sortby = 'id';
    this.sortas = environment.listDefaultSort;
    this.emptyList = false;
    this.pageSizeOptions = environment.pageSizeOptions;

    this.limit = environment.pageSizeOptions[0];
    this.skip = 0;
    this.totalCount = 0;
    this.projectName = new FormControl();
    this.displayedColumnsForApproved = [
      'position',
      'projectName',
      'vendor',
      'entity',
      'staffName',
      'status',
      // 'customerName',
      'action'
    ];
  }

  ngOnInit() {
    this.createProjectSearchForm();
    this.getProject();
  }

  createProjectSearchForm() {
    this.projectSearchForm = this.fb.group({
      fromDate: [''],
      toDate: [''],
      selectedBranch: [''],
      selectedProjectName: ['']
    });
  }

  /**
   * Open pop up dialog box to view the details of the legal project
   */
  openDialog(element: any) {
    const dialogConfig = new MatDialogConfig();

    const dialogRef = this.dialog.open(DetailViewProjectComponent, {
      height: '75%',
      width: '80%'
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  /**
   * Open pop up dialog box to view the details of the legal project
   */
  openEditDialog(row: any) {
    const editDialogRef = this.dialog.open(EditProjectComponent, {
      // height: '75%',
      width: '80%'
    });
    editDialogRef.componentInstance.editDialogRef = editDialogRef;
    editDialogRef.componentInstance.editFormData = row;
    editDialogRef.componentInstance.viewProjectComponent = this;
  }

  getProject() {
    this.masterService
      .getProject(this.limit, this.skip, this.sortby, this.sortas)
      .subscribe((res: any) => {
        if (res.data && res.data.rows) {
          this.projectList = res.data.rows;
          this.totalCount = res.data.count;
        }
        this.dataSource = new MatTableDataSource(this.projectList);
      });
  }

  getNextPaginationHandler(event: PageEvent) {
    this.skip = event.pageSize * event.pageIndex;
    this.limit = event.pageSize;
    if (this.searchON) {
      this.searchProject();
    } else {
      this.getProject();
    }
  }

  searchProject() {
    var data = {
      fromDate: this.sharedService.convertFromDate(this.projectSearchForm.value.fromDate),
      toDate: this.sharedService.convertTODate(this.projectSearchForm.value.toDate),
      name: this.projectSearchForm.value.selectedProjectName,
      branch: this.projectSearchForm.value.selectedBranch
        ? this.projectSearchForm.value.selectedBranch.branch_master_id
        : '',
      limit: this.limit,
      skip: this.skip,
      sortby: this.sortby,
      sortas: this.sortas
    };
    this.masterService.searchProject(data).subscribe((res: any) => {
      if (res.data && res.data.rows && res.data.rows.length > 0) {
        this.searchON = true;
        this.projectList = res.data.rows;
        this.totalCount = res.data.count;
        this.dataSource = new MatTableDataSource(this.projectList);
      } else {
        this.searchON = true;
        this.projectList = [];
        this.totalCount = 0;
        this.dataSource = new MatTableDataSource(this.projectList);
      }
    });
  }

  resetProjectSearch() {
    this.searchON = false;
    this.projectSearchForm.reset();
    // this.paginator.pageIndex = 0;
    this.skip = 0;
    this.limit = environment.pageSizeOptions[0];
    this.getProject();
  }

  /**
   * Sort handler
   */
  sortData(sort: Sort) {
    this.sortby = sort.active;
    this.sortas = sort.direction;
    this.limit = this.limit;
    this.skip = this.skip;
    if (this.searchON) {
      this.searchProject();
    } else {
      this.getProject();
    }
  }

  /**
   * Open pop up dialog box to view the details of the legal entity
   */
  openViewDialog(element: any) {
    const dialogRef = this.dialog.open(DetailViewProjectComponent, {
      width: '80%'
    });
    dialogRef.componentInstance.projectData = element;
    dialogRef.afterClosed().subscribe(result => {
      this.getProject();
    });
  }

  goToAddProjectPage() {
    this.projectTab.selectedIndex = 1;
  }
}
