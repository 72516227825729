import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AccountMaster } from '@app/model/accountMaster.model';
import { Observable } from 'rxjs';
import { MasterService } from '@app/master/master.service';
import { startWith, map } from 'rxjs/operators';
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';

@Component({
  selector: 'app-select-all-account-master',
  templateUrl: './select-all-account-master.component.html',
  styleUrls: ['./select-all-account-master.component.scss']
})
export class SelectAllAccountMasterComponent implements OnInit {
  @Input() allAccountMasterCtrl = new FormControl();
  @Output() selectedAllAccountMaster = new EventEmitter();
  @Input() isLabelRequired: boolean = true;
  @Input() matLabelText: String = 'Pay to account';
  @Input() placeholderText: String = 'Select pay to account';
  @Input() isEditable = true;
  @Input() errorMessage = 'Expense Account is required.';
  @Input() tabindex: number;
  allAccountMasterList: AccountMaster[];
  filteredAllAccountMasterList: Observable<AccountMaster[]>;

  constructor(private masterService: MasterService) {}

  ngOnInit() {
    // Call the method getAccountMasterList and get the result in a callback
    this.getAccountMasterList(accountMasterList => {
      this.filteredAllAccountMasterList = accountMasterList;
    });
  }

  /**
   *
   *
   * @param {(branchList: Observable<AccountMaster[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete AccountMaster list
   */
  getAccountMasterList(
    fn: (accountMasterList: Observable<AccountMaster[]>) => void
  ) {
    this.masterService.getAllAccountMaster().subscribe(res => {
      if (res.data && res.data.rows) {
        this.allAccountMasterList = res.data.rows;
      } else {
        this.allAccountMasterList = [];
      }
      fn(
        (this.filteredAllAccountMasterList = this.allAccountMasterCtrl.valueChanges.pipe(
          startWith(this.allAccountMasterCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(allAccountMaster =>
            allAccountMaster && allAccountMaster.length > 1
              ? this.filterAccountMaster(allAccountMaster)
              : this.allAccountMasterList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} accountMaster
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected accountMaster
   */
  public displaySelectedAllAccountMaster(allAccountMaster: any) {
    return allAccountMaster
      ? allAccountMaster.account_master_name
      : allAccountMaster;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the AccountMaster type based on input
   *
   */
  private filterAccountMaster(name: string) {
    return this.allAccountMasterList.filter(
      allAccountMaster =>
        allAccountMaster.account_master_name
          .toLowerCase()
          .indexOf(name.toLowerCase()) >= 0
    );
  }

  public selectAllAccountMasterClickHandler(allAccountMaster: AccountMaster) {
    this.selectedAllAccountMaster.emit(allAccountMaster);
  }
}
