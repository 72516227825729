import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
//import { far } from '@fortawesome/free-regular-svg-icons';

import { environment } from '@env/environment';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { VendorShellModule } from './vendor-shell/vendor-shell.module';
import { LoginModule } from './login/login.module';
import { VendorLoginModule } from './vendor-login/vendor-login.module';
//import { SetupModule } from './setup/setup.module';
//import { SalesModule } from './sales/sales.module';
//import { PurchaseModule } from './purchase/purchase.module';
import { MasterModule } from './master/master.module';
import { MaterialMasterModule } from './material-master/material-master.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
//import { UserModule } from './user/user.module';
//import { AccountingModule } from './accounting/accounting.module';
//import { InventoryModule } from './inventory/inventory.module';
//import { EntityModule } from './entity/entity.module';
//import { ConfigModule } from './config/config.module';
//import { ReportsModule } from './reports/reports.module';
//import { ProductionsModule } from './productions/productions.module';
import {
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
  MatExpansionModule
} from '@angular/material';
//import { SettingModule } from './setting/setting.module';
import { ChartsModule } from 'ng2-charts';
import {
  CustomerRequestAdapter,
  CustomerResponseAdapter
} from './sales/customer/customer.customer.model';
import {
  ProductionResponceAdapter,
  ProductionRequestAdapter
} from './productions/production/production.model';
import { productionCompositionResponceAdapter } from './productions/production-composition/production-composition.model';
import {
  FGProductionResponceAdapter,
  FGProductionRequestAdapter
} from './productions/fg-production/fg-production.model';
import {
  MaterialIssueResponceAdapter,
  MaterialIssueRequestAdapter
} from './productions/material-issue/material-issue.model';
@NgModule({
  imports: [
    BrowserModule,
    FlexLayoutModule,
    FontAwesomeModule,
    ChartsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production
    }),
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    CoreModule,
    SharedModule,
    ShellModule,
    VendorShellModule,
    HomeModule,
    LoginModule,
    VendorLoginModule,
    //SetupModule,
    //SalesModule,
    MasterModule,
    MaterialMasterModule,
    //PurchaseModule,
    // UserModule,
    // AccountingModule,
    //InventoryModule,
    //  EntityModule,
    // ConfigModule,
    //  ReportsModule,
    // SettingModule,
    MatExpansionModule,
    //ProductionsModule,
    Angulartics2Module.forRoot(),
    AppRoutingModule // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    CustomerRequestAdapter,
    CustomerResponseAdapter,
    ProductionResponceAdapter,
    ProductionRequestAdapter,
    productionCompositionResponceAdapter,
    MaterialIssueResponceAdapter,
    MaterialIssueRequestAdapter,
    FGProductionResponceAdapter,
    FGProductionRequestAdapter,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }
  ]
})
export class AppModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(fas);
  }
}
