import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogComponent } from '@app/shared/confirmation-dialog/confirmation-dialog.component';
import { BranchMaster } from '@app/model/branchMaster.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from '@env/environment.prod';
import { StaffMaster } from '@app/model/staffMaster.model';
import { MasterService } from '@app/master/master.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Country } from '@app/model/country.model';

@Component({
  selector: 'app-detail-view-branch',
  templateUrl: './detail-view-branch.component.html',
  styleUrls: ['./detail-view-branch.component.scss']
})
export class DetailViewBranchComponent implements OnInit {
  public branchData: BranchMaster;
  public viewBranchForm: FormGroup;
  public dateFormat: string = environment.dateFormat;
  public staffList: StaffMaster[] = [];
  public selectedCountryId: number;

  constructor(
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    //Init method

    this.getStaffList();
    this.createViewBranchForm();
  }

  // method for view legal entity form fields
  createViewBranchForm() {
    this.viewBranchForm = this.formBuilder.group({
      name: [''],
      gst: [''],
      entity: [''],
      country: [''],
      state: [''],
      pcontactname: [''],
      pcontactno: [''],
      pincode: [''],
      address: [''],
      acontactname: [''],
      acontactno: [''],
      remarks: ['']
    });
    this.patchViewBranchForm(this.branchData);
  }

  /**
   * Method to get the complete staff list
   */
  getStaffList() {
    let data = {
      limit: environment.pageSizeOptions[0],
      skip: 0
    };
    this.masterService.getStaff(data).subscribe(res => {
      if (res.data && res.data.rows) {
        this.staffList = res.data.rows;
      } else {
        this.staffList = [];
      }
    });
  }

  // method for patch legal entity form fields
  patchViewBranchForm(branchData: BranchMaster) {
    if (branchData) {
      this.masterService.getBranchById(branchData.branch_master_id).subscribe(
        (res: any) => {
          this.branchData = res.data;
          this.viewBranchForm.patchValue({
            name: this.branchData.branch_master_name,
            gst: this.branchData.gst_master.gst_no,
            entity: this.branchData.legal_entity_master,
            country: this.branchData.country,
            state: this.branchData.state,
            pcontactname: this.branchData.primary_contact_name,
            pcontactno: this.branchData.primary_contact_no,
            pincode: this.branchData.pincode,
            address: this.branchData.address,
            acontactname: this.branchData.alternate_contact_name,
            acontactno: this.branchData.alternate_contact_no,
            remarks: this.branchData.remarks
          });
        },
        (error: any) => {
          if (error.error.error.message) {
            this.snackBar.open(error.error.error.message, 'OK', {
              duration: 2000
            });
          } else {
            this.snackBar.open(
              'Something went wrong. Please try again.',
              'OK',
              { duration: 2000 }
            );
          }
        }
      );
    }
  }

  // change the status
  changeBranchStatus(status: string) {
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        width: '400px',
        data: {
          name: 'Test',
          message: 'Are you sure, you want to change the status'
        }
      }
    );
    confirmationDialogRef.disableClose = true;
    confirmationDialogRef.componentInstance.dialogRef = confirmationDialogRef;
    confirmationDialogRef.afterClosed().subscribe(result => {
      if (confirmationDialogRef.componentInstance.confirmation) {
        let credentials = JSON.parse(localStorage.getItem('credentials'));
        let data = {
          branch_master_id: this.branchData.branch_master_id,
          is_active: status,
          user: credentials.id
        };
        this.masterService.changeBranchStatus(data).subscribe(
          (res: any) => {
            this.snackBar.open('Branch Updated Successfully', 'OK', {
              duration: 2000
            });
            this.patchViewBranchForm(this.branchData);
          },
          (error: any) => {
            if (error.error.error.message) {
              this.snackBar.open(error.error.error.message, 'OK', {
                duration: 2000
              });
            } else {
              this.snackBar.open(
                'Something went wrong. Please try again.',
                'OK',
                { duration: 2000 }
              );
            }
          }
        );
      }
    });
  }

  compareBranchs(c1: any, c2: any): boolean {
    return c1 && c2
      ? c1.branch_master_name === c2.branch_master_name
      : c1 === c2;
  }

  selectedCountryClickHandler(data: Country) {
    this.selectedCountryId = data.country_id;
  }
}
