import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as _ from 'lodash';
// Internal component imports
import { PurchaseService } from '../../../purchase/purchase.service';
import { PurchaseType } from '../../../model/purchaseType.model';

// Define component
@Component({
  selector: 'app-select-purchase-type',
  templateUrl: './select-purchase-type.component.html',
  styleUrls: ['./select-purchase-type.component.scss']
})

// Declare class
export class SelectPurchaseTypeComponent implements OnInit {
  @Input() purchaseTypeCtrl = new FormControl();
  @Input() isEditable = true;
  @Output() selectedPurchaseType = new EventEmitter();
  purchaseTypeList: PurchaseType[];
  filteredPurchaseTypeList: Observable<PurchaseType[]>;

  /**
   * Constructor
   */
  constructor(private purchaseService: PurchaseService) {}

  /**
   * Init method
   */
  ngOnInit() {
    // Call the method getPurchaseTypeList and get the result in a callback
    this.getPurchaseTypeList(purchaseTypeList => {
      this.filteredPurchaseTypeList = purchaseTypeList;
    });
  }

  /**
   * Method to get the complete purchase type list
   */
  getPurchaseTypeList(
    fn: (purchaseTypeList: Observable<PurchaseType[]>) => void
  ) {
    this.purchaseService.getPurchaseType().subscribe(res => {
      if (res.data && res.data.rows) {
        this.purchaseTypeList = res.data.rows;
        if (this.purchaseTypeList && this.purchaseTypeList.length > 0) {
          let purType = _.filter(this.purchaseTypeList, {
            purchase_type_name: 'Domestic Credit Bill'
          })[0];
          this.purchaseTypeCtrl.patchValue(purType);
        }
      } else {
        this.purchaseTypeList = [];
      }
      fn(
        (this.filteredPurchaseTypeList = this.purchaseTypeCtrl.valueChanges.pipe(
          startWith(this.purchaseTypeCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(purchaseType =>
            purchaseType && purchaseType.length > 1
              ? this.filterPurchaseTypes(purchaseType)
              : this.purchaseTypeList.slice()
          )
        ))
      );
    });
  }

  /*
   * Method to display only the name of the selected purchase type
   */
  public displaySelectedPurchaseType(purchaseType: PurchaseType) {
    return purchaseType ? purchaseType.purchase_type_name : purchaseType;
  }

  /**
   * Method to filter the purchase type based on input
   */
  private filterPurchaseTypes(purchase_type_name: string) {
    return this.purchaseTypeList.filter(
      purchaseType =>
        purchaseType.purchase_type_name
          .toLowerCase()
          .indexOf(purchase_type_name.toLowerCase()) >= 0
    );
  }

  selectPurchaseTypeClickHandler(purchaseType: PurchaseType) {
    this.selectedPurchaseType.emit(purchaseType);
  }
}
