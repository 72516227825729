import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductionsService {
  constructor(private http: HttpClient) {}

  /********************** PRODUCTION ***********************/

  getProduction(limit: number, skip: number, sortby: string, sortas: string) {
    console.log('list production service');
    return this.http.get('/production', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }

  getProductionById(id: number): Observable<any> {
    console.log('get production service');
    return this.http.get('/production/id/' + id);
  }

  getProductionList(): Observable<any> {
    console.log('get list of production service');
    return this.http.get('/production', {});
  }

  getProductionNo() {
    console.log('get production no service');
    return this.http.get('/production', {});
  }

  searchProduction(data: any): Observable<any> {
    console.log('search production service');
    return this.http.post('/production/search', data);
  }

  addProduction(data: any): Observable<any> {
    console.log('add production service');
    return this.http.post('/production', data);
  }

  editProduction(data: any): Observable<any> {
    console.log('edit production service');
    return this.http.put('/production', data);
  }

  getProductionNumber(data: string): Observable<any> {
    console.log('get production number service');
    return this.http.get('/production/productionNo', {
      params: {
        prefix: data
      }
    });
  }

  /********************** PRODUCTION COMPOSITION ***********************/

  getProductionComposition(
    limit: number,
    skip: number,
    sortby: string,
    sortas: string
  ) {
    console.log('get production composition service');
    return this.http.get('/productionComposition', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }

  getProductionCompositionById(id: number): Observable<any> {
    console.log('get production composition service');
    return this.http.get('/productionComposition/id/' + id);
  }

  searchProductionComposition(data: any): Observable<any> {
    console.log('search production composition service');
    return this.http.post('/productionComposition/search', data);
  }

  addProductionComposition(data: any): Observable<any> {
    console.log('add production composition service');
    return this.http.post('/productionComposition', data);
  }

  editProductionComposition(data: any): Observable<any> {
    console.log('edit production composition service');
    return this.http.put('/productionComposition', data);
  }

  /********************** MATERIAL ISSUE ***********************/

  getMaterialIssue(
    limit: number,
    skip: number,
    sortby: string,
    sortas: string
  ) {
    console.log('list material issue service');
    return this.http.get('/materialIssue', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }

  getMaterialIssueById(id: number): Observable<any> {
    console.log('get material issue service');
    return this.http.get('/materialIssue/id/' + id);
  }

  searchMaterialIssue(data: any): Observable<any> {
    console.log('search material issue service');
    return this.http.post('/materialIssue/search', data);
  }

  addMaterialIssue(data: any): Observable<any> {
    console.log('add material issue service');
    return this.http.post('/materialIssue', data);
  }

  editMaterialIssue(data: any): Observable<any> {
    console.log('edit material issue service');
    return this.http.put('/materialIssue', data);
  }

  /********************** FG PRODUCTION ***********************/

  getFGProduction(limit: number, skip: number, sortby: string, sortas: string) {
    console.log('list fg production service');
    return this.http.get('/fgproduction', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }

  getFGProductionById(id: number): Observable<any> {
    console.log('get fg production service');
    return this.http.get('/fgproduction/id/' + id);
  }

  searchFGProduction(data: any): Observable<any> {
    console.log('search fg production service');
    return this.http.post('/fgproduction/search', data);
  }

  addFGIssue(data: any): Observable<any> {
    console.log('add fg production service');
    return this.http.post('/fgproduction', data);
  }

  editFGIssue(data: any): Observable<any> {
    console.log('edit fg production service');
    return this.http.put('/fgproduction', data);
  }
}
