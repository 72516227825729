import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AccountMaster } from '@app/model/accountMaster.model';
import { Observable } from 'rxjs';
import { MasterService } from '@app/master/master.service';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-select-vendor-account-master',
  templateUrl: './select-vendor-account-master.component.html',
  styleUrls: ['./select-vendor-account-master.component.scss']
})
export class SelectVendorAccountMasterComponent implements OnInit {
  @Input() vendorAccountMasterCtrl = new FormControl();
  @Output() selectedVendorAccountMaster = new EventEmitter();
  @Input() isLabelRequired: boolean = true;
  @Input() isEditable = true;
  vendorAccountMasterList: AccountMaster[];
  filteredVendorAccountMasterList: Observable<AccountMaster[]>;

  constructor(private masterService: MasterService) {}

  ngOnInit() {
    // Call the method getAccountMasterList and get the result in a callback
    this.getAccountMasterList(accountMasterList => {
      this.filteredVendorAccountMasterList = accountMasterList;
    });
  }

  /**
   *
   *
   * @param {(branchList: Observable<AccountMaster[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete AccountMaster list
   */
  getAccountMasterList(
    fn: (accountMasterList: Observable<AccountMaster[]>) => void
  ) {
    this.masterService.getVendorAccountMaster().subscribe(res => {
      if (res.data && res.data.rows) {
        this.vendorAccountMasterList = res.data.rows;
      } else {
        this.vendorAccountMasterList = [];
      }
      fn(
        (this.filteredVendorAccountMasterList = this.vendorAccountMasterCtrl.valueChanges.pipe(
          startWith(this.vendorAccountMasterCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(vendorAccountMaster =>
            vendorAccountMaster && vendorAccountMaster.length > 1
              ? this.filterAccountMaster(vendorAccountMaster)
              : this.vendorAccountMasterList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} accountMaster
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected accountMaster
   */
  public displaySelectedVendorAccountMaster(vendorAccountMaster: any) {
    return vendorAccountMaster
      ? vendorAccountMaster.account_master_name
      : vendorAccountMaster;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the AccountMaster type based on input
   *
   */
  private filterAccountMaster(name: string) {
    return this.vendorAccountMasterList.filter(
      vendorAccountMaster =>
        vendorAccountMaster.account_master_name
          .toLowerCase()
          .indexOf(name.toLowerCase()) >= 0
    );
  }

  public selectVendorAccountMasterClickHandler(
    vendorAccountMaster: AccountMaster
  ) {
    this.selectedVendorAccountMaster.emit(vendorAccountMaster);
  }
}
