import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SalesService } from '../../../sales/sales.service';
import { SourceMaster } from '../../../model/sourceMaster.model';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatChipInputEvent
} from '@angular/material';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import * as _ from 'lodash';

@Component({
  selector: 'app-select-source',
  templateUrl: './select-source.component.html',
  styleUrls: ['./select-source.component.scss']
})
export class SelectSourceComponent implements OnInit {
  @Output() selectedSourceList = new EventEmitter();
  @ViewChild('sourceInput') sourceInput: ElementRef<HTMLInputElement>;
  @ViewChild('source') matAutocomplete: MatAutocomplete;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() sources: SourceMaster[] = [];
  @Input() sourceCtrl = new FormControl();
  @Input() isEditable = true;
  @Input() tabindex: number;
  sourceList: SourceMaster[];
  filteredSourceList: Observable<SourceMaster[]>;

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    this.removable = this.isEditable;
    // Call the method getSourceList and get the result in a callback
    this.getSourceList(sourceList => {
      this.filteredSourceList = sourceList;
    });
  }

  /**
   *
   *
   * @param {(sourceList: Observable<SourceMaster[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete source list
   */
  getSourceList(fn: (sourceList: Observable<SourceMaster[]>) => void) {
    this.salesService.getSource().subscribe(res => {
      if (res.data && res.data.rows) {
        this.sourceList = res.data.rows;
        if (this.sources && this.sources.length > 0) {
          this.sourceList = _.difference(this.sourceList, this.sources);
        }
      } else {
        this.sourceList = [];
      }
      fn(
        (this.filteredSourceList = this.sourceCtrl.valueChanges.pipe(
          startWith(this.sourceCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(source =>
            source && source.length > 1
              ? this.filterSource(source)
              : this.sourceList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} source
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected source
   */
  public displaySelectedSource(source: SourceMaster) {
    return source ? source.source_master_name : source;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the source type based on input
   *
   */
  private filterSource(name: string) {
    return this.sourceList.filter(
      source =>
        source.source_master_name.toLowerCase().indexOf(name.toLowerCase()) >= 0
    );
  }

  add(event: MatChipInputEvent): void {
    // Add approver only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
  }

  remove(source: SourceMaster): void {
    const index = this.sources.indexOf(source);
    if (index >= 0) {
      this.sources.splice(index, 1);
      this.sourceList.push(source);
      this.filteredSourceList = of(this.sourceList);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let index = this.sources.indexOf(event.option.value);
    if (index < 0) {
      this.sources.push(event.option.value);
      let listIndex = this.sourceList.indexOf(event.option.value);
      this.sourceList.splice(listIndex, 1);
      this.sourceInput.nativeElement.value = '';
      this.sourceCtrl.setValue(null);
      this.selectedSourceList.emit(this.sources);
    }
  }
}
