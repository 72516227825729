import { Title } from '@angular/platform-browser';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation
} from '@angular/core';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError
} from '@angular/router';
import { MatSidenav } from '@angular/material';
import { MediaObserver } from '@angular/flex-layout';

import {
  AuthenticationService,
  CredentialsService,
  I18nService
} from '@app/core';
import { BranchMaster } from '@app/model/branchMaster.model';
import { EntityService } from '@app/entity/entity.service';
import { FormControl } from '@angular/forms';
import { SharedServiceService } from '@app/shared/shared-service.service';
import { LegalEntityMaster } from '@app/model/legalEntityMaster.model';
import * as _ from 'lodash';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  public flag = true;
  @Output() public selectedTab = new EventEmitter();
  public userDetails: any;
  public picUrl: string = '';
  public selectedEntity: LegalEntityMaster;
  public entityCtrl = new FormControl();
  public branchList: any[] = [];
  public entityList: any[] = [];

  constructor(
    private router: Router,
    private titleService: Title,
    private media: MediaObserver,
    private authenticationService: AuthenticationService,
    public credentialsService: CredentialsService,
    private entityService: EntityService,
    private i18nService: I18nService,
    private sharedService: SharedServiceService
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.selectedTab.emit(event);
        // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        //console.log(event.error);
      }
    });
  }

  /** Instance of sidenav. */
  @Input() sidenav: MatSidenav;

  /** Sidenav collapse event. */
  @Output() collapse = new EventEmitter<boolean>();

  /** Sets the initial state of sidenav as collapsed. Not collapsed if false. */
  sidenavCollapsed = true;

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'));
    //console.log('credentials');
    //console.log(this.userDetails);

    this.picUrl = JSON.parse(localStorage.getItem('profile_pic_url'));
    if (!this.picUrl) {
      this.picUrl = '../assets/icons/user_placeholder.png';
    }
    this.getEntityList();
    if (
      this.userDetails &&
      this.userDetails.branch &&
      this.userDetails.branch.length > 0
    ) {
      this.branchList = this.userDetails.branch;
      // if(this.branchList[0] && this.branchList[0].branch_master && this.branchList[0].branch_master.legal_entity_master) {
      //   this.selectedEntity = this.branchList[0].branch_master.legal_entity_master.legal_entity_master_name;
      // }
    } else {
      this.branchList = [];
    }
    this.sharedService.updateEntityList.subscribe(data => {
      this.getEntityList();
    });
  }

  getEntityList() {
    // Get the complete list of active entities
    this.entityService.getEntityForDropdown().subscribe(res => {
      if (res.data && res.data.rows) {
        this.entityList = res.data.rows;
        if (localStorage.hasOwnProperty('selectedEntity')) {
          let selectedEntityValue = localStorage.getItem('selectedEntity');
          if (
            selectedEntityValue !== 'undefined' &&
            selectedEntityValue !== null &&
            selectedEntityValue !== '' &&
            typeof selectedEntityValue !== 'undefined'
          ) {
            let entity = JSON.parse(localStorage.getItem('selectedEntity'));
            let selectedEntity = _.find(res.data.rows, {
              legal_entity_master_name: entity.legal_entity_master_name
            });
            if (selectedEntity) {
              this.selectedEntity = selectedEntity;
            } else {
              this.selectedEntity = res.data.rows[0];
            }
          } else {
            this.selectedEntity = res.data.rows[0];
          }
        } else {
          this.selectedEntity = res.data.rows[0];
        }
        this.entityCtrl.patchValue(this.selectedEntity);
        if (this.sharedService) {
          this.sharedService.setSelectedEntity(this.selectedEntity);
        }
        localStorage.setItem(
          'selectedEntity',
          JSON.stringify(this.selectedEntity)
        );
      }
    });
  }

  entitySelectionChangeHandler(data: any) {
    this.selectedEntity = this.entityCtrl.value;
    console.log('Selected Entity');
    console.log(this.selectedEntity);
    if (this.sharedService) {
      this.sharedService.setSelectedEntity(this.selectedEntity);
    }
    localStorage.setItem('selectedEntity', JSON.stringify(this.selectedEntity));
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  logout() {
    this.authenticationService
      .logout()
      .subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  test() {
    this.flag = !this.flag;
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  /**
   * Toggles the current collapsed state of sidenav.
   */
  toggleSidenavCollapse(sidenavCollapsed?: boolean) {
    this.sidenavCollapsed = sidenavCollapsed || !this.sidenavCollapsed;
    this.collapse.emit(this.sidenavCollapsed);
  }
}
