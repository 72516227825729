import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { VendorLoginComponent } from './vendor-login.component';

const routes: Routes = [
  {
    path: 'vendor/login',
    component: VendorLoginComponent,
    data: { title: extract('Login') }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class VendorLoginRoutingModule {}
