import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class configService {
  constructor(private http: HttpClient) {}

  /********************************** ORGANISATION : TDS TYPE *********************/

  // Fetch Tds Type
  getTdsType(limit: number, skip: number): Observable<any> {
    return this.http.get('/tdsType', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  // Add New TDS Type
  addTdsType(data: any): Observable<any> {
    return this.http.post('/tdsType', data);
  }

  // update New TDS Type
  updateTdsType(data: any): Observable<any> {
    return this.http.put('/tdsType', data);
  }

  // search TDS Type
  searchTdsType(data: any): Observable<any> {
    return this.http.post('/tdsType/search', data);
  }

  /********************************** ORGANISATION : SOURCE *********************/

  // Fetch Source
  getSource(limit: number, skip: number): Observable<any> {
    return this.http.get('/source', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  // Add New Source
  addSource(data: any): Observable<any> {
    return this.http.post('/source', data);
  }

  // Update source
  updateSource(data: any): Observable<any> {
    return this.http.put('/source', data);
  }

  // search Source
  searchSource(data: any): Observable<any> {
    return this.http.post('/source/search', data);
  }

  /********************************** ORGANISATION : STAFF *********************/

  // Fetch Staff
  getStaff(limit: number, skip: number): Observable<any> {
    return this.http.get('/staff', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  // Add New Staff
  addStaff(data: any): Observable<any> {
    return this.http.post('/staff', data);
  }

  //Update Staff
  updateStaff(staff_id: any, data: any): Observable<any> {
    return this.http.put('/staff', data);
  }

  // search Staff
  searchStaff(data: any): Observable<any> {
    return this.http.post('/staff/search', data);
  }

  /********************************** ORGANISATION : ASSET *********************/

  // Fetch Asset
  getAsset(limit: number, skip: number): Observable<any> {
    return this.http.get('/asset', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  // Add New Asset
  addAsset(data: any): Observable<any> {
    return this.http.post('/asset', data);
  }

  // Update Asset
  updateAsset(data: any): Observable<any> {
    return this.http.put('/asset', data);
  }

  // search Asset
  searchAsset(data: any): Observable<any> {
    return this.http.post('/asset/search', data);
  }

  /********************************** ORGANISATION : E COMMERCE *********************/
  getEcommerce(limit: number, skip: number): Observable<any> {
    return this.http.get('/eCommerce', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  addEcommerce(data: any): Observable<any> {
    return this.http.post('/eCommerce', data);
  }

  // Update Ecommerce
  updateEcommerce(data: any): Observable<any> {
    return this.http.put('/ecommerce', data);
  }

  // search e-commerce
  searchEcommerce(data: any): Observable<any> {
    return this.http.post('/eCommerce/search', data);
  }

  /********************************** ORGANISATION : BANK *********************/

  // Fetch Bank List
  getBank(limit: number, skip: number): Observable<any> {
    if (limit > 0 && skip >= 0) {
      return this.http.get('/bank', {
        params: { limit: limit.toString(), skip: skip.toString() }
      });
    } else {
      return this.http.get('/bank');
    }
  }

  // Add Bank
  addBank(data: any): Observable<any> {
    return this.http.post('/bank', data);
  }

  // Update Bank
  updateBank(data: any): Observable<any> {
    return this.http.put('/bank', data);
  }

  // search Bank
  searchBank(data: any): Observable<any> {
    return this.http.post('/bank/search', data);
  }

  /********************************** ORGANISATION : REVENUE SEGMENT *********************/

  // Fetch Revenue Segment List
  getRevenueSegment(limit: number, skip: number): Observable<any> {
    return this.http.get('/revenueSegment', {
      params: {
        limit: limit.toString(),
        skip: skip.toString()
      }
    });
  }

  // Add Revenue Segment
  addRevenueSegment(data: any): Observable<any> {
    return this.http.post('/revenueSegment', data);
  }

  // Update Revenue Segment
  updateRevenueSegment(data: any): Observable<any> {
    return this.http.put('/revenueSegment', data);
  }

  // search Revenue Segment
  searchRevenueSegment(data: any): Observable<any> {
    return this.http.post('/revenueSegment/search', data);
  }

  /********************************** ORGANISATION : CUSTOM TAGS *********************/
  getTag(limit: number, skip: number): Observable<any> {
    return this.http.get('/tag', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  addTag(data: any): Observable<any> {
    return this.http.post('/tag', data);
  }

  // Update Tag
  updateTag(data: any): Observable<any> {
    return this.http.put('/tag', data);
  }

  // search Tag
  searchTag(data: any): Observable<any> {
    return this.http.post('/tag/search', data);
  }

  /********************************** SERVICE : SERVICE CATEGORY *********************/
  getServiceCategory(limit: number, skip: number): Observable<any> {
    return this.http.get('/serviceCategory', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  addServiceCategory(data: any): Observable<any> {
    return this.http.post('/serviceCategory', data);
  }

  updateServiceCategory(data: any): Observable<any> {
    return this.http.put('/serviceCategory', data);
  }

  //search
  searchServiceCategory(data: any): Observable<any> {
    return this.http.post('/serviceCategory/search', data);
  }

  /********************************** SERVICE : SERVICE *********************/
  getService(limit: number, skip: number): Observable<any> {
    return this.http.get('/service', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  addService(data: any): Observable<any> {
    return this.http.post('/service', data);
  }

  updateService(data: any): Observable<any> {
    return this.http.put('/service', data);
  }

  // search
  searchService(data: any): Observable<any> {
    return this.http.post('/service/search', data);
  }

  /********************************** SERVICE : SERVICE UNIT *********************/
  getServiceUnit(limit: number, skip: number): Observable<any> {
    return this.http.get('/serviceUnit', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  addServiceUnit(data: any): Observable<any> {
    return this.http.post('/serviceUnit', data);
  }

  updateServiceUnit(data: any): Observable<any> {
    return this.http.put('/serviceUnit', data);
  }

  // search
  searchServiceUnit(data: any): Observable<any> {
    return this.http.post('/serviceUnit/search', data);
  }

  /********************************** SERVICE : SERVICE UNIT *********************/
  getServiceTag(limit: number, skip: number): Observable<any> {
    return this.http.get('/serviceTag', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  addServiceTag(data: any): Observable<any> {
    return this.http.post('/serviceTag', data);
  }

  updateServiceTag(data: any): Observable<any> {
    return this.http.put('/serviceTag', data);
  }

  //search
  searchServiceTag(data: any): Observable<any> {
    return this.http.post('/serviceTag/search', data);
  }

  /********************************** INVENTORY : MATERIAL CATEGORY *********************/
  getCategory(limit: number, skip: number): Observable<any> {
    return this.http.get('/materialCategory', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  addCategory(data: any): Observable<any> {
    return this.http.post('/materialCategory', data);
  }

  searchCategory(data: any): Observable<any> {
    return this.http.post('/materialCategory/search', data);
  }

  updateCategory(data: any): Observable<any> {
    return this.http.put('/materialCategory', data);
  }

  /********************************** INVENTORY : MATERIAL UNIT *********************/
  getUnit(limit: number, skip: number): Observable<any> {
    return this.http.get('/materialUnit', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  addUnit(data: any): Observable<any> {
    return this.http.post('/materialUnit', data);
  }

  updateUnit(data: any): Observable<any> {
    return this.http.put('/materialUnit', data);
  }

  searchMaterialUnit(data: any): Observable<any> {
    return this.http.post('/materialUnit/search', data);
  }

  /********************************** INVENTORY : MATERIAL VARIANT *********************/
  getVariant(limit: number, skip: number): Observable<any> {
    return this.http.get('/materialVariant', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  addVariant(data: any): Observable<any> {
    return this.http.post('/materialVariant', data);
  }

  updateVariant(data: any): Observable<any> {
    return this.http.put('/materialVariant', data);
  }

  searchMaterialVariant(data: any): Observable<any> {
    return this.http.post('/materialVariant/search', data);
  }

  /********************************** INVENTORY : MATERIAL VARIENT VALUE *********************/
  getVariantValue(limit: number, skip: number): Observable<any> {
    return this.http.get('/materialVariantValue', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  getAllVariantValue(): Observable<any> {
    return this.http.get('/materialVariantValue');
  }

  addVariantValue(data: any): Observable<any> {
    return this.http.post('/materialVariantValue', data);
  }

  updateVariantValue(data: any): Observable<any> {
    return this.http.put('/materialVariantValue', data);
  }

  searchMaterialVariantValue(data: any): Observable<any> {
    return this.http.post('/materialVariantValue/search', data);
  }

  /********************************** INVENTORY : MATERIAL TAG *********************/
  getMaterialTag(limit: number, skip: number): Observable<any> {
    return this.http.get('/materialTag', {
      params: { limit: limit.toString(), skip: skip.toString() }
    });
  }

  addMaterialTag(data: any): Observable<any> {
    return this.http.post('/materialTag', data);
  }

  searchMaterialTag(data: any): Observable<any> {
    return this.http.post('/materialTag/search', data);
  }

  updateMaterialTag(data: any): Observable<any> {
    return this.http.put('/materialTag', data);
  }
}
