import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

// Service
import { MasterService } from '../../master.service';

// Model
import { StaffMaster } from '../../../model/staffMaster.model';
import {
  MatAutocomplete,
  MatChipInputEvent,
  MatAutocompleteSelectedEvent
} from '@angular/material';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

// Component
@Component({
  selector: 'app-select-staff',
  templateUrl: './select-staff.component.html',
  styleUrls: ['./select-staff.component.scss']
})

// Class
export class SelectStaffComponent implements OnInit {
  @Output() selectedStaffList = new EventEmitter();
  @ViewChild('staffInput') staffInput: ElementRef<HTMLInputElement>;
  @ViewChild('staff') matAutocomplete: MatAutocomplete;
  @Input() assetCtrl = new FormControl();
  @Input() isEditable = true;
  @Input() tabindex: number;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() staffs: StaffMaster[] = [];
  @Input() staffCtrl = new FormControl();
  staffList: StaffMaster[];
  filteredStaffList: Observable<StaffMaster[]>;
  limit: number;
  skip: number;

  constructor(private masterService: MasterService) {
    this.limit = 10;
    this.skip = 0;
  }

  ngOnInit() {
    this.removable = this.isEditable;
    // Call the method getStaffList and get the result in a callback
    this.getStaffList(staffList => {
      this.filteredStaffList = staffList;
    });
  }

  /**
   * Method to get the complete staff list
   */
  getStaffList(fn: (staffList: Observable<StaffMaster[]>) => void) {
    let data = {
      limit: this.limit,
      skip: this.skip
    };
    this.masterService.getStaff(data).subscribe(res => {
      if (res.data && res.data.rows) {
        this.staffList = res.data.rows;
      } else {
        this.staffList = [];
      }
      fn(
        (this.filteredStaffList = this.staffCtrl.valueChanges.pipe(
          startWith(this.staffCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(staff =>
            staff && staff.length > 1
              ? this.filterStaff(staff)
              : this.staffList.slice()
          )
        ))
      );
    });
  }

  /**
   * Method to display only the name of the selected staff
   */
  public displaySelectedStaff(staff: StaffMaster) {
    return staff ? staff.staff_master_name : staff;
  }

  /**
   * Method to filter the staff type based on input
   */
  private filterStaff(name: string) {
    return this.staffList.filter(
      staff =>
        staff.staff_master_name.toLowerCase().indexOf(name.toLowerCase()) >= 0
    );
  }

  add(event: MatChipInputEvent): void {
    // Add approver only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
  }

  remove(asset: StaffMaster): void {
    const index = this.staffs.indexOf(asset);
    if (index >= 0) {
      this.staffs.splice(index, 1);
      this.staffList.push(asset);
      this.filteredStaffList = of(this.staffList);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let index = this.staffs.indexOf(event.option.value);
    if (index < 0) {
      this.staffs.push(event.option.value);
      let listIndex = this.staffList.indexOf(event.option.value);
      this.staffList.splice(listIndex, 1);
      this.staffInput.nativeElement.value = '';
      this.assetCtrl.setValue(null);
      this.selectedStaffList.emit(this.staffs);
    }
  }
}
