import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationService,
  CredentialsService,
  I18nService
} from '@app/core';

@Component({
  selector: 'app-vendor-sidebar',
  templateUrl: './vendor-sidebar.component.html',
  styleUrls: ['./vendor-sidebar.component.scss']
})
export class VendorSidebarComponent implements OnInit {
  /** Loop to iterate  */
  fillerNav = Array.from({ length: 5 }, (_, i) => `Nav Item ${i + 1}`);

  /** True if sidenav is in collapsed state. */
  @Input() sidenavCollapsed: boolean;

  /** Username of authenticated user. */
  username: string;

  /**
   * @param {Router} router Router for navigation.
   * @param {AuthenticationService} authenticationService Authentication Service.
   * @param {CredentialsService} credentialsService Credentials Service.
   */
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private i18nService: I18nService
  ) {}

  /**
   * Sets the username of the authenticated user.
   */
  ngOnInit() {}

  /**
   * Logs out the authenticated user and redirects to login page.
   */
  logout() {
    this.authenticationService
      .logout()
      .subscribe(() =>
        this.router.navigate(['/vendor/login'], { replaceUrl: true })
      );
  }
}
