import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  EventEmitter,
  Output
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MaterialMaster } from '../../../model/materialMaster.model';
import { SalesService } from '../../../sales/sales.service';

@Component({
  selector: 'app-select-output-material',
  templateUrl: './select-output-material.component.html',
  styleUrls: ['./select-output-material.component.scss']
})
export class SelectOutputMaterialComponent implements OnInit {
  @Input() materialMasterCtrl = new FormControl();
  @Input() isEditable = true;
  @Output() selectedMaterialMaster = new EventEmitter();
  materialMasterList: MaterialMaster[];
  filteredMaterialMasterList: Observable<MaterialMaster[]>;

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    // Call the method getAccountGroupList and get the result in a callback
    this.getOutputMaterialMasterList(materialMasterList => {
      this.filteredMaterialMasterList = materialMasterList;
    });
  }

  demoCall() {}

  /**
   *
   *
   * @param {(materialMasterList: Observable<MaterialMaster[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete MaterialMaster list
   */
  getOutputMaterialMasterList(
    fn: (accountGroupList: Observable<MaterialMaster[]>) => void
  ) {
    this.salesService.getOutputMaterialMaster().subscribe(res => {
      if (res.data && res.data.rows) {
        this.materialMasterList = res.data.rows;
      } else {
        this.materialMasterList = [];
      }
      fn(
        (this.filteredMaterialMasterList = this.materialMasterCtrl.valueChanges.pipe(
          startWith(this.materialMasterCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(materialMaster =>
            materialMaster && materialMaster.length > 1
              ? this.filterMaterialMaster(materialMaster)
              : this.materialMasterList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} materialMaster
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected materialMaster
   */
  public displaySelectedMaterialMaster(materialMaster: any) {
    return materialMaster
      ? materialMaster.material_master_name
      : materialMaster;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the MaterialMaster type based on input
   *
   */
  private filterMaterialMaster(name: string) {
    return this.materialMasterList.filter(
      materialMaster =>
        materialMaster.material_master_name
          .toLowerCase()
          .indexOf(name.toLowerCase()) >= 0
    );
  }

  public selectMaterialMasterClickHandler(materialMaster: MaterialMaster) {
    this.selectedMaterialMaster.emit(materialMaster);
  }
}
