import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import {
  Logger,
  I18nService,
  AuthenticationService,
  untilDestroyed
} from '@app/core';
import { MatSnackBar } from '@angular/material';

const log = new Logger('Login');
const credentialsKey = 'credentials';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  version: string = environment.version;
  companyName: string = environment.companyName;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;
  hide = true;
  get passwordInput() {
    return this.loginForm.get('password');
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private i18nService: I18nService,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService
  ) {
    this.createForm();
  }

  ngOnInit() {
    const storage = localStorage;
    let data = JSON.parse(storage.getItem(credentialsKey));
    if (this.loginForm && data && data.username) {
      this.loginForm.patchValue({ username: data.username });
    }
  }

  ngOnDestroy() {}

  login() {
    console.log(this.loginForm.value);
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.loginForm.value.username = this.loginForm.value.username.trim();
      const login$ = this.authenticationService.login(this.loginForm.value);
      login$
        .pipe(
          finalize(() => {
            this.loginForm.markAsPristine();
            this.isLoading = false;
          }),
          untilDestroyed(this)
        )
        .subscribe(
          credentials => {
            log.debug(`${credentials.username} successfully logged in`);
            console.log('this.route');
            console.log(this.route);
            console.log('this.route.snapshot');
            console.log(this.route.snapshot);
            console.log('this.route.snapshot.queryParams');
            console.log(this.route.snapshot.queryParams);
            console.log('this.route.snapshot.queryParams.redirect');
            console.log(this.route.snapshot.queryParams.redirect);
            this.router.navigate(
              [this.route.snapshot.queryParams.redirect || '/home'],
              { replaceUrl: true }
            );
          },
          error => {
            console.log('error');
            console.log(error);
            log.debug(`Login error: ${error}`);
            if (error.status == 504) {
              this.error =
                'Unable to reach the server. please try after sometime.';
            } else {
              this.error = error.error.message
                ? error.error.message.message
                : error.error.message;
            }
          }
        );
    } else {
      this.snackBar.open('Please Enter Valid Data.', 'OK', { duration: 2000 });
    }
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
  }
}
