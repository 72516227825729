import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

// Service
import { EntityService } from '../../../entity/entity.service';

// Model
import { LegalEntityMaster } from '../../../model/legalEntityMaster.model';

// Component
@Component({
  selector: 'app-select-entity',
  templateUrl: './select-entity.component.html',
  styleUrls: ['./select-entity.component.scss']
})

// Class
export class SelectEntityComponent implements OnInit {
  @Input() entityCtrl = new FormControl();
  @Input() isEditable = true;
  @Input() isLabelRequired: boolean = true;
  @Output() selectedEntity = new EventEmitter();
  entityList: LegalEntityMaster[];
  filteredEntityList: Observable<LegalEntityMaster[]>;

  constructor(private entityService: EntityService) {}

  ngOnInit() {
    this.getEntityList(entityList => {
      this.filteredEntityList = entityList;
    });
  }

  /**
   * Entity List for dropdown
   */
  getEntityList(fn: (entityList: Observable<LegalEntityMaster[]>) => void) {
    this.entityService.getEntityForDropdown().subscribe(res => {
      if (res.data && res.data.rows) {
        this.entityList = res.data.rows;
      } else {
        this.entityList = [];
      }
      fn(
        (this.filteredEntityList = this.entityCtrl.valueChanges.pipe(
          startWith(this.entityCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(entity =>
            entity && entity.length > 0
              ? this.filterEntity(entity)
              : this.entityList.slice()
          )
        ))
      );
    });
  }

  /**
   * Display the selected entity
   */
  public displaySelectedEntity(entity: LegalEntityMaster) {
    return entity ? entity.legal_entity_master_name : entity;
  }

  /**
   * Filter entity in auto complete
   */
  private filterEntity(entity_name: string) {
    return this.entityList.filter(
      entity =>
        entity.legal_entity_master_name
          .toLowerCase()
          .indexOf(entity_name.toLowerCase()) >= 0
    );
  }

  /**
   * Select the entity on click
   */
  public selectEntityClickHandler(entity: LegalEntityMaster) {
    this.selectedEntity.emit(entity);
  }
}
