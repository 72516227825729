import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-master',
  templateUrl: './material-master.component.html',
  styleUrls: ['./material-master.component.scss']
})
export class MaterialMasterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
