import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SalesService } from '../../../sales/sales.service';
import { AssetMaster } from '../../../model/assetMaster.model';
import {
  MatChipInputEvent,
  MatAutocompleteSelectedEvent,
  MatAutocomplete
} from '@angular/material';
import { UserMaster } from '@app/model/userMaster.model';

@Component({
  selector: 'app-select-asset',
  templateUrl: './select-asset.component.html',
  styleUrls: ['./select-asset.component.scss']
})
export class SelectAssetComponent implements OnInit {
  @Output() selectedAssetList = new EventEmitter();
  @ViewChild('assetInput') assetInput: ElementRef<HTMLInputElement>;
  @ViewChild('asset') matAutocomplete: MatAutocomplete;
  @Input() assetCtrl = new FormControl();
  @Input() isEditable = true;
  @Input() assets: AssetMaster[] = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  assetList: AssetMaster[];
  filteredAssetList: Observable<AssetMaster[]>;

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    this.removable = this.isEditable;
    // Call the method getAssetList and get the result in a callback
    this.getAssetList(assetList => {
      this.filteredAssetList = assetList;
    });
  }

  /**
   *
   *
   * @param {(revenueSegmentList: Observable<RevenueSegment[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete source list
   */
  getAssetList(fn: (assetList: Observable<AssetMaster[]>) => void) {
    this.salesService.getAsset().subscribe(res => {
      if (res.data && res.data.rows) {
        this.assetList = res.data.rows;
      } else {
        this.assetList = [];
      }
      fn(
        (this.filteredAssetList = this.assetCtrl.valueChanges.pipe(
          startWith(this.assetCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(asset =>
            asset && asset.length > 1
              ? this.filterAsset(asset)
              : this.assetList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} source
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected Asset
   */
  public displaySelectedAsset(asset: AssetMaster) {
    return asset ? asset.asset_master_name : asset;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the Asset type based on input
   *
   */
  private filterAsset(name: string) {
    return this.assetList.filter(
      asset =>
        asset.asset_master_name.toLowerCase().indexOf(name.toLowerCase()) >= 0
    );
  }

  add(event: MatChipInputEvent): void {
    // Add approver only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
  }

  remove(asset: AssetMaster): void {
    const index = this.assets.indexOf(asset);
    if (index >= 0) {
      this.assets.splice(index, 1);
      this.assetList.push(asset);
      this.filteredAssetList = of(this.assetList);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let index = this.assets.indexOf(event.option.value);
    if (index < 0) {
      this.assets.push(event.option.value);
      let listIndex = this.assetList.indexOf(event.option.value);
      this.assetList.splice(listIndex, 1);
      this.assetInput.nativeElement.value = '';
      this.assetCtrl.setValue(null);
      this.selectedAssetList.emit(this.assets);
    }
  }
}
