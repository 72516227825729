import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ViewChild
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
  PatternValidator
} from '@angular/forms';

// Service
import { MasterService } from '../../master.service';
import { MatTabGroup, MatDialog } from '@angular/material';
import { ViewBranchComponent } from '../view-branch/view-branch.component';
import { ConfirmationDialogComponent } from '@app/shared/confirmation-dialog/confirmation-dialog.component';
import { Country } from '@app/model/country.model';
import { SharedServiceService } from '@app/shared/shared-service.service';

// Component
@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html',
  styleUrls: ['./add-branch.component.scss'],
  encapsulation: ViewEncapsulation.None
})

// Class
export class AddBranchComponent implements OnInit {
  public addBranchForm: FormGroup;
  public selectedCountryId: number;
  @Input() matTabGroup: MatTabGroup;
  @Input() viewBranchComponent: ViewBranchComponent;
  @ViewChild('addBranchFormObj') addBranchFormObj: NgForm;
  // Constructor
  constructor(
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public sharedService: SharedServiceService
  ) {}

  // Init method
  ngOnInit() {
    this.createAddBranchForm();
  }

  // method for add branch form fields & validations
  createAddBranchForm() {
    this.addBranchForm = this.formBuilder.group({
      name: ['', Validators.required],
      gst: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9a-zA-Z]){1}$'
          )
        ]
      ],
      entity: ['', [Validators.required, this.sharedService.objectValidator]],
      country: ['', [Validators.required, this.sharedService.objectValidator]],
      state: ['', [Validators.required, this.sharedService.objectValidator]],
      pcontactname: ['', Validators.required],
      pcontactno: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10}$')]
      ],
      pincode: [null],
      address: [''],
      acontactname: [''],
      acontactno: ['', Validators.pattern('^[0-9]{10}$')],
      remarks: ['', Validators.required]
    });
  }

  // method to add a new branch
  addBranch() {
    let credentials = JSON.parse(localStorage.getItem('credentials'));
    if (this.addBranchForm.valid) {
      const confirmationDialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        {
          width: '400px'
          // data : {name: "TEST", message: "Are you sure to submit add vendor ?"}
        }
      );
      confirmationDialogRef.disableClose = true;
      confirmationDialogRef.componentInstance.dialogRef = confirmationDialogRef;
      confirmationDialogRef.afterClosed().subscribe(result => {
        if (confirmationDialogRef.componentInstance.confirmation) {
          let data = {
            branch_master_name: this.addBranchForm.value.name,
            legal_entity_master_id: this.addBranchForm.value.entity
              .legal_entity_master_id,
            gst_no: this.addBranchForm.value.gst,
            country_id: this.addBranchForm.value.country.country_id,
            state_id: this.addBranchForm.value.state.state_id,
            primary_contact_name: this.addBranchForm.value.pcontactname,
            primary_contact_no: this.addBranchForm.value.pcontactno,
            alternate_contact_name: this.addBranchForm.value.acontactname,
            alternate_contact_no: this.addBranchForm.value.acontactno,
            address: this.addBranchForm.value.address,
            // ' - ' +
            pincode: this.addBranchForm.value.pincode,
            remarks: this.addBranchForm.value.remarks,
            user: credentials.id
          };
          this.masterService.addBranch(data).subscribe(
            (res: any) => {
              // this.addBranchForm.reset();
              this.viewBranchComponent.getBranch();
              this.addBranchFormObj.resetForm();
              setTimeout(() => {
                this.matTabGroup.selectedIndex = 0;
              }, 1000);
              this.snackBar.open('New Branch Added Successfully', 'OK', {
                duration: 2000
              });
            },
            (error: any) => {
              if (error.error.error.message) {
                this.snackBar.open(error.error.error.message, 'OK', {
                  duration: 2000
                });
              } else {
                this.snackBar.open(
                  'Something went wrong. Please try again.',
                  'OK',
                  { duration: 2000 }
                );
              }
            }
          );
        }
      });
    } else {
      this.snackBar.open('Please Enter Valid Data', 'OK', { duration: 2000 });
    }
  }

  cancelHandler() {
    this.addBranchFormObj.resetForm();
    this.matTabGroup.selectedIndex = 0;
  }

  selectedCountryClickHandler(data: Country) {
    this.addBranchForm.patchValue({
      state: ''
    });
    this.selectedCountryId = data.country_id;
  }
}
