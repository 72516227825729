import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
import { environment } from '@env/environment';

@Pipe({
  name: 'dateWithZoneOffset'
})
export class TimeOffsetPipe extends DatePipe implements PipeTransform {
  public dateFormat: string = environment.dateFormat;
  public timeOffset: number = moment().utcOffset();
  transform(
    value: string | Date,
    format: string = environment.dateFormat,
    timezone: string = moment.tz.guess()
  ): string {
    const timezoneOffset = moment()
      .tz(timezone)
      .format('Z');
    let date = new Date(value);
    date.setMinutes(date.getMinutes() + this.timeOffset);
    return super.transform(date, format, timezoneOffset);
  }
}
