import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(private http: HttpClient) {}

  // Widget - Expenses
  getWidgetExpenses(data: any): Observable<any> {
    return this.http.get('/widget/expenses', {
      params: data
    });
  }

  // Widget - Top Payable Vendors
  getWidgetTopPayableVendors(data: any): Observable<any> {
    return this.http.get('/widget/payableVendors', {
      params: data
    });
  }

  // Widget - Top Advances Pending
  getWidgetTopAdvancesPending(data: any): Observable<any> {
    return this.http.get('/widget/advancesPending', {
      params: data
    });
  }

  // Widget - Expenses Account-wise
  getWidgetExpenseHeadwise(data: any): Observable<any> {
    return this.http.get('/widget/expenseHeadwise', {
      params: data
    });
  }

  // Widget - Expenses Branch-wise
  getWidgetExpenseBranchwise(data: any): Observable<any> {
    return this.http.get('/widget/expenseBranchwise', {
      params: data
    });
  }

  // Widget - Vendor Balances
  getWidgetVendorBalances(data: any): Observable<any> {
    return this.http.get('/widget/vendorBalances', {
      params: data
    });
  }

  // Widget - Total Project Expense
  getTotalProjectExpenses(data: any): Observable<any> {
    return this.http.get('/widget/totalProjectExpenses', {
      params: data
    });
  }

  getTotalProjectExpensesVsBudget(data: any): Observable<any> {
    return this.http.get('/widget/projectLifetimeBudget', {
      params: data
    });
  }

  // DashBoard -  VendorStatusCount
  getAllVendorStatusCount(data: any): Observable<any> {
    return this.http.get('/vendor/vendorStatusCount', {
      params: data
    });
  }

  // DashBoard -  PurchaseOrderStatusCount
  getAllPurchaseOrderStatusCount(data: any): Observable<any> {
    return this.http.get('/purchaseOrder/purchaseOrderStatusCount', {
      params: data
    });
  }

  // DashBoard -  BillStatusCount
  getAllBillStatusCount(data: any): Observable<any> {
    return this.http.get('/bill/billStatusCount', {
      params: data
    });
  }
}
