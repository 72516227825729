import { Injectable } from '@angular/core';
import { LegalEntityMaster } from '@app/model/legalEntityMaster.model';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import objOf from 'ramda/es/objOf';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  public selectedEntity: LegalEntityMaster;
  public entitySelector$: Subject<LegalEntityMaster> = new Subject<
    LegalEntityMaster
  >();

  public updateEntityList: Subject<any> = new Subject<any>();
  public timeOffset: number = moment().utcOffset();
  constructor() {
    if (localStorage.hasOwnProperty('selectedEntity')) {
      let selectedEntityValue = localStorage.getItem('selectedEntity');
      if (
        selectedEntityValue !== 'undefined' &&
        selectedEntityValue !== null &&
        selectedEntityValue !== '' &&
        typeof selectedEntityValue !== 'undefined'
      ) {
        this.selectedEntity = JSON.parse(
          localStorage.getItem('selectedEntity')
        );
      }
    }
  }

  public broadCastUpdateEntityList() {
    this.updateEntityList.next();
  }

  public setSelectedEntity(selectedEntity: LegalEntityMaster) {
    this.entitySelector$.next(selectedEntity);
  }

  handleEnterKeyPress(event: { target: { tagName: string } }) {
    const tagName = event.target.tagName.toLowerCase();
    if (tagName !== 'textarea') {
      return false;
    }
  }

  convertToLocalDate(date: string | Date) {
    date = new Date(date);
    date.setMinutes(date.getMinutes() + this.timeOffset);
    return date;
  }

  validateDate(date: string | Date) {
    return date;
  }

  convertFromDate(date: string | Date) {
    if (date) {
      var convertedFromDate = new Date(this.validateDate(date));
      convertedFromDate.setHours(0, 0, 0);
      return convertedFromDate.toISOString();
    } else {
      return '';
    }
  }

  convertTODate(date: string | Date) {
    if (date) {
      var convertedToDate = new Date(this.validateDate(date));
      convertedToDate.setHours(23, 59, 59);
      return convertedToDate.toISOString();
    } else {
      return '';
    }
  }

  convertNumberToTwoDecimalPlace(data: number){
    if(data){
      return Number(data.toFixed(2));
    } else {
      return 0;
    }
  }

  convertForm(date: string | Date) {
    if (date) {
      var convertedForm = new Date(this.validateDate(date));
      return convertedForm.toISOString();
    } else {
      return '';
    }
  }

  getFinicalYear() {
    // get current date
    const today = new Date();

    // get current month
    const curMonth = today.getMonth();

    let fiscalYr = '';
    if (curMonth > 3) {
      //
      const nextYr1 = (today.getFullYear() + 1).toString();
      return (fiscalYr =
        today.getFullYear().toString() +
        '-' +
        nextYr1.charAt(2) +
        nextYr1.charAt(3));
    } else {
      const nextYr2 = today.getFullYear().toString();
      return (fiscalYr =
        (today.getFullYear() - 1).toString() +
        '-' +
        nextYr2.charAt(2) +
        nextYr2.charAt(3));
    }
  }

  objectValidator(control: AbstractControl): { [key: string]: any } | null {
    if (typeof control.value == 'object' || control.value == '') {
      return null;
    } else {
      return { invalidObject: { valid: false, value: control.value } };
    }
  }


}

export interface AllValidationErrors {
  control_name: string;
  error_name: string;
  error_value: any;
  message: string;
}

export interface FormGroupControls {
  [key: string]: AbstractControl;
}

export function getFormValidationErrors(
  controls: FormGroupControls
): AllValidationErrors[] {
  let errors: AllValidationErrors[] = [];
  Object.keys(controls).forEach(key => {
    const control = controls[key];
    if (control instanceof FormGroup) {
      errors = errors.concat(getFormValidationErrors(control.controls));
    }
    const controlErrors: ValidationErrors = controls[key].errors;
    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach(keyError => {
        let text;
        switch (keyError) {
          case 'required':
            text = `${key} is required!`;
            break;
          case 'pattern':
            text = `${key} has wrong pattern!`;
            break;
          case 'email':
            text = `${key} has wrong email format!`;
            break;
          case 'minlength':
            text = `${key} has wrong length! Required length: ${controlErrors[keyError].requiredLength}`;
            break;
          case 'areEqual':
            text = `${key} must be equal!`;
            break;
          default:
            text = `${key}: ${keyError}: ${controlErrors[keyError]}`;
        }
        errors.push({
          control_name: key,
          error_name: keyError,
          error_value: controlErrors[keyError],
          message: text
        });
      });
    }
  });
  return errors;
}
