import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { SalesService } from '../../../sales/sales.service';
import { ProjectMaster } from '../../../model/projectMaster.model';
import { LegalEntityMaster } from '@app/model/legalEntityMaster.model';
import { SharedServiceService } from '@app/shared/shared-service.service';

@Component({
  selector: 'app-select-project',
  templateUrl: './select-project.component.html',
  styleUrls: ['./select-project.component.scss']
})
export class SelectProjectComponent implements OnInit {
  @Input() projectCtrl = new FormControl();
  @Input() isEditable = true;
  @Output() selectedProject = new EventEmitter();
  @Input() isLabelRequired = true;
  @Input() branchId = 0;
  @Input() tabindex: number;
  projectList: ProjectMaster[];
  filteredProjectList: Observable<ProjectMaster[]>;
  public selectedEntity: LegalEntityMaster;
  public selectedEntityId: any;
  public subscribers: any = {};
  constructor(
    private salesService: SalesService,
    private sharedService: SharedServiceService
  ) {}

  ngOnInit() {
    if (localStorage.hasOwnProperty('selectedEntity')) {
      let selectedEntityValue = localStorage.getItem('selectedEntity');
      if (
        selectedEntityValue !== 'undefined' &&
        selectedEntityValue !== null &&
        selectedEntityValue !== '' &&
        typeof selectedEntityValue !== 'undefined'
      ) {
        this.selectedEntity = JSON.parse(
          localStorage.getItem('selectedEntity')
        );
        this.selectedEntityId = this.selectedEntity.legal_entity_master_id;
      }
    }

    // Call the method ProjectList and get the result in a callback
    if (this.selectedEntityId) {
      this.getProjectByEntityId(projectList => {
        this.filteredProjectList = projectList;
      });
    } else {
      this.getProjectList(projectList => {
        this.filteredProjectList = projectList;
      });
    }
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.branchId) {
  //     this.branchId = changes.branchId.currentValue;
  //     if (this.branchId) {
  //       this.getProjectsByBranchId(this.branchId, projectList => {
  //         this.filteredProjectList = projectList;
  //       });
  //     }
  //   }
  // }

  ngAfterViewInit() {
    //call my function on entity changes
    this.subscribers.entitySelectorBranch = this.sharedService.entitySelector$.subscribe(
      entity => {
        if (
          this.selectedEntityId &&
          entity &&
          this.selectedEntityId != entity.legal_entity_master_id
        ) {
          this.projectCtrl.patchValue(null);
          this.selectedEntityId = entity ? entity.legal_entity_master_id : '';
          this.getProjectByEntityId(projectList => {
            this.filteredProjectList = projectList;
          });
        }
      }
    );
  }

  /**
   *
   *
   * @param {(projectList: Observable<Project[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete project list
   */
  getProjectList(fn: (projectList: Observable<ProjectMaster[]>) => void) {
    this.salesService.getProject().subscribe(res => {
      if (res.data && res.data.rows) {
        this.projectList = res.data.rows;
      } else {
        this.projectList = [];
      }
      fn(
        (this.filteredProjectList = this.projectCtrl.valueChanges.pipe(
          startWith(this.projectCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(project =>
            project && project.length > 0
              ? this.filterProject(project)
              : this.projectList.slice()
          )
        ))
      );
    });
  }

  // get Project by branch Id
  getProjectsByBranchId(
    id: number,
    fn: (projectList: Observable<ProjectMaster[]>) => void
  ) {
    this.salesService.getProjectByBranchId(id).subscribe(res => {
      if (res.data && res.data.rows && res.data.rows.length > 0) {
        this.projectList = res.data.rows;
      } else {
        this.projectList = [];
      }
      fn(
        (this.filteredProjectList = this.projectCtrl.valueChanges.pipe(
          startWith(this.projectCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(project =>
            project && project.length > 0
              ? this.filterProject(project)
              : this.projectList.slice()
          )
        ))
      );
    });
  }

  // get project by Entity Id
  getProjectByEntityId(fn: (projectList: Observable<ProjectMaster[]>) => void) {
    this.salesService
      .getProjectByEntityId(this.selectedEntityId)
      .subscribe(res => {
        if (res.data && res.data.rows) {
          this.projectList = res.data.rows;
        } else {
          this.projectList = [];
        }
        fn(
          (this.filteredProjectList = this.projectCtrl.valueChanges.pipe(
            startWith(this.projectCtrl.value),
            map(value => (typeof value === 'string' ? value : value)),
            map(project =>
              project && project.length > 0
                ? this.filterProject(project)
                : this.projectList.slice()
            )
          ))
        );
      });
  }

  /**
   *
   *
   * @param {*} project
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected project
   */
  public displaySelectedProject(project: ProjectMaster) {
    return project ? project.project_master_name : project;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the project based on input
   *
   */
  private filterProject(name: string) {
    return this.projectList.filter(
      project =>
        project.project_master_name.toLowerCase().indexOf(name.toLowerCase()) >=
        0
    );
  }

  selectProjectClickHandler(project: ProjectMaster) {
    this.selectedProject.emit(project);
  }
}
