import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material.module';

import { MaterialMasterRoutingModule } from './material-master-routing.module';
import { MaterialMasterComponent } from './material-master.component';

@NgModule({
  declarations: [MaterialMasterComponent],
  exports: [MaterialMasterComponent],
  imports: [CommonModule, MaterialMasterRoutingModule, MaterialModule]
})
export class MaterialMasterModule {}
