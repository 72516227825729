import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  EventEmitter,
  Output
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { AccountMaster } from '../../../model/accountMaster.model';
import { MasterService } from '../../master.service';

@Component({
  selector: 'app-select-account-master',
  templateUrl: './select-account-master.component.html',
  styleUrls: ['./select-account-master.component.scss']
})
export class SelectAccountMasterComponent implements OnInit {
  @Input() accountMasterCtrl = new FormControl();
  @Output() selectedAccountMaster = new EventEmitter();
  @Input() isLabelRequired: boolean = true;
  @Input() isEditable = true;
  @Input() tabindex: number;
  accountMasterList: AccountMaster[];
  filteredAccountMasterList: Observable<AccountMaster[]>;

  constructor(private masterService: MasterService) {}

  ngOnInit() {
    // Call the method getAccountMasterList and get the result in a callback
    this.getAccountMasterList(accountMasterList => {
      this.filteredAccountMasterList = accountMasterList;
    });
  }

  /**
   *
   *
   * @param {(branchList: Observable<AccountMaster[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete AccountMaster list
   */
  getAccountMasterList(
    fn: (accountMasterList: Observable<AccountMaster[]>) => void
  ) {
    this.masterService.getAccountMaster().subscribe(res => {
      if (res.data && res.data.rows) {
        this.accountMasterList = res.data.rows;
      } else {
        this.accountMasterList = [];
      }
      if (this.accountMasterCtrl) {
        fn(
          (this.filteredAccountMasterList = this.accountMasterCtrl.valueChanges.pipe(
            startWith(this.accountMasterCtrl.value),
            map(value => (typeof value === 'string' ? value : value)),
            map(accountMaster =>
              accountMaster && accountMaster.length > 1
                ? this.filterAccountMaster(accountMaster)
                : this.accountMasterList.slice()
            )
          ))
        );
      }
    });
  }

  /**
   *
   *
   * @param {*} accountMaster
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected accountMaster
   */
  public displaySelectedAccountMaster(accountMaster: any) {
    return accountMaster ? accountMaster.account_master_name : accountMaster;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the AccountMaster type based on input
   *
   */
  private filterAccountMaster(name: string) {
    return this.accountMasterList.filter(
      accountMaster =>
        accountMaster.account_master_name
          .toLowerCase()
          .indexOf(name.toLowerCase()) >= 0
    );
  }

  public selectAccountMasterClickHandler(accountMaster: AccountMaster) {
    this.selectedAccountMaster.emit(accountMaster);
  }
}
