import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MatTableDataSource,
  MatChipInputEvent,
  MatTable,
  MatAutocompleteSelectedEvent,
  MatAutocomplete
} from '@angular/material';

import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { SalesService } from '../../../sales/sales.service';
import {
  UserMaster as Approver,
  UserMaster
} from '../../../model/userMaster.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-select-approver',
  templateUrl: './select-approver.component.html',
  styleUrls: ['./select-approver.component.scss']
})
export class SelectApproverComponent implements OnInit {
  @Input() approverCtrl = new FormControl();
  @Output() selectedApproverList = new EventEmitter();
  @ViewChild('approverInput') approverInput: ElementRef<HTMLInputElement>;
  @ViewChild('approver') matAutocomplete: MatAutocomplete;
  @Input() isEditable = true;
  @Input() approvers: Approver[] = [];
  @Input() approverUserStatusList: any[] = [];
  @Input() approverUserStatus: string = '';
  @Input() tabindex: number;
  approverList: Approver[];
  public user: UserMaster;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredApproverList: Observable<Approver[]>;
  // approvers: Approver[] = [];
  allApprover: Approver[] = [];

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('loggedInUserDetails'));
    this.removable = this.isEditable;
    // Call the method getApproverList and get the result in a callback
    this.getApproverList(approverList => {
      this.filteredApproverList = approverList;
    });
  }

  /**
   *
   *
   * @param {(approverList: Observable<Approver[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete approver list
   */
  getApproverList(fn: (approverList: Observable<Approver[]>) => void) {
    this.salesService.getApprovers().subscribe(res => {
      if (res.data && res.data.length > 0) {
        this.approverList = res.data;
      } else {
        this.approverList = [];
      }
      fn(
        (this.filteredApproverList = this.approverCtrl.valueChanges.pipe(
          startWith(this.approverCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(cessType =>
            cessType && cessType.length > 0
              ? this.filterApprover(cessType)
              : this.approverList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} approver
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected approver
   */
  public displaySelectedApprover(approver: Approver) {
    return approver ? approver.first_name : approver;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the approver type based on input
   *
   */
  private filterApprover(name: string) {
    return this.approverList.filter(
      approver =>
        approver.first_name.toLowerCase().indexOf(name.toLowerCase()) >= 0
    );
  }

  add(event: MatChipInputEvent): void {
    // Add approver only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
  }

  remove(approver: UserMaster): void {
    const index = this.approvers.indexOf(approver);
    if (index >= 0) {
      this.approvers.splice(index, 1);
      this.approverList.push(approver);
      this.filteredApproverList = of(this.approverList);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let index = this.approvers.indexOf(event.option.value);
    if (index < 0) {
      this.approvers.push(event.option.value);
      let listIndex = this.approverList.indexOf(event.option.value);
      this.approverList.splice(listIndex, 1);
      this.approverInput.nativeElement.value = '';
      this.approverCtrl.setValue(null);
      this.selectedApproverList.emit(this.approvers);
    }
  }

  checkPendingUser(approver: Approver) {
    if (this.approverUserStatusList && this.approverUserStatusList.length > 0) {
      let approverUser = _.find(this.approverUserStatusList, {
        user_id: approver.user_master_id
      });
      if (approverUser && approverUser.approval_status === 'Pending') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkApprovedUser(approver: Approver) {
    if (this.approverUserStatusList && this.approverUserStatusList.length > 0) {
      let approverUser = _.find(this.approverUserStatusList, {
        user_id: approver.user_master_id
      });
      if (approverUser && approverUser.approval_status === 'Approved') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
