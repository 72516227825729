import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MasterService } from '@app/master/master.service';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { ViewBranchComponent } from '../view-branch/view-branch.component';
import { ConfirmationDialogComponent } from '@app/shared/confirmation-dialog/confirmation-dialog.component';
import { Country } from '@app/model/country.model';
import { SharedServiceService } from '@app/shared/shared-service.service';

// Component
@Component({
  selector: 'app-edit-branch',
  templateUrl: './edit-branch.component.html',
  styleUrls: ['./edit-branch.component.scss']
})

// Class
export class EditBranchComponent implements OnInit {
  public editBranchForm: FormGroup;
  public editFormData: any;
  public selectedCountryId: number;
  public viewBranchComponent: ViewBranchComponent;

  // Constructor
  constructor(
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public editDialogRef: MatDialogRef<EditBranchComponent>,
    public sharedService: SharedServiceService
  ) {}

  // Init method
  ngOnInit() {
    this.createBranchForm();
    this.showBranchDetails();
  }

  // method for add branch form fields & validations
  createBranchForm() {
    this.editBranchForm = this.formBuilder.group({
      name: ['', Validators.required],
      gst: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9a-zA-Z]){1}$'
          )
        ]
      ],
      entity: ['', [Validators.required, this.sharedService.objectValidator]],
      country: ['', [Validators.required, this.sharedService.objectValidator]],
      state: ['', [Validators.required, this.sharedService.objectValidator]],
      primaryContactName: ['', Validators.required],
      primaryContactNo: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10}$')]
      ],
      pincode: [''],
      address: [''],
      alternameContactname: [''],
      alternateContactNo: ['', Validators.pattern('^[0-9]{10}$')],
      remarks: ['']
    });
  }

  // Method to prefill the edit branch form details
  showBranchDetails() {
    this.editBranchForm.patchValue({
      name: this.editFormData.branch_master_name,
      gst: this.editFormData.gst_master.gst_no,
      entity: this.editFormData.legal_entity_master,
      country: this.editFormData.country,
      state: this.editFormData.state,
      primaryContactName: this.editFormData.primary_contact_name,
      primaryContactNo: this.editFormData.primary_contact_no,
      pincode: this.editFormData.pincode,
      address: this.editFormData.address,
      alternameContactname: this.editFormData.alternate_contact_name,
      alternateContactNo: this.editFormData.alternate_contact_no,
      remarks: this.editFormData.remarks
    });
  }

  // Update the branch details
  updateBranch() {
    let credentials = JSON.parse(localStorage.getItem('credentials'));
    if (this.editBranchForm.valid) {
      const confirmationDialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        {
          width: '400px'
          // data : {name: "TEST", message: "Are you sure to submit add vendor ?"}
        }
      );
      confirmationDialogRef.disableClose = true;
      confirmationDialogRef.componentInstance.dialogRef = confirmationDialogRef;
      confirmationDialogRef.afterClosed().subscribe(result => {
        if (confirmationDialogRef.componentInstance.confirmation) {
          let data = {
            branch_master_name: this.editBranchForm.value.name,
            branch_master_id: this.editFormData.branch_master_id,
            legal_entity_master_id: this.editBranchForm.value.entity
              ? this.editBranchForm.value.entity.legal_entity_master_id
              : this.editFormData.legal_entity_master_id,
            gst_no: this.editBranchForm.value.gst,
            primary_contact_name: this.editBranchForm.value.primaryContactName,
            primary_contact_no: this.editBranchForm.value.primaryContactNo,
            alternate_contact_name: this.editBranchForm.value
              .alternameContactname,
            alternate_contact_no: this.editBranchForm.value.alternateContactNo,
            country_id: this.editBranchForm.value.country.country_id,
            state_id: this.editBranchForm.value.state.state_id,
            address: this.editBranchForm.value.address, //+
            // ' - ' +
            pincode: this.editBranchForm.value.pincode,
            remarks: this.editBranchForm.value.remarks,
            user: credentials.id
          };
          this.masterService.updateBranch(data).subscribe(
            (res: any) => {
              this.snackBar.open('Branch Detais Updated Successfully', 'OK', {
                duration: 2000
              });
              this.viewBranchComponent.getBranch();
              this.editDialogRef.close();
            },
            (error: any) => {
              if (error.error.error.message) {
                this.snackBar.open(error.error.error.message, 'OK', {
                  duration: 2000
                });
              } else {
                this.snackBar.open(
                  'Something went wrong. Please try again.',
                  'OK',
                  { duration: 2000 }
                );
              }
            }
          );
        }
      });
    } else {
      this.snackBar.open('Please Enter Valid Data', 'OK', { duration: 2000 });
    }
  }

  cancelModal() {
    this.editDialogRef.close();
  }

  selectedCountryClickHandler(data: Country) {
    this.editBranchForm.patchValue({
      state: ''
    });
    this.selectedCountryId = data.country_id;
  }
}
