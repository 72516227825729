import {
  Component,
  OnInit,
  Input,
  Output,
  ViewEncapsulation,
  EventEmitter
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { SalesService } from '../../../sales/sales.service';
import { MasterService } from '../../../master/master.service';
import { CustomerType } from '../../../model/customerType.model';

@Component({
  selector: 'app-select-customer-type',
  templateUrl: './select-customer-type.component.html',
  styleUrls: ['./select-customer-type.component.scss']
})
export class SelectCustomerTypeComponent implements OnInit {
  @Input() customerTypeCtrl = new FormControl();
  @Input() isEditable: boolean = true;
  @Input() tabindex: number;
  customerTypeList: CustomerType[];
  filteredCustomerTypeList: Observable<CustomerType[]>;
  @Output() public selectedCustomerType = new EventEmitter();

  constructor(
    private salesService: SalesService,
    private masterService: MasterService
  ) {}

  ngOnInit() {
    // Call the method getCustomerTypeList and get the result in a callback
    this.getCustomerList(customerTypeList => {
      this.filteredCustomerTypeList = customerTypeList;
    });
  }

  /**
   *
   *
   * @param {(customerTypeList: Observable<CustomerType[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete customer type list
   */
  getCustomerList(fn: (customerList: Observable<CustomerType[]>) => void) {
    this.masterService.getCustomerType().subscribe(res => {
      this.customerTypeList = res.data;
      fn(
        (this.filteredCustomerTypeList = this.customerTypeCtrl.valueChanges.pipe(
          startWith(this.customerTypeCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(customerType =>
            customerType && customerType.length > 0
              ? this.filterCustomer(customerType)
              : this.customerTypeList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} customerType
   * @returns
   * @memberof AddCutomerTypeComponent
   * Method to display only the name of the selected customer type
   */
  public displaySelectedCustomerType(customerType: CustomerType) {
    return customerType ? customerType.customer_type : customerType;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the customer based on input
   *
   */
  private filterCustomer(name: string) {
    return this.customerTypeList.filter(
      customerType =>
        customerType.customer_type.toLowerCase().indexOf(name.toLowerCase()) >=
        0
    );
  }

  selectedCustomerTypeClickHandler(customerType: CustomerType) {
    this.selectedCustomerType.emit(customerType);
  }
}
