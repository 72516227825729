import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { VendorShellComponent } from './vendor-shell.component';
import { VendorHeaderComponent } from './vendor-header/vendor-header.component';
import { VendorSidebarComponent } from './vendor-sidebar/vendor-sidebar.component';
import { VendorBreadcrumbComponent } from './vendor-breadcrumb/vendor-breadcrumb.component';
import { VendorSubHeaderComponent } from './vendor-sub-header/vendor-sub-header.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule,
    FlexLayoutModule,
    FontAwesomeModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    VendorShellComponent,
    VendorHeaderComponent,
    VendorSidebarComponent,
    VendorBreadcrumbComponent,
    VendorSubHeaderComponent
  ]
})
export class VendorShellModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(fas);
  }
}
