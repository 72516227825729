import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { SalesService } from '../../../sales/sales.service';
import { TaxType } from '../../../model/taxType.model';

@Component({
  selector: 'app-select-tax-type',
  templateUrl: './select-tax-type.component.html',
  styleUrls: ['./select-tax-type.component.scss']
})
export class SelectTaxTypeComponent implements OnInit {
  @Input() taxTypeCtrl = new FormControl();
  @Input() isEditable = true;
  @Input() isLabelRequired: boolean = true;
  @Output() taxTypeSelected = new EventEmitter();
  taxTypeList: TaxType[];
  filteredTaxTypeList: Observable<TaxType[]>;

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    // Call the method getTaxTypeList and get the result in a callback
    this.getTaxTypeList(taxTypeList => {
      this.filteredTaxTypeList = taxTypeList;
    });
  }

  /**
   *
   *
   * @param {(taxTypeList: Observable<TaxType[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete taxType list
   */
  getTaxTypeList(fn: (taxTypeList: Observable<TaxType[]>) => void) {
    this.salesService.getTaxType().subscribe(res => {
      if (res.data && res.data.rows) {
        this.taxTypeList = res.data.rows;
      } else {
        this.taxTypeList = [];
      }
      fn(
        (this.filteredTaxTypeList = this.taxTypeCtrl.valueChanges.pipe(
          startWith(this.taxTypeCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(taxType =>
            taxType && taxType.length > 0
              ? this.filterTaxType(taxType)
              : this.taxTypeList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} taxType
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected taxType
   */
  public displaySelectedTaxType(taxType: TaxType) {
    return taxType ? taxType.tax_type_name : taxType;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the taxType based on input
   *
   */
  private filterTaxType(name: string) {
    return this.taxTypeList.filter(
      taxType =>
        taxType.tax_type_name.toLowerCase().indexOf(name.toLowerCase()) >= 0
    );
  }

  public selectTaxTypeClickHandler(taxType: TaxType) {
    this.taxTypeSelected.emit(taxType);
  }
}
