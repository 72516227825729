import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  EventEmitter,
  Output
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { AccountGroupMaster } from '../../../model/accountGroupMaster.model';
import { SalesService } from '../../../sales/sales.service';

@Component({
  selector: 'app-select-account-group',
  templateUrl: './select-account-group.component.html',
  styleUrls: ['./select-account-group.component.scss']
})
export class SelectAccountGroupComponent implements OnInit {
  @Input() accountGroupCtrl = new FormControl();
  @Input() isEditable = true;
  @Output() selectedAccountGroup = new EventEmitter();
  accountGroupList: AccountGroupMaster[];
  filteredAccountGroupList: Observable<AccountGroupMaster[]>;

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    // Call the method getAccountGroupList and get the result in a callback
    this.getAccountGroupList(accountGroupList => {
      this.filteredAccountGroupList = accountGroupList;
    });
  }

  /**
   *
   *
   * @param {(branchList: Observable<AccountGroupMaster[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete AccountGroup list
   */
  getAccountGroupList(
    fn: (accountGroupList: Observable<AccountGroupMaster[]>) => void
  ) {
    this.salesService.getAccountGroup().subscribe(res => {
      if (res.data && res.data.rows) {
        this.accountGroupList = res.data.rows;
      } else {
        this.accountGroupList = [];
      }
      fn(
        (this.filteredAccountGroupList = this.accountGroupCtrl.valueChanges.pipe(
          startWith(this.accountGroupCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(accountGroup =>
            accountGroup && accountGroup.length > 1
              ? this.filterAccountGroup(accountGroup)
              : this.accountGroupList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} accountGroup
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected accountGroup
   */
  public displaySelectedAccountGroup(accountGroup: any) {
    return accountGroup ? accountGroup.account_group_master_name : accountGroup;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the AccountGroup type based on input
   *
   */
  private filterAccountGroup(name: string) {
    return this.accountGroupList.filter(
      accountGroup =>
        accountGroup.account_group_master_name
          .toLowerCase()
          .indexOf(name.toLowerCase()) >= 0
    );
  }

  public selectAccountGroupClickHandler(accountGroup: AccountGroupMaster) {
    this.selectedAccountGroup.emit(accountGroup);
  }
}
