import { Adapter } from '@app/sales/response.model';
import {
  ProductionCompositionDTO,
  ProductionCompositionDAO
} from './production-composition.interface';

export class ResponceProductionComposition {
  constructor(
    public productionCompositionId: Number,
    public outputMaterial: Number,
    public outputUnitName: String,
    public inputMaterial: Number,
    public inputUnitName: String,
    public quantity: Number,
    public stdRate: Number,
    public cost: Number,
    public remarks: String
  ) {}
}

export class productionCompositionResponceAdapter
  implements Adapter<ResponceProductionComposition> {
  adapt(item: ProductionCompositionDTO): ResponceProductionComposition {
    return new ResponceProductionComposition(
      item.production_composition_id,
      item.output_material_id,
      item.output_unit_name,
      item.input_material_id,
      item.input_unit_name,
      item.issue_qty,
      item.std_rate,
      item.cost,
      item.remarks
    );
  }
}

export class RequestProductinComposition {
  constructor(
    public production_composition_id: Number,
    public output_material_id: Number,
    public output_unit_name: String,
    public input_material_id: Number,
    public input_unit_name: String,
    public issue_qty: Number,
    public std_rate: Number,
    public cost: Number,
    public remarks: String
  ) {}
}

export class ProductionCompositionRequestAdapter
  implements Adapter<RequestProductinComposition> {
  adapt(item: ProductionCompositionDAO): RequestProductinComposition {
    return new RequestProductinComposition(
      item.productionCompositionId,
      item.outputMaterial,
      item.outputUnitName,
      item.inputMaterial,
      item.inputUnitName,
      item.quantity,
      item.stdRate,
      item.cost,
      item.remarks
    );
  }
}
