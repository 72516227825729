import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public confirmation: boolean = false;
  public dataValue: any = {
    name: 'Test',
    message: 'Are you sure you want to submit the form?'
  };
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataValue = data ? data : this.dataValue;
  }

  onOkClick(): void {
    this.confirmation = true;
    console.log(this.confirmation);
    this.dialogRef.close();
  }

  ngOnInit() {}
}
