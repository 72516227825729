import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toBase64String } from '@angular/compiler/src/output/source_map';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  [x: string]: any;
  constructor(private http: HttpClient) {}

  /*
   * BRANCH
   */

  // Get List Of All the branches
  getBranch(
    limit: number,
    skip: number,
    sortby: string,
    sortas: string
  ): Observable<any> {
    return this.http.get('/branch', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }

  // Add new branch
  addBranch(data: any): Observable<any> {
    return this.http.post('/branch', data);
  }

  // Update new branch
  updateBranch(data: any): Observable<any> {
    return this.http.put('/branch', data);
  }

  // Get List Of All active branches
  getBranchForDropdown(entityId: any): Observable<any> {
    let entity = entityId ? entityId.toString() : '';
    return this.http.get('/branch/active', {
      params: { entityId: entity }
    });
  }

  getBranchByEntityId(id: number): Observable<any> {
    return this.http.get('/branch/entityId/' + id);
  }

  // Project List
  getBranchByEntityIdList(ids: number[]): Observable<any> {
    return this.http.post('/project/entityIdList', { entityIdList: ids });
  }

  getLatestBranches(): Observable<any> {
    return this.http.get('/branch/latest');
  }

  changeBranchStatus(data: any): Observable<any> {
    return this.http.put('/branch/changeStatus', data);
  }

  getBranchById(id: Number) {
    return this.http.get('/branch/' + id);
  }

  /*
   * PROJECT
   */

  // Get List Of All the projects
  getProject(
    limit: number,
    skip: number,
    sortby: string,
    sortas: string
  ): Observable<any> {
    return this.http.get('/project', {
      params: {
        limit: limit.toString(),
        skip: skip.toString(),
        sortby: sortby.toString(),
        sortas: sortas.toString()
      }
    });
  }

  searchProject(data: any): Observable<any> {
    return this.http.post('/project/search', data);
  }

  // Add new project
  addProject(data: any): Observable<any> {
    return this.http.post('/project', data);
  }

  // Update new project
  updateProject(data: any): Observable<any> {
    return this.http.put('/project', data);
  }

  getProjectById(id: Number) {
    return this.http.get('/project/' + id);
  }

  changeProjectStatus(data: any): Observable<any> {
    return this.http.put('/project/changeStatus', data);
  }

  // Project List
  getProjectByBranchIdList(ids: number[]): Observable<any> {
    return this.http.post('/project/branchIdList', { branchIdList: ids });
  }

  // Get List Of All active projecs
  getProjectForDropdown(): Observable<any> {
    return this.http.get('/project/active');
  }

  getLatestProjects(): Observable<any> {
    return this.http.get('/project/latest ');
  }
  /*
   * STAFF
   */

  // Get List Of All the projects
  getStaff(data: any): Observable<any> {
    return this.http.get('/staff', data);
  }

  /*
   * ACCOUNT MASTER
   */

  // Get List Of All the expense account
  getAccountMaster(): Observable<any> {
    return this.http.get('/account/accoutForExpense');
  }

  // Get List Of All the expense account
  getVendorAccountMaster(): Observable<any> {
    return this.http.get('/account/vendorAccount');
  }

  // Get List Of All the expense account
  getAllAccountMaster(): Observable<any> {
    return this.http.get('/account/accountForPayment');
  }

  /**
   *
   *Attendance Master
   */
  addAttendance(data: any): Observable<any> {
    return this.http.post('/attendance', data);
  }
  // Get all customer type List
  getCustomerType(): Observable<any> {
    return this.http.get('/customerType', {});
  }
}
