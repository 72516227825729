import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

import { ViewBranchComponent } from './branch/view-branch/view-branch.component';
import { ViewProjectComponent } from './project/view-project/view-project.component';

import { AuthenticationGuard } from '@app/core';
import { environment } from '@env/environment';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'branch',
      data: { title: environment.title + 'Branch', breadcrumb: 'Branch' },
      children: [
        {
          path: '',
          component: ViewBranchComponent,
          canActivate: [AuthenticationGuard]
        }
      ]
    },
    {
      path: 'project',
      data: { title: environment.title + 'Project', breadcrumb: 'Project' },
      children: [
        {
          path: '',
          component: ViewProjectComponent,
          canActivate: [AuthenticationGuard]
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MasterRoutingModule {}
