import { Component, OnInit } from '@angular/core';
import { ProjectMaster } from '@app/model/projectMaster.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from '@env/environment';
import { MasterService } from '@app/master/master.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { StaffMaster } from '@app/model/staffMaster.model';
import { ConfirmationDialogComponent } from '@app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-detail-view-project',
  templateUrl: './detail-view-project.component.html',
  styleUrls: ['./detail-view-project.component.scss']
})
export class DetailViewProjectComponent implements OnInit {
  public projectData: ProjectMaster;
  public viewProjectForm: FormGroup;
  public dateFormat: string = environment.dateFormat;
  public staffList: StaffMaster[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    //Init method

    this.getStaffList();
    this.createViewProjectForm();
  }

  // method for view legal entity form fields
  createViewProjectForm() {
    this.viewProjectForm = this.formBuilder.group({
      name: [''],
      branch: [''],
      staff: [''],
      party: [''],
      legalEntity: [''],
      remarks: ['']
    });
    this.patchViewProjectForm(this.projectData);
  }

  /**
   * Method to get the complete staff list
   */
  getStaffList() {
    let data = {
      limit: environment.pageSizeOptions[0],
      skip: 0
    };
    this.masterService.getStaff(data).subscribe(res => {
      if (res.data && res.data.rows) {
        this.staffList = res.data.rows;
      } else {
        this.staffList = [];
      }
    });
  }

  // method for patch legal entity form fields
  patchViewProjectForm(projectData: ProjectMaster) {
    if (projectData) {
      this.masterService
        .getProjectById(projectData.project_master_id)
        .subscribe(
          (res: any) => {
            this.projectData = res.data;
            this.viewProjectForm.patchValue({
              name: res.data.project_master_name,
              branch: res.data.branch_master,
              staff: res.data.staff_master,
              party: res.data.vendor_master,
              legalEntity: res.data.legal_entity_master,
              remarks: res.data.remarks
            });
          },
          (error: any) => {
            if (error.error.error.message) {
              this.snackBar.open(error.error.error.message, 'OK', {
                duration: 2000
              });
            } else {
              this.snackBar.open(
                'Something went wrong. Please try again.',
                'OK',
                { duration: 2000 }
              );
            }
          }
        );
    }
  }

  // change the status
  changeProjectStatus(status: string) {
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        width: '400px',
        data: {
          name: 'Test',
          message: 'Are you sure, you want to change the status'
        }
      }
    );
    confirmationDialogRef.disableClose = true;
    confirmationDialogRef.componentInstance.dialogRef = confirmationDialogRef;
    confirmationDialogRef.afterClosed().subscribe(result => {
      if (confirmationDialogRef.componentInstance.confirmation) {
        let credentials = JSON.parse(localStorage.getItem('credentials'));
        let data = {
          project_master_id: this.projectData.project_master_id,
          is_active: status,
          user: credentials.id
        };
        this.masterService.changeProjectStatus(data).subscribe(
          (res: any) => {
            this.snackBar.open('Project Updated Successfully', 'OK', {
              duration: 2000
            });
            this.patchViewProjectForm(this.projectData);
          },
          (error: any) => {
            if (error.error.error.message) {
              this.snackBar.open(error.error.error.message, 'OK', {
                duration: 2000
              });
            } else {
              this.snackBar.open(
                'Something went wrong. Please try again.',
                'OK',
                { duration: 2000 }
              );
            }
          }
        );
      }
    });
  }

  compareProjects(c1: any, c2: any): boolean {
    return c1 && c2
      ? c1.project_master_name === c2.project_master_name
      : c1 === c2;
  }
}
