import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError
} from '@angular/router';
import { AuthenticationService, CredentialsService } from '@app/core';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubHeaderComponent implements OnInit {
  selectedTab: String;
  currentUrl: String;
  hideToolBar: Boolean;
  constructor(
    private router: Router,
    public credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.hideToolBar = false;
    if (this.currentUrl.includes('sales')) {
      this.selectedTab = '/sales';
    } else if (this.currentUrl.includes('purchase')) {
      this.selectedTab = '/purchase';
    } else if (this.currentUrl.includes('productions')) {
      this.selectedTab = '/productions';
    } else if (this.currentUrl.includes('accounting')) {
      this.selectedTab = '/accounting';
    } else if (this.currentUrl.includes('user')) {
      this.selectedTab = '/user';
    } else if (this.currentUrl.includes('reports')) {
      this.selectedTab = '/reports';
    } else if (this.currentUrl.includes('branch')) {
      this.selectedTab = '/branch';
    } else if (this.currentUrl.includes('config')) {
      this.selectedTab = '/config';
    } else if (this.currentUrl.includes('inventory')) {
      this.selectedTab = '/inventory';
    } else if (this.currentUrl.includes('timesheet')) {
      this.selectedTab = '/timesheet';
    } else if (this.currentUrl.includes('settings')) {
      this.selectedTab = '/settings';
    } else {
      this.selectedTab = '/home';
    }
  }
}
