import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SalesService } from '@app/sales/sales.service';
import { startWith, map } from 'rxjs/operators';
import { PaymentMode } from '../../../model/paymentMode.model';

@Component({
  selector: 'app-select-payment-mode',
  templateUrl: './select-payment-mode.component.html',
  styleUrls: ['./select-payment-mode.component.scss']
})
export class SelectPaymentModeComponent implements OnInit {
  public paymentModeList: Array<PaymentMode>;
  @Input() paymentModeCtrl = new FormControl();
  @Input() isLabelRequired: boolean = true;
  @Input() tabindex: number;
  public filteredPaymentModeList: Observable<PaymentMode[]>;
  constructor(private salesService: SalesService) {}

  ngOnInit() {
    // Call the method getPaymentModeList and get the result in a callback
    this.getPaymentModeList(paymentModeList => {
      this.filteredPaymentModeList = paymentModeList;
    });
  }

  //method for get payment-mode list
  getPaymentModeList(fn: (paymentModeList: Observable<PaymentMode[]>) => void) {
    this.salesService.getPaymentModeList().subscribe(res => {
      if (res.data && res.data.rows) {
        this.paymentModeList = res.data.rows;
      } else {
        this.paymentModeList = [];
      }
      fn(
        (this.filteredPaymentModeList = this.paymentModeCtrl.valueChanges.pipe(
          startWith(this.paymentModeCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(paymentMode =>
            paymentMode && paymentMode.length > 1
              ? this.filterPaymentMode(paymentMode)
              : this.paymentModeList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddReceiptComponent
   * Method to filter the paymentMode type based on input
   *
   */
  private filterPaymentMode(name: string) {
    return this.paymentModeList.filter(
      paymentMode =>
        paymentMode.payment_mode_name
          .toLowerCase()
          .indexOf(name.toLowerCase()) >= 0
    );
  }

  displaySelectedPaymentMode(paymentMode: PaymentMode) {
    return paymentMode ? paymentMode.payment_mode_name : paymentMode;
  }
}
