import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';
import { SubHeaderComponent } from './sub-header/sub-header.component';

import {
  AuthenticationService,
  CredentialsService,
  I18nService
} from '@app/core';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {
  flag = true;

  /** Sets the initial state of sidenav as collapsed. Not collapsed if false. */
  sidenavCollapsed = true;
  @ViewChild('subheader') subheader: SubHeaderComponent;

  constructor(
    private router: Router,
    private titleService: Title,
    private media: MediaObserver,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private i18nService: I18nService
  ) {}

  ngOnInit() {}

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  logout() {
    this.authenticationService
      .logout()
      .subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  test() {
    this.flag = !this.flag;
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  /**
   * Toggles the current collapsed state of sidenav according to the emitted event.
   * @param {boolean} event denotes state of sidenav
   */
  toggleCollapse($event: boolean) {
    this.sidenavCollapsed = $event;
    //this.cdr.detectChanges();
  }

  selectedTab($event: any) {
    console.log($event);
    let eventUrl = $event.url;
    if (eventUrl.includes('home')) {
      this.subheader.selectedTab = '/home';
      this.subheader.hideToolBar = false;
    } else if (eventUrl.includes('sales')) {
      this.subheader.selectedTab = '/sales';
      this.subheader.hideToolBar = true;
    } else if (eventUrl.includes('purchase')) {
      this.subheader.selectedTab = '/purchase';
      this.subheader.hideToolBar = true;
    } else if (eventUrl.includes('productions')) {
      this.subheader.selectedTab = '/productions';
      this.subheader.hideToolBar = true;
    } else if (eventUrl.includes('accounting')) {
      this.subheader.selectedTab = '/accounting';
      this.subheader.hideToolBar = true;
    } else if (eventUrl.includes('profile')) {
      this.subheader.selectedTab = '/profile';
      this.subheader.hideToolBar = true;
    } else if (eventUrl.includes('user')) {
      this.subheader.selectedTab = '/user';
      this.subheader.hideToolBar = true;
    } else if (eventUrl.includes('reports')) {
      this.subheader.selectedTab = '/reports';
      this.subheader.hideToolBar = true;
    } else if (eventUrl.includes('config')) {
      this.subheader.selectedTab = '/config';
      this.subheader.hideToolBar = true;
    } else if (eventUrl.includes('inventory')) {
      this.subheader.selectedTab = '/inventory';
      this.subheader.hideToolBar = true;
    } else if (eventUrl.includes('timesheet')) {
      this.subheader.selectedTab = '/timesheet';
      this.subheader.hideToolBar = true;
    } else if (eventUrl.includes('settings')) {
      this.subheader.selectedTab = '/settings';
      this.subheader.hideToolBar = true;
    } else {
      this.subheader.selectedTab = '/home';
      this.subheader.hideToolBar = false;
    }
  }
}
