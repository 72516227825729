import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { SalesService } from '../../../sales/sales.service';
import { SalesType } from '../../../model/salesType.model';

@Component({
  selector: 'app-select-sales-type',
  templateUrl: './select-sales-type.component.html',
  styleUrls: ['./select-sales-type.component.scss']
})
export class SelectSalesTypeComponent implements OnInit {
  @Input() salesTypeCtrl = new FormControl();
  @Input() isEditable = true;
  salesTypeList: SalesType[];
  @Input() tabindex: number;

  filteredSalesTypeList: Observable<SalesType[]>;
  @Output() public selectedSalesType = new EventEmitter();
  constructor(private salesService: SalesService) {}

  ngOnInit() {
    // Call the method getSalesTypeList and get the result in a callback
    this.getSalesTypeList(salesTypeList => {
      this.filteredSalesTypeList = salesTypeList;
    });
  }

  /**
   *
   *
   * @param {(salesTypeList: Observable<SalesType[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete sales type list
   */

  getSalesTypeList(fn: (salesTypeList: Observable<SalesType[]>) => void) {
    this.salesService.getSalesType().subscribe(res => {
      if (res.data && res.data.rows && res.data.rows.length > 0) {
        this.salesTypeList = res.data.rows;
      } else {
        this.salesTypeList = [];
      }
      fn(
        (this.filteredSalesTypeList = this.salesTypeCtrl.valueChanges.pipe(
          startWith(this.salesTypeCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(salesType =>
            salesType && salesType.length > 1
              ? this.filterSalesTypes(salesType)
              : this.salesTypeList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} salesType
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the name of the selected sales type
   */
  public displaySelectedSalesType(salesType: any) {
    return salesType ? salesType.sales_type_name : salesType;
  }

  /**
   *
   *
   * @private
   * @param {string} name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the sales type based on input
   */
  private filterSalesTypes(name: string) {
    return this.salesTypeList.filter(
      salesType =>
        salesType.sales_type_name.toLowerCase().indexOf(name.toLowerCase()) >= 0
    );
  }

  selecteSalesTypeClickHandler(salesType: SalesType) {
    this.selectedSalesType.emit(salesType);
  }
}
