import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Role } from '@app/model/role.model';
import * as _ from 'lodash';
import { environment } from '@env/environment';

export interface Credentials {
  // Customize received credentials here
  username: string;
  token: string;
}

const credentialsKey = 'credentials';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable()
export class CredentialsService {
  private _credentials: Credentials | null = null;
  public userRoles: Role[] = [];
  public userRoleNames: String[] = [];
  public userRoleLevels: number[] = [];
  public user_level_7: boolean = false;
  public user_level_6: boolean = false;
  public user_level_5: boolean = false;
  public user_level_4: boolean = false;
  public user_level_3: boolean = false;
  public user_level_2: boolean = false;
  public user_level_1: boolean = false;

  constructor(private http: HttpClient) {
    const savedCredentials =
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
    let data = JSON.parse(localStorage.getItem('loggedInUserDetails'));
    this.setUserRoles(data);
  }

  // Get All Role-Permission
  // getRolePermission(): Observable<any> {
  //   return this.http
  //     .disableApiPrefix()
  //     .get('/assets/mock/role-permission.mock.json');
  // }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    const storage = remember ? localStorage : sessionStorage;
    if (credentials) {
      this._credentials = credentials || null;
    }
    sessionStorage.clear();
    localStorage.clear();
    this.user_level_7 = false;
    this.user_level_6 = false;
    this.user_level_5 = false;
    this.user_level_4 = false;
    this.user_level_3 = false;
    this.user_level_2 = false;
    this.user_level_1 = false;
    if (credentials) {
      storage.setItem(credentialsKey, JSON.stringify(credentials));
      console.log(
        'credentials*************************************************iffff'
      );
    } else {
      if (this._credentials) {
        console.log(
          'credentials**********************************************else***iffff'
        );
        storage.setItem(credentialsKey, JSON.stringify(this._credentials));
      }
    }
  }

  setUserDetails(data: any) {
    localStorage.setItem('loggedInUserDetails', JSON.stringify(data));
    if (data.user_profile_pic && data.user_profile_pic.permanent_url) {
      localStorage.setItem(
        'profile_pic_url',
        JSON.stringify(data.user_profile_pic.permanent_url)
      );
    }
    this.setUserRoles(data);
  }

  setUserRoles(data: any) {
    if (data && data.rolesMapping) {
      this.userRoles = _.map(data.rolesMapping, 'role');
      this.userRoleLevels = _.map(this.userRoles, 'level');
      let totalLevelSum = this.userRoleLevels.reduce(
        (acc, value) => acc + value,
        0
      );
      console.log(environment);
      console.log(this.userRoleLevels);
      console.log(totalLevelSum);

      if (
        environment &&
        this.userRoles &&
        this.userRoleLevels &&
        this.userRoleLevels.length > 0
      ) {
        if (totalLevelSum & environment.level_7) {
          this.user_level_7 = true;
        }

        if (totalLevelSum & environment.level_6) {
          this.user_level_6 = true;
        }

        if (totalLevelSum & environment.level_5) {
          this.user_level_5 = true;
        }

        if (totalLevelSum & environment.level_4) {
          this.user_level_4 = true;
        }

        if (totalLevelSum & environment.level_3) {
          this.user_level_3 = true;
        }

        if (totalLevelSum & environment.level_2) {
          this.user_level_2 = true;
        }

        if (totalLevelSum & environment.level_1) {
          this.user_level_1 = true;
        }
      }
    }
  }

  // setUserRoles(data: any) {
  //   if (data && data.rolesMapping) {

  //     this.userRoles = _.map(data.rolesMapping, 'role');
  //     this.userRoleNames = _.map(this.userRoles, 'role_name');

  //     if (
  //       this.userRoles &&
  //       this.userRoleNames &&
  //       this.userRoleNames.length > 0
  //     ) {
  //       if (
  //         _.intersection(this.userRoleNames, ['system-super-user']).length > 0
  //       ) {
  //         this.user_level_5 = true;
  //       }
  //       if (
  //         _.intersection(this.userRoleNames, ['system-approver-user']).length >
  //         0
  //       ) {
  //         this.user_level_3 = true;
  //       }

  //       if (
  //         _.intersection(this.userRoleNames, ['system-administrator-user'])
  //           .length > 0
  //       ) {
  //         this.user_level_4 = true;
  //       }

  //       if (
  //         _.intersection(this.userRoleNames, ['system-accountant-user'])
  //           .length > 0
  //       ) {
  //         this.user_level_2 = true;
  //       }

  //     }
  //   }
  // }
}
