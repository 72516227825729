import { Adapter } from '@app/sales/response.model';
import {
  FgProductionDAO,
  FgProductionDTO,
  IsActiveEnum,
  IsDeleteEnum
} from './fg-production.interface';

export class ResponceFGProduction {
  constructor(
    public fg_production_id: number,
    public date: Date,
    public production_no: number,
    public branch: number,
    public project: number,
    public remarks: string,
    public material: any,
    public unit: number,
    public qty: number,
    public std_rate: number,
    public std_value: number
  ) {}
}

export class FGProductionResponceAdapter
  implements Adapter<ResponceFGProduction> {
  adapt(item: FgProductionDTO): ResponceFGProduction {
    return new ResponceFGProduction(
      item.fg_production_master_id,
      item.date,
      item.production_master_id,
      item.branch_master_id,
      item.project_master_id,
      item.remarks,
      item.material_master_id,
      item.material_unit_id,
      item.qty,
      item.std_rate,
      item.std_value
    );
  }
}

export class RequestFGProduction {
  constructor(
    public fg_production_master_id: number,
    public date: Date,
    public production_master_id: number,
    public branch_master_id: number,
    public project_master_id: number,
    public material_master_id: number,
    public material_unit_id: number,
    public qty: number,
    public std_rate: number,
    public std_value: number,
    public remarks: string,
    public created_by: number,
    public last_updated_by: number,
    public created_at: Date,
    public last_updated_at: Date,
    public is_active: IsActiveEnum,
    public is_deleted: IsDeleteEnum,
    public ip_address: string
  ) {}
}

export class FGProductionRequestAdapter
  implements Adapter<RequestFGProduction> {
  adapt(item: FgProductionDAO): RequestFGProduction {
    return new RequestFGProduction(
      item.fg_production_id,
      item.date,
      item.production_no,
      item.branch,
      item.project,
      item.material,
      item.unit,
      item.qty,
      item.std_rate,
      item.std_value,
      item.remarks,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }
}
