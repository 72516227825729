import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { SalesService } from '../../../sales/sales.service';
import { State } from '../../../model/state.model';

@Component({
  selector: 'app-select-state',
  templateUrl: './select-state.component.html',
  styleUrls: ['./select-state.component.scss']
})
export class SelectStateComponent implements OnInit {
  @Input() stateCtrl = new FormControl();
  @Input() isEditable = true;
  @Output() selectedState = new EventEmitter();
  @Input() label = 'State';
  @Input() countryId = 0;
  @Input() tabindex: number;
  stateList: State[];
  filteredStateList: Observable<State[]>;

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    // Call the method getStateList and get the result in a callback
    this.getStateList(stateList => {
      this.filteredStateList = stateList;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.countryId) {
      this.countryId = changes.countryId.currentValue;
      if (this.countryId) {
        this.getAllStatesByCountryId(this.countryId, stateList => {
          this.filteredStateList = stateList;
        });
      }
    }
  }

  /**
   *
   *
   * @param {(stateList: Observable<State[]>) => void} fn
   * @memberof AddBookingComponent
   * Method to get the complete state list
   */
  getStateList(fn: (stateList: Observable<State[]>) => void) {
    this.salesService.getState().subscribe(res => {
      if (res.data && res.data.rows) {
        this.stateList = res.data.rows;
      } else {
        this.stateList = [];
      }
      fn(
        (this.filteredStateList = this.stateCtrl.valueChanges.pipe(
          startWith(this.stateCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(state =>
            state && state.length > 0
              ? this.filterState(state)
              : this.stateList.slice()
          )
        ))
      );
    });
  }

  getAllStatesByCountryId(
    countryId: number,
    fn: (stateList: Observable<State[]>) => void
  ) {
    this.salesService.getStateByCountryId(countryId).subscribe(res => {
      if (res.data && res.data.rows) {
        this.stateList = res.data.rows;
      } else {
        this.stateList = [];
      }
      fn(
        (this.filteredStateList = this.stateCtrl.valueChanges.pipe(
          startWith(this.stateCtrl.value),
          map(value => (typeof value === 'string' ? value : value)),
          map(state =>
            state && state.length > 0
              ? this.filterState(state)
              : this.stateList.slice()
          )
        ))
      );
    });
  }

  /**
   *
   *
   * @param {*} state
   * @returns
   * @memberof AddBookingComponent
   * Method to display only the state_name of the selected state
   */
  public displaySelectedState(state: State) {
    return state ? state.state_name : state;
  }

  /**
   *
   *
   * @private
   * @param {string} state_name
   * @returns
   * @memberof AddBookingComponent
   * Method to filter the state based on input
   *
   */
  private filterState(state_name: string) {
    return this.stateList.filter(
      state =>
        state.state_name.toLowerCase().indexOf(state_name.toLowerCase()) >= 0
    );
  }

  public selectStateClickHandler(state: State) {
    this.selectedState.emit(state);
  }
}
