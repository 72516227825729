import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationService,
  CredentialsService,
  I18nService
} from '@app/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  /** Loop to iterate  */
  fillerNav = Array.from({ length: 5 }, (_, i) => `Nav Item ${i + 1}`);

  /** True if sidenav is in collapsed state. */
  @Input() sidenavCollapsed: boolean;
  /** Username of authenticated user. */
  username: string;
  picUrl: string;
  /**
   * @param {Router} router Router for navigation.
   * @param {AuthenticationService} authenticationService Authentication Service.
   * @param {CredentialsService} credentialsService Credentials Service.
   */
  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    public credentialsService: CredentialsService,
    private i18nService: I18nService
  ) {}

  /**
   * Sets the username of the authenticated user.
   */
  ngOnInit() {
    this.picUrl = JSON.parse(localStorage.getItem('profile_pic_url'));
    if (!this.picUrl) {
      this.picUrl = '../assets/icons/user_placeholder.png';
    }
    // const credentials = this.authenticationService.getCredentials();
    // this.username = credentials.username;
  }

  /**
   * Logs out the authenticated user and redirects to login page.
   */
  logout() {
    this.authenticationService
      .logout()
      .subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }
}
